const TextAreaConstants = {

  NUMBERS: {
    ZERO: 0,
  },

  MAX_LENGTH: 500000,

  HIDDEN: 'hidden',
  PX: 'px',
  
}

export default TextAreaConstants
const asideComponentConstants = {
    USE_TRANSLATION_GLOBAL_VALUE: 'global',

    HASH_PATH: '#',

    ADMIN_USER: 'Administrador',
    EMPTY_USER: '',

    BRANDS_PATH: '/marcas',
    CREATE_BRAND_PATH: '/marcas/crear',
    USERS_PATH: '/usuarios',
    MANAGE_CITIES_PATH: '/ciudades',
    MANAGE_STATES_PATH: '/estados',
    MANAGE_COUNTRIES_PATH: '/paises',

    CUSTOMERS_PATH: '/clientes',
    CREATE_CUSTOMER_PATH: '/cliente',

    INVOICE_PATH: '/factura',
    PENDING_INVOICES_PATH: '/facturas-pendientes',
    HISTORY_INVOICES_PATH: '/historico-de-facturas',

    POLICY_PATH: '/poliza',
    PENDING_POLICIES_PATH: '/polizas-pendientes',
    HISTORY_POLICIES_PATH: '/historico-de-polizas',

    EMPTY_STRING_VALUE: '',

    ROOT_PATH: '/',
}

export default asideComponentConstants;
import Checkbox from "../checkbox/checkbox";
import { formatValToCurrency } from "../../../utils/utils";
import { Fragment } from "react";
import "../../molecules/table/Table.scss"
import TableConstants from "../../../constants/table-constants";

const constants = TableConstants;

const TrTable = ({
    row,
    breakOn,
    indexRow,
    headingColums,
    checkeableRows,
    handleOnClickRow,
}) => {

    const setFormatCell = (header, row) => {
        let formattedCell;
        let cell = row[header.key]
        switch (header.type) {
            case constants.CURRENCY_TYPE:
                formattedCell = formatCurrency(header.currencyTypeFormatter, cell);
                return formattedCell;
            case constants.TEXT_TYPE:
                formattedCell = formatText(header.renderAs, row);
                return formattedCell;
            case constants.BOOL_TYPE:
                formattedCell = formatCheckbox(row[header.key]);
                return formattedCell;
            default:
                formattedCell = cell;
                return formattedCell;
        }
    }

    const formatCheckbox = (value) => {
        return (
            <Checkbox
                readOnly={true}
                checked={value}
                shortMargin={true}
            />
        )
    }

    const formatCurrency = (currencyTypeFormatter, cell) => {
        if (currencyTypeFormatter)
            return cell ? `${constants.CURRENCY_FORMAT}${formatValToCurrency(constants.ID_ONE, cell, true)}` : `${constants.CURRENCY_DEFAULT}`;
        return null;
    }

    const formatText = (renderAs, row) => {
        let formattedCell;
        if (renderAs) {
            if (renderAs.length > constants.ONE_LENGTH) {
                formattedCell = renderAs[constants.FIRST_POSITION_INDEX];
                const variableCount = renderAs[constants.FIRST_POSITION_INDEX].match(/\$(.*?)\%/g).length;
                for (let index = constants.ONE_INITIALIZER; index <= variableCount; index++) {
                    formattedCell = formattedCell.replace(`${constants.CURRENCY_FORMAT2}${index}${constants.PERCENTAGE_SYMBOL}`, row[renderAs[index]]);
                }
            }
        }
        return formattedCell;
    }

    const getStyleCell = (header, row) => {
        const style = {};
        if (header.hasOwnProperty(constants.TEXT_TRANSFORM)) { style.textTransform = header.textTransform; };
        if (header.hasOwnProperty(constants.ALIGN)) { style.textAlign = header.align; };

        if (header.key === constants.STATUS_KEY) {
            style.fontWeight = constants.FONT_WEIGHT_700;

            if(row[constants.STATUS_KEY] === constants.APPROVED_KEY || row[constants.STATUS_KEY] === constants.ACTIVE_KEY){
                style.color = constants.APPROVED_COLOR
                
            }else if(row[constants.STATUS_KEY] === constants.PENDING_KEY){
                style.color = constants.PENDING_COLOR 

            }else if(row[constants.STATUS_KEY] === constants.REVIEWED_KEY){
                style.color = constants.REVIEWED_COLOR 

            }else if(row[constants.STATUS_KEY] === constants.EXPORTED_KEY){
                style.color = constants.EXPORTED_COLOR 

            }else if(row[constants.STATUS_KEY] === constants.SAVED_KEY){
                style.color = constants.SAVED_COLOR 
                
            }else{
                style.color = constants.ERROR_COLOR;
            }
        }  
        if (header.key === constants.NIT_KEY) {
            style.fontWeight = constants.FONT_WEIGHT_700;
        };
        if (header.key === constants.CENTERCOST_KEY) {
            style.fontWeight = constants.FONT_WEIGHT_700;
        };

        return style;
    }

    const converterData = () => {
        const newData = [];
        headingColums.forEach(header => {
            let formattedValue = header.type ? setFormatCell(header, row) : null;
            newData.push({
                key: header.val,
                val: formattedValue ? formattedValue : row[header.key],
                style: getStyleCell(header, row),
            });
        });
        return newData;
    }

    const getClassRow = () => {
        let classRow =
            indexRow % constants.EVEN_NUMBER_DIVISION === constants.EVEN_NUMBER_RESIDUE ? constants.EMPTY_STRING : constants.TR_BASE_CLASS;
        switch (breakOn) {
            case constants.SMALL:
                return classRow += constants.TR_SMALL_CLASS;
            case constants.MEDIUM:
                return classRow += constants.TR_MEDIUM_CLASS;
            case constants.LARGE:
                return classRow += constants.TR_LARGE_CLASS;
            default:
                return classRow;
        }
    }


    return (
        <tr onClick={(event) => handleOnClickRow(event, row)} key={indexRow} className={getClassRow()}>
            {converterData().map((data, indexCell) => (
                <td style={data.style} key={indexCell} data-heading={data.key}>
                    {indexCell === constants.FIRST_POSITION_INDEX && checkeableRows
                        ?
                        <Fragment>
                            <input type={constants.INPUT_CHECKBOX} name={constants.REQUEST_NAME + data.val} />&nbsp;&nbsp;
                        </Fragment>
                        :
                        constants.EMPTY_STRING
                    }
                    {data.val}
                </td>
            ))}
        </tr>
    )
}

export default TrTable;
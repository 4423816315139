import { useContext } from 'react';

import { BASE_URL } from '../../../constants/api-constants';
import RequestData from '../../molecules/request-data/requestData';
import Header from '../../molecules/header/header';
import DataClient from '../../organism/data-client/dataClient';
import PaymentConditions from '../../organism/payment-conditions/paymentConditions';
import { confirmationAlert } from "../../molecules/alert/alert";
import { STATUS_ID } from '../../../utils/constans';
import './generalInformation.scss';
import Back from '../../../images/ico-back.svg';
import Adjuntar from '../../../images/adjuntar.svg';
import RoundButton from '../../atoms/round-button/roundButton';
import { useTranslation } from 'react-i18next';
import TranslationComponentConstants from '../../../constants/translation-language-constants';
import GeneralInformationConstants from '../../../constants/general-information-constants';


function GeneralInformation(Props) {
    const constants = GeneralInformationConstants;
    const translationConstants = TranslationComponentConstants;
    const [translate] = useTranslation(translationConstants.USE_TRANSLATION_GLOBAL_VALUE);
    const [data] = useContext(Props.context)

    const goBack = () =>{
        confirmationAlert(
            constants.WARNING,
            translate('alerts.comeBack'),
            "",
            translate('transversal.yes'),
            translate('transversal.no'),
            () => {
                window.history.back();
            }
        );
    }
    
    return (
        <div className="GeneralInformation">
            <div className="HeaderInformation">
                <div className="HeaderInformation__title">
                    <Header policy={Props.policy} context={data} />
                    <hr className='HeaderInformation__title hr' />
                </div>
            </div>
            <div className="Body">
                <section className="body_generalInformation">
                    <div className="HeaderBody">
                        <h3 className="Title">{translate('invoices.createInvoice.generalInformation')}</h3>
                    </div>
                    <div className="PolicyData">
                        <div className="bodyRequest">
                            <RequestData data={Props.dataToShow} date={Props.dataToShow.length === constants.REQUESTDATA_LENGTH_2 ? true : false} /> 
                        </div>
                    </div>
                </section>
                
                <div hidden>{BASE_URL}</div>
                <div className='ContentDataClient'>
                    <DataClient context={Props.context} policy={Props.policy} changeClient={Props.changeClient} />
                </div>
                <div className='Conditions'>
                    <PaymentConditions limitFiles={Props.limitFiles} context={Props.context} policy={Props.policy} show={Props.show} isDuplicate={Props.isDuplicate} />
                </div>
            </div>
        </div>
    );
}

export default GeneralInformation;


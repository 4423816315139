
const CrearFacturaConstants = {

  GLOBAL: "global",
  EMPTY: "",
  SPACE: " ",
  MIDDLE_DASH: "-",
  POINT: ".",
  NULL: null,


  TRUE: true,
  FALSE: false,

  TYPE_ADD: "AGREGAR",
  TYPE_RESET: "RESET",
  TYPE_ORANGE: "orange",

  BUTTON: "Button",
  INVOICE_CHECK:"InvoiceCheck",

  CREATE_INVOICE: "Crear factura",
  REQUEST_INVOICE: "Solicitar factura",

  REQUEST_DATE: "fechaSolicitud",
  LIMIT_DATE: "fechaLimiteEntrega",
  FORMAT_DATE: "DD/MM/AAAA",
  FORMAT_DATE_YYYY_MM_DD: "YYYY-MM-DD",
  DATE: "DATE",
  DATE_LOWER: "date",
  TIME_ZONE: "America/Bogota",

  ALERT_ERROR: "error",
  ALERT_WARNING: "warning",
  ALERT_SUCCES: "success",

  ROOT: "/",
  ROOT_PENDING_INVOICE: "/facturas-pendientes",
  ROOT_INVOICE: "/factura",

  WHITE: "white",
  DARK: "dark",
  OUTLINED: "outlined",
  SECONDARY: "secondary",
  PRIMARY: "primary",

  NUMBER_MINUS_ONE: -1,
  NUMBER_ZERO: 0,
  NUMBER_ONE: 1,
  NUMBER_TWO: 2,
  NUMBER_THREE: 3,
  NUMBER_FOUR: 4,
  NUMBER_FIVE: 5,
  NUMBER_SIX: 6,
  NUMBER_EIGHT: 8,
  NUMBER_NINE: 9,
  NUMBER_ELEVEN: 11,

  STATUS_200: 200,
  STATUS_403: 403,

  CHANGE_STATE: "Cambiar estado",

  CONSECUTIVO: {
    SEDE_BE: "BE",
    SEDE_CE: "CE",
    SEDE_NN: "NN",
    SEDE_ME: "ME",

  },

  ROLE: {
    SUPERVISOR: "Supervisor",
  },



};

export default CrearFacturaConstants
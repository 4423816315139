import React from 'react';
import './TablePagination.scss';
import LeftArrow from "../../../images/icon-arrow-left.svg";
import RightArrow from "../../../images/icon-arrow-right.svg";
import tablePaginationConstants from '../../../constants/table-pagination-constants';

const TablePagination = ({ currentPage, pageAmount, paginate }) => {
    const constants = tablePaginationConstants;

    const renderPageNumbers = () => {
        const pageNumbers = [];

        if (currentPage < pageAmount) {
            for (let index = currentPage; (index <= pageAmount) && (index <= (currentPage + constants.TWO_VALUE)); index++) {
                pageNumbers.push(
                    <span className={`${constants.PAGINATION_NUMBER_CLASS} ${currentPage === index ? constants.ACTIVE_CLASS : constants.EMPTY_STRING}`}
                        key={index} onClick={(e) => paginate(e, index)} >
                        {index}
                    </span>
                )
            }
        }

        if (pageAmount >= constants.THREE_VALUE) {
            if (currentPage === pageAmount) {
                for (let index = currentPage; index >= (pageAmount - constants.TWO_VALUE); index--) {
                    pageNumbers.unshift(
                        <span className={`${constants.PAGINATION_NUMBER_CLASS} ${currentPage === index ? constants.ACTIVE_CLASS : constants.EMPTY_STRING}`}
                            key={index} onClick={(e) => paginate(e, index)} >
                            {index}
                        </span>
                    )
                }
            }

            if (currentPage === (pageAmount - constants.ONE_VALUE)) {
                pageNumbers.unshift(
                    <span className={`${constants.PAGINATION_NUMBER_CLASS} ${currentPage === pageAmount - constants.TWO_VALUE ? constants.ACTIVE_CLASS : constants.EMPTY_STRING}`}
                        onClick={(e) => paginate(e, pageAmount - constants.TWO_VALUE)} >
                        {pageAmount - constants.TWO_VALUE}
                    </span>
                )
            }

            if ((currentPage + constants.THREE_VALUE) < pageAmount) {
                pageNumbers.push(
                    <span key={constants.DOTS_KEY} className={constants.PAGINATION_DOTS_CLASS}>...</span>,
                    <span className={`paginationTotalText`} onClick={(e) => paginate(e, pageAmount)}>{pageAmount}</span>
                );
            }
        }


        if (pageAmount > constants.FOUR_VALUE && currentPage >= (pageAmount - constants.TWO_VALUE)) {
            pageNumbers.unshift(
                <span className={`paginationTotalText`} onClick={(e) => paginate(e, constants.ONE_VALUE)}>{constants.ONE_VALUE}</span>,
                <span key={constants.DOTS_KEY} className={constants.PAGINATION_DOTS_CLASS}>...</span>
            );
        }


        if (currentPage + constants.THREE_VALUE === pageAmount) {
            pageNumbers.push(
                <span className={`paginationTotalText`} onClick={(e) => paginate(e, pageAmount)}>{pageAmount}</span>
            );
        }

        return pageNumbers;
    };

    return (
        <section className="navigation">
            <span className={`paginationIconContainer ${currentPage === constants.ONE_VALUE ? "m-hide_element" : ""}`}
                onClick={(e) => paginate(e, currentPage - constants.ONE_VALUE)}>
                <img className={"paginationIcon"} src={LeftArrow} alt={"Anterior"} />
            </span>
            {renderPageNumbers()}
            <span className={`paginationIconContainer ${currentPage === pageAmount ? "m-hide_element" : ""}`}
                onClick={(e) => paginate(e, currentPage + constants.ONE_VALUE)}>
                <img className={"paginationIcon"} src={RightArrow} alt={"Siguiente"} />
            </span>
        </section>
    );
};

export default TablePagination;

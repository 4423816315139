import React from 'react';
import { useHistory } from 'react-router';
import './quickAccessLink.scss';
import cardImage from '../../../images/card-selector.svg';
import QuickAccessComponentConstants from '../../../constants/quick-access-constants';

function QuickAccessLink({ link, props }) {
    const constants = QuickAccessComponentConstants
    const history = useHistory();

    const handleClick = () => {
        history.push(link.link);
    }

    return (
        <div onClick={handleClick} className="quick-access">
            <img src={cardImage} className='selector' alt={constants.SVG_HOVER} />
            <img src={link.image} alt={`${link.text}`} width={constants.WIDTH_HEIGHT_IMG} height={constants.WIDTH_HEIGHT_IMG} />
            <span>{link.text}</span>
        </div>
    )
}

export default QuickAccessLink;
const tablePaginationConstants = {
    ONE_VALUE: 1,
    TWO_VALUE: 2,
    THREE_VALUE: 3,
    FOUR_VALUE: 4,

    PAGINATION_NUMBER_CLASS: "paginationNumber",
    PAGINATION_DOTS_CLASS: "paginationDots",
    ACTIVE_CLASS: "active",
    EMPTY_STRING: "",
    DOTS_KEY: "dots",
};

export default tablePaginationConstants;
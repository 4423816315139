import React, { useState } from 'react';
import Proptypes from 'prop-types';
import Checkbox from '../../atoms/checkbox/checkbox';
import InputFileGeneric from '../../molecules/input-file-generic/inputFileGeneric';
import { POLICY_ID } from '../../../utils/constans';
import SpanGray from '../../atoms/text/spanGray';

import './checkbox-container.scss';
import { useTranslation } from 'react-i18next';
import { PdsInput } from '@pragma-dev-kit/pragma-ds-react';
import CheckboxContainerConstants from '../../../constants/checkbox-container-constants';


const CheckboxContainer = ({ handleChange, handleChangeText, handleFileChange, checked, verticalMode, data,
    showOtherPolicyInput, componentType, manageFiles, disabledFields, message }) => {
    const constants = CheckboxContainerConstants;
    const [translate] = useTranslation(constants.GLOBAL);
    const [otherPolicyValue, setOtherPolicyValue] = useState(constants.EMPTY);
    const onChangeText = (event) => {  
        setOtherPolicyValue(event.target.value);
        handleChangeText(event);
    };

    data.forEach(element => {
        switch (constants.TRUE) {
            case element.descripcion === (constants.DESCRIPTION.QUALITY_SERVICE):
                element.t = constants.TRANSLATE.QUALITY_SERVICE;
                break;
            case element.descripcion === (constants.DESCRIPTION.CONTRACT_PERFORMANCE):
                element.t = constants.TRANSLATE.CONTRACT_PERFORMANCE;
                break;
            case element.descripcion === (constants.DESCRIPTION.MANAGEMENT_ADVANCE):
                element.t = constants.TRANSLATE.MANAGEMENT_ADVANCE;
                break;
            case element.descripcion === (constants.DESCRIPTION.PAYMENT_WAGES_SOCIAL_BENEFITS):
                element.t = constants.TRANSLATE.PAYMENT_WAGES_SOCIAL_BENEFITS;
                break;
            case element.descripcion === (constants.DESCRIPTION.CIVIL_LIABILITY):
                element.t = constants.TRANSLATE.CIVIL_LIABILITY;
                break;
            case element.descripcion === (constants.DESCRIPTION.NON_CONTRACTUAL_CIVIL_LIABILITY):
                element.t = constants.TRANSLATE.NON_CONTRACTUAL_CIVIL_LIABILITY;
                break;
            case element.descripcion === (constants.DESCRIPTION.SERIOUSNESS_OFFER):
                element.t = constants.TRANSLATE.SERIOUSNESS_OFFER;
                break;
            case element.descripcion === (constants.DESCRIPTION.OTHER):
                element.t = constants.TRANSLATE.OTHER;
                break;
            default:
                break;
        }
        if (element.t) element.descripcion = translate(element.t);
    });

    React.useEffect(() => {
        data.forEach(element => element.descripcion = element.t ? translate(element.t) : element.descripcion);
    }, [translate]);

    const isOneInputFile = () => {
        return data.length === constants.ONE;
    }

    const setComponent = (code, index) => {
        switch (componentType) {
            case constants.CASE.CHECKBOX:
                return (
                    <Checkbox
                        key= {index}
                        name={code.tipoPolizaId}
                        label={code.descripcion}
                        handleChange={handleChange}
                        checked={checked}
                        verticalMode={verticalMode}
                    />
                );
            case constants.CASE.INPUT_FILE_GENERIC:
                return (
                    <div key={index} className= {`m-div-table-row ${isOneInputFile() ? 'one':''}`}>
                        <div className="m-div-table-row__col">
                            {code.tipoPolizaId === POLICY_ID.OTRA ?
                                <SpanGray MostrarValor={`${code.descripcion} - ${code.descripcionOtra}`} />
                                : <SpanGray MostrarValor={code.descripcion} />
                            }
                        </div>
                        <div className="m-div-table-row__col--box">
                            <InputFileGeneric
                                status={'upload'}
                                title={code.descripcion}
                                name={code.descripcion}
                                code={code.tipoPolizaId}
                                handleFileChange={handleFileChange}
                                category={code.descripcion}
                                data={code.files}
                                manageFiles={manageFiles}
                                disabled={disabledFields}
                                message={message}
                                multiple={false}
                                limitFiles={1}
                            />
                             <p className="input-file-block">{translate('components.inputFile.limitFile')}</p>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    }

    return (
        <div className="form-checkbox">
            <div className="HeaderBody">
                <h3>{translate('policy.title')}</h3>
            </div>
            <div className={componentType === "checkbox" ? "horizontal" : "m-div-table"}>
                {data.map((code, index) => (
                    setComponent(code, index)
                ))}
                {showOtherPolicyInput ? 
                <PdsInput
                    className={"horizontal_input_text"}
                    id="text-otro"
                    maxLength="40"
                    onInput={onChangeText}
                    placeholder={translate('policy.requestPolicy.otherPolicy')}
                    value={otherPolicyValue}
                />  : <></>}
            </div>
        </div>
    )
}

CheckboxContainer.propTypes = {
    handleChange: Proptypes.func.isRequired,
    checked: Proptypes.bool,
    verticalMode: Proptypes.bool,
    data: Proptypes.arrayOf(Proptypes.shape({ code: Proptypes.string, name: Proptypes.string }))
}

export default CheckboxContainer;
const brandFormComponentConstants = {
    ADD_LABEL: 'AGREGAR',
    RESET_LABEL: 'RESET',
    CHANGE_LABEL: 'change',
    CLIENTS_LABEL: 'clients',
    AFFILIATES_LABEL: 'filiales',
    CHECKBOX_LABEL: 'checkbox',
    SUCCESS_LABEL: 'success',
    ERROR_LABEL: 'error',
    TYPE_NUMBER: 'number',
    TYPE_TEXT: 'text',
    NAME_COSTCENTER: 'costCenter',
    NAME_NAME: 'name',
    NAME_ACTIVE: 'active',
    SEARCHNAME_RAZONSOCIAL: 'razonSocialRut',
    SEARCHNAME_FILIALES: 'filiales',

    PRAGMA_CO: 'Pragma - Colombia',
    PIRANI_CO: 'Pirani - Colombia',
    PIRANI_PA: 'Pirani - Panamá',

    LENGTH_1: 1,
    LENGTH_2: 2,
    LENGTH_3: 3,

    ONE: '1',
    TWO: '2',
    THREE: '3',

    FIRST_INDEX_POSITION: 0,
    
    TIMER_500: 500,

    STATUS_CODE_200: 200,
    BRANDS_ROUTE: '/marcas'
};

export default brandFormComponentConstants;
const LoginComponentConstants = {
    HELP_DESK_URL: "https://www.pragma.co/mesa-de-ayuda",

    USE_TRANSLATION_GLOBAL_VALUE: "global",
    ALERT_ERROR_TYPE: "error",

    GOOGLE_LOGO_ALT: "Google Icon",
    AUTHENTICATION_AMPLIFY_PROVIDER: "Google",

    ROOT_PATH: "/",
    BILL_PATH: "/factura"
}
export default LoginComponentConstants;
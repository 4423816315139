let date = new Date();
const a = JSON.stringify(date);

let year = a.substring(1, 5);
let month = a.substring(6, 8);
let day = a.substring(9, 11);
let completDateParameterMin = year + '-' + month + '-' + day;

export const initialState = {
    clienteId: -1, 
    contacto: "",
    cargoContacto: "",
    fechaSolicitud: completDateParameterMin, 
    fechaLimiteEntrega: completDateParameterMin, 
    files: [], 
    tiposPolizasSolicitud : [],
    observaciones: "",
    reset: false,
    filialId: "",
    marcas: [],
    negocios: []
};

const policyReducer = (state, action) => {
    switch(action.type) {
        case 'RESET':
            initialState.files = [];
            initialState.reset = true;
            initialState.tiposPolizasSolicitud = [];
            initialState.clienteId = -1; 
            return initialState;
        case 'INITIAL_STATE':
            initialState.files = [];
            initialState.tiposPolizasSolicitud = [];
            initialState.reset = true;
            initialState.marcas = [];
            initialState.negocios = [];
            initialState.clienteId = -1; 
            return initialState;
        case 'AGREGAR':
            return {...state, ...action.payload}    
        default:
            return {...state, ...action.payload}    
    }
}
 
export default policyReducer;
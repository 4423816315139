import './section.scss';

function Section({ children, ...otherSectionProps }) {
    return (
        <section {...otherSectionProps} >
            {children}
        </section>
    );
}

export default Section;
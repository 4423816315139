const AlertConstants = {

  TRUE: true,
  FALSE: false,
  
  SUCCESS: "success",
  ERROR: "error",
  INFO: "info",
  WARNING: "warning",

  ACCEPT: "Aceptar",
  OK: "Entendido",

  ICON_COLOR: '#6F2FD3',
  BACKGROUND_COLOR: '#1F0D3F'
};

export default AlertConstants;
import { createContext, useReducer } from "react";
import userReducer from "../reducers/userReducer";

export const AppContextUser = createContext();

const UserInSessionProvider = ({ children }) => {
  const [dataUser, dispatch] = useReducer(userReducer, {});
  return (
    <AppContextUser.Provider value={{ dataUser, dispatch }}>
      {children}
    </AppContextUser.Provider>
  );
};

export default UserInSessionProvider;

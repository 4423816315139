import React, { useState } from 'react';
import HeaderInvoiceRequest from '../../organism/header-invoice-request/HeaderInvoiceRequest';
import ManageCities from '../../organism/manage-cities/manageCities';
import Aside from '../../templates/aside/aside';
import Wrapper from '../../templates/wrapper/Wrapper';
import { useTranslation } from "react-i18next";
import TranslationComponentConstants from '../../../constants/translation-language-constants';

function Cities({props}) {
    const translationConstants = TranslationComponentConstants;
    const [translate] = useTranslation(translationConstants.USE_TRANSLATION_GLOBAL_VALUE);
    const [blur, setBlur] = useState(false);
    const title = translate('administrator.manageCities.title');
    const description = translate('administrator.manageCities.description')

    return (
        <Wrapper aside={ <Aside blur={blur} setBlur={setBlur} /> } blur={blur} >
            <HeaderInvoiceRequest title={title} description={description} />
            <ManageCities />
        </Wrapper>
    )
}

export default Cities;
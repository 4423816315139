const HomeComponentConstants = {
    TRANSLATION_GLOBAL: "global",

    CLIENT_PATH: "/cliente",
    BRAND_PATH: "/marcas/crear",
    BILL_PATH: "/factura",
    PENDING_BILL_PATH: "/facturas-pendientes",
    HISTORY_BILL_PATH: "/historico-de-facturas",
    POLICY_PATH: "/poliza",
    PENDING_POLICY_PATH: "/polizas-pendientes",
    HISTORY_POLICY_PATH: "/historico-de-polizas",

    SEND_EMAIL: "mailto:",

    USER_APPROVER: "Aprobador",
    USER_SUPERVISER: "Supervisor",
    USER_APPLICANT: "Solicitante",
    USER_ADMIN: "Administrador",
}

export default HomeComponentConstants;
const manageCountriesConstants = {
    EMPTY_STRING_INITIAL_STATE: '',
    REQUESTS_INITIAL_STATE_TWENTY: 20,
    PAGES_INITIAL_STATE_ONE: 1,
    ZERO_VALUE: 0,
    ONE_VALUE: 1,
    EMPTY_STRING_VALUE: '',
    EQUALS: '=',
    AMPERSAND: '&',

    NAME_COLUMN: 'nombre',
    CODE_COLUMN: 'codigo',
    SORT_NAME_COLUMN: 'sortname',
    EN_NAME_COLUMN: 'name',
    PHONE_CODE_COLUMN: 'phonecode',
    INDICATIVE_COLUMN: 'indicativo',
    COLUMN_WIDTH_1: 1,
    COLUMN_WIDTH_3: 3,

    TEN_ROWS: 10,
    TEN_ROWS_LABEL: '10',
    TWENTY_ROWS: 20,
    TWENTY_ROWS_LABEL: '20',
    FIFTY_ROWS: 50,
    FIFTY_ROWS_LABEL: '50',

    COUNTRY_LABEL: 'pais',
    STATUS_CODE_200: 200,
    SUCCESS_LABEL: 'success',
    ERROR_LABEL: 'error',

    KEYWORD_PARAM: '?keyword=',
    PAGE_NO_PARAM: '&pageNo=',
    PAGE_SIZE_PARAM: '&pageSize=',
    SORT_BY_PARAM: '&sortBy=',
    SORT_ASC_PARAM: '&ascSort=',
    FILTERED_PARAM: '&pageNo=1&pageSize=20&sortBy=pais&ascSort=true',
    STATES_PATH: '/estados',

    FIRST_INDEX_POSITION: 0,
    SECOND_INDEX_POSITION: 1,
    THIRD_INDEX_POSITION: 2,
    FOURTH_INDEX_POSITION: 3,
    FIFTH_INDEX_POSITION: 4,
    SIXTH_INDEX_POSITION: 5,
};

export default manageCountriesConstants;
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext, useCallback } from "react";
import { getStatesByCountryId, getCitiesByStateId, getAllCountries } from "../../../services/getLocationList";
import { isNumberKey, isPhoneKey, validatePermissionsFilial, getValueDropdown } from "../../../utils/utils";
import { AppContextUser } from "../../../provider/userInSession";
import { ClientContext } from "../../../provider/cliente";
import { useTranslation } from "react-i18next";
import { PdsDropdown, PdsInput, PdsDropdownOption } from "@pragma-dev-kit/pragma-ds-react";
import AddClientComponentConstants from "../../../constants/add-client-constants";
import { validateField } from "../../../utils/utils";
import "./addClient.scss";

function AddClient() {
  const constants = AddClientComponentConstants;
  const [translate] = useTranslation(constants.TRANSLATION_GLOBAL_VALUE);

  const { dataUser } = useContext(AppContextUser);
  const { dataClient, setDataClient } = useContext(ClientContext);

  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    dataClient.usuarioId = dataUser.userId;
    setDataClient({
      type: constants.ADD_LABEL,
      payload: { ...dataClient },
    });
  }, [dataUser]);

  useEffect(() => {
    const fetchData = async () => {
      try { 
        setCountries(await getAllCountries());  
        setStates(await getStatesByCountryId(dataClient.paisId));
        setCities(await getCitiesByStateId(dataClient.departamentoId)); 
      }
      catch (err) { console.error(constants.ERROR_MESSAGE, err) }
    };
    fetchData();
  }, [dataClient, dataUser, setDataClient]);

  const getAllStates = async (countryCode) => {
    try { setStates(await getStatesByCountryId(countryCode)) }
    catch (err) { console.error(constants.ERROR_MESSAGE, err) }
  }

  const getAllCities = async (stateCode) => {
    try { setCities(await getCitiesByStateId(stateCode)) }
    catch (err) { console.error(constants.ERROR_MESSAGE, err) }
  }

  const getCountryInfo = (value) => countries.filter((country) => country.name === value.detail || country.id === Number(value.detail))[constants.ZERO_NUMBER];
  const getStateInfo = (value) => states.filter((state) => state.name === value.detail || state.id === Number(value.detail))[constants.ZERO_NUMBER];
  const getCityInfo = (value) => cities.filter((city) => city.name === value.detail || city.id === Number(value.detail))[constants.ZERO_NUMBER];


  const updateData = (event) => {
    const value = event.detail ? event.detail : event.target.value;
    setDataClient({
      type: constants.ADD_TYPE,
      payload: { ...dataClient, [event.target.name]: value }
    });
  }

  const updateDataLocationNames = (value, event) => {
    setDataClient({
      type: constants.ADD_TYPE,
      payload: { ...dataClient, [event.target.name]: value.toString() }
    });
  }

  const updateLocation = (location, event) => {
    if (event.detail !== constants.ZERO_STRING || Number(event.detail) !== constants.ZERO_NUMBER) {
      if (location === constants.COUNTRY_LABEL) {
        const selectedCountry = getCountryInfo(event);
        const numberCode = constants.PLUS_SIGN + selectedCountry.phonecode;
        dataClient.telefonoRut = numberCode;
        dataClient.celularRut = numberCode;
        getAllStates(selectedCountry.id);
        updateDataLocationNames(selectedCountry.id, event);
        return;
      }
      if (location === constants.STATE_LABEL) {
        const selectedState = getStateInfo(event);
        getAllCities(selectedState.id);
        updateDataLocationNames(selectedState.id, event);
        return;
      }
      if (location === constants.CITY_LABEL) {
        const selectedCity = getCityInfo(event);
        updateDataLocationNames(selectedCity.id, event);
        return;
      }
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    updateData(event);

    const fieldErrors = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      ...fieldErrors,
    }));
  };

  const getValuesDropdown = useCallback((key, value, list) => {
    return getValueDropdown(key, value, list);
  },[countries, states, cities])

  return (
    <section className="form__content">
      <section className="information">
        <h3 className="information__title" > {translate('customers.createCustomer.dataCustomer.title')} </h3>

        <div className="input__text">
          <PdsInput
            name="razonSocialRut"
            value={dataClient.razonSocialRut}
            onInput={handleChange}
            label={translate('customers.createCustomer.dataCustomer.name')}
            {...(errors.razonSocialRut || {})}
          />
          <PdsInput
            type="number"
            name="numeroDocumento"
            value={dataClient.numeroDocumento}
            onInput={handleChange}
            label={translate('customers.createCustomer.dataCustomer.fiscalIdNumber')}
            {...(errors.numeroDocumento || {})}
          />
        </div>

        <div className="input__text--grid-3cols">
          <PdsDropdown
            className={dataClient.paisId ? 'input-value' : ''}
            name="paisId"
            onValueChange={(e) => updateLocation(constants.COUNTRY_LABEL, e)}
            placeholder={getValuesDropdown("id", dataClient.paisId, countries) || translate('transversal.selectOption')}
            label={translate('customers.createCustomer.dataCustomer.country')}
          >
            {countries && countries.map(item => (
              <PdsDropdownOption theme="dark" value={item.name} key={item.code}>
                {item.name}
              </PdsDropdownOption>
            ))}
          </PdsDropdown>
          <PdsDropdown
            className={dataClient.departamentoId ? 'input-value' : ''}
            name="departamentoId"
            onValueChange={(e) => updateLocation(constants.STATE_LABEL, e)}
            disabled={!dataClient.paisId}
            placeholder={getValuesDropdown("id", dataClient.departamentoId, states) || translate('transversal.selectOption')}
            label={translate('customers.createCustomer.dataCustomer.state')}
          >
            {states && states.map(item => (
              <PdsDropdownOption theme="dark" value={item.name} key={item.id}>
                {item.name}
              </PdsDropdownOption>
            ))}
          </PdsDropdown>
          <PdsDropdown
            className={dataClient.ciudadId ? 'input-value' : ''}
            name="ciudadId"
            onValueChange={(e) => updateLocation(constants.CITY_LABEL, e)}
            disabled={!dataClient.departamentoId}
            placeholder={ getValuesDropdown("id", dataClient.ciudadId, cities) || translate('transversal.selectOption')}
            label={translate('customers.createCustomer.dataCustomer.city')}
          >
            {cities && cities.map(item => (
              <PdsDropdownOption theme="dark" value={item.name} key={item.id}>
                {item.name}
              </PdsDropdownOption>
            ))}
          </PdsDropdown>
        </div>

        <div className="input__text--grid-3cols">
          <PdsInput
            name="direccionRut"
            value={dataClient.direccionRut}
            onInput={handleChange}
            label={translate('customers.createCustomer.dataCustomer.address')}
            {...(errors.direccionRut || {})}
          />
          {!validatePermissionsFilial(dataUser) && <PdsInput
            name="codigoPostal"
            value={dataClient.codigoPostal}
            onInput={handleChange}
            onKeyDown={isNumberKey}
            label={translate('customers.createCustomer.dataCustomer.zipCode')}
            {...(errors.codigoPostal || {})}
          />}
          <PdsInput
            name="telefonoRut"
            value={dataClient.telefonoRut}
            onInput={handleChange}
            onKeyDown={isPhoneKey}
            label={translate('customers.createCustomer.dataCustomer.rutPhone')}
            maxLength={constants.PHONE_LIMIT}
            {...(errors.telefonoRut || {})}
          />
        </div>

        <div className="input__text--grid-3cols">
          <PdsInput
            name="celularRut"
            value={dataClient.celularRut}
            onInput={handleChange}
            onKeyDown={isPhoneKey}
            label={translate('customers.createCustomer.dataCustomer.rutCellphone')}
            maxLength={constants.PHONE_LIMIT}
            {...(errors.celularRut || {})}
          />
          <PdsInput
            type="email"
            name="emailFacturacion"
            value={dataClient.emailFacturacion}
            onInput={handleChange}
            label={translate('customers.createCustomer.dataCustomer.email')}
            {...(errors.emailFacturacion || {})}
          />
          <PdsInput
            type="number"
            name="plazoPago"
            value={dataClient.plazoPago}
            onInput={handleChange}
            label={translate('customers.createCustomer.dataCustomer.maxPaymentTerm')}
            {...(errors.plazoPago || {})}
          />
        </div>
      </section>
    </section>
  );
}

export default AddClient;

/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from "react-i18next";
import Table from '../../molecules/table/Table';
import Button from '../../atoms/buttons/button';
import useLoader from '../../../utils/useLoader';
import Toolkit from '../../atoms/toolkit/Toolkit';
import { PdsButton, PdsInput } from '@pragma-dev-kit/pragma-ds-react';
import MainSelect from '../../atoms/main-select/MainSelect';
import { validateLocationData } from '../../../utils/utils';
import { validateField } from "../../../utils/utils";
import { informationAlert } from '../../molecules/alert/alert';
import InputSearchSet from '../../molecules/input-search-set/InputSearchSet';
import TablePagination from '../../molecules/table-pagination/TablePagination';
import manageCountriesConstants from '../../../constants/manage-countries-constants';
import { getAllCountriesPaginated, updateCountry } from '../../../services/getLocationList';
import TranslationComponentConstants from '../../../constants/translation-language-constants';
import './manageCountries.scss';

function ManageCountries({ props }) {
    let keyword, pageNo, pageSize, sortBy, ascSort;

    const constants = manageCountriesConstants;
    const translationConstants = TranslationComponentConstants;

    const history = useHistory();
    const [loader, showLoader, hideLoader] = useLoader();
    const [translate] = useTranslation(translationConstants.USE_TRANSLATION_GLOBAL_VALUE);

    const selectValues = [
        { value: constants.TEN_ROWS, label: `${constants.TEN_ROWS_LABEL} ${translate('components.table.resultsPerPage')}` },
        { value: constants.TWENTY_ROWS, label: `${constants.TWENTY_ROWS} ${translate('components.table.resultsPerPage')}` },
        { value: constants.FIFTY_ROWS, label: `${constants.FIFTY_ROWS} ${translate('components.table.resultsPerPage')}` }
    ];

    const headingColumns = [
        { key: constants.SORT_NAME_COLUMN, val: translate('administrator.manageCountries.countryCode'), width: constants.COLUMN_WIDTH_1 },
        { key: constants.EN_NAME_COLUMN, val: translate('administrator.manageCountries.countryName'), width: constants.COLUMN_WIDTH_3 },
        { key: constants.PHONE_CODE_COLUMN, val: translate('administrator.manageCountries.phoneCode'), width: constants.COLUMN_WIDTH_3 }
    ];

    const initialData = {
        id: constants.ZERO_VALUE,
        name: constants.EMPTY_STRING_VALUE,
        sortname: constants.EMPTY_STRING_VALUE,
        phonecode: constants.EMPTY_STRING_VALUE
    };

    const [edit, setEdit] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [errors, setErrors] = useState({});
    const [selected, setSelected] = useState(initialData);
    const [totalPages, setTotalPages] = useState(constants.PAGES_INITIAL_STATE_ONE);
    const [currentPage, setCurrentPage] = useState(constants.PAGES_INITIAL_STATE_ONE);
    const [searchItem, setSearchItem] = useState(constants.EMPTY_STRING_INITIAL_STATE);
    const [orderBy, setOrderBy] = useState({ nameColumn: constants.NAME_COLUMN, orderAsc: true });
    const [requestsPerPage, setRequestsPerPage] = useState(constants.REQUESTS_INITIAL_STATE_TWENTY);

    useEffect(() => { reloadTable() }, [currentPage, requestsPerPage, orderBy, searchItem]);

    useEffect(() => {
        showLoader();
        const dataSend = `${constants.KEYWORD_PARAM}${searchItem}${constants.PAGE_NO_PARAM}${currentPage - constants.ONE_VALUE}${constants.PAGE_SIZE_PARAM}${requestsPerPage}${constants.SORT_BY_PARAM}${orderBy.nameColumn}${constants.SORT_ASC_PARAM}${orderBy.orderAsc}`;
        if (window.location.search === constants.EMPTY_STRING_VALUE) {
            getAllCountriesPaginated(dataSend).then(response => {
                setTotalPages(response.totalPages);
                setTableData(response.resultList.slice());
                hideLoader();
            }).catch(error => {
                hideLoader();
                setTableData([]);
                informationAlert(constants.ERROR_LABEL, translate('alerts.oops'), error);
            })
        } else {
            separatePastParams(window.location.search);
        }
    }, []);

    const validate = async () => {
        const dataSend = {
            id: selected.id,
            name: selected.name,
            sortname: selected.sortname,
            phonecode: selected.phonecode
        }
        
        try {
            validateLocationData(constants.COUNTRY_LABEL, selected, () => {
                showLoader();
                updateCountry(dataSend).then(response => {
                    hideLoader();
                    switch (response.data.status) {
                        case constants.STATUS_CODE_200:
                            informationAlert(constants.SUCCESS_LABEL, translate('alerts.success'), response.data.userMessage);
                            setSelected(initialData);
                            setEdit(false);
                            reloadTable();
                            break;
                        default:
                            informationAlert(constants.ERROR_LABEL, translate('alerts.oops'), response.data.userMessage);
                            break;
                    }
                }).catch(err => {
                    hideLoader();
                    throw ({ type: constants.ERROR_LABEL, msg: translate('alerts.oopsWrong'), details: constants.EMPTY_STRING_VALUE });
                })
            })

        } catch (e) {
            informationAlert(e.type, e.msg, e.details);
            return false;
        }
    }

    const handleOnclickDataHeading = (nameTableColumn) => {
        switch (nameTableColumn) {
            case (constants.SORT_NAME_COLUMN):
                nameTableColumn = constants.CODE_COLUMN;
                break;
            case (constants.EN_NAME_COLUMN):
                nameTableColumn = constants.NAME_COLUMN;
                break;
            case (constants.PHONE_CODE_COLUMN):
                nameTableColumn = constants.INDICATIVE_COLUMN;
                break;
            default:
                break;
        }
        let orderByVal = nameTableColumn === orderBy.nameColumn ? !orderBy.orderAsc : true;
        setOrderBy({
            nameColumn: nameTableColumn,
            orderAsc: orderByVal
        });
    };

    const reloadTable = () => {
        const filterData = `${constants.KEYWORD_PARAM}${searchItem}${constants.PAGE_NO_PARAM}${currentPage - constants.ONE_VALUE}${constants.PAGE_SIZE_PARAM}${requestsPerPage}${constants.SORT_BY_PARAM}${orderBy.nameColumn}${constants.SORT_ASC_PARAM}${orderBy.orderAsc}`;
        showLoader();
        getAllCountriesPaginated(filterData).then(response => {
            setTotalPages(response.totalPages);
            setTableData(response.resultList.slice());
            hideLoader();
        }).catch(error => {
            hideLoader();
            setTableData([]);
            informationAlert(constants.ERROR_LABEL, translate('alerts.oops'), error);
        });
    };

    const separatePastParams = (url) => {
        const search = url.split(constants.EQUALS);
        keyword = (search[constants.SECOND_INDEX_POSITION].split(constants.AMPERSAND))[constants.FIRST_INDEX_POSITION];
        pageNo = (search[constants.THIRD_INDEX_POSITION].split(constants.AMPERSAND))[constants.FIRST_INDEX_POSITION];
        pageSize = (search[constants.FOURTH_INDEX_POSITION].split(constants.AMPERSAND))[constants.FIRST_INDEX_POSITION];
        sortBy = (search[constants.FIFTH_INDEX_POSITION].split(constants.AMPERSAND))[constants.FIRST_INDEX_POSITION];
        ascSort = search[constants.SIXTH_INDEX_POSITION];
        setRequestsPerPage(pageSize);
        setSearchItem(keyword);
        setCurrentPage(parseInt(pageNo));
        setOrderBy({
            nameColumn: sortBy.toString(),
            orderAsc: ascSort
        });
    };

    const actualizarData = (event) => {
        setSelected(
            {
                ...selected,
                [event.target.name]: event.target.name === constants.SORT_NAME_COLUMN ? event.target.value.toUpperCase() : event.target.value
            }
        );
    };

    const handleOnInput = (event) => {
        const { name, value } = event.target;
        actualizarData(event);
    
        const fieldErrors = validateField(name, value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            ...fieldErrors,
        }));
    };

    const handleOnItemClickListener = (row) => {
        setEdit(true);
        setSelected(row)
        window.scrollTo(constants.ZERO_VALUE, constants.ZERO_VALUE);
    };

    const getCountries = () => {
        const filterData = `${constants.KEYWORD_PARAM}${selected.name}${constants.FILTERED_PARAM}`;
        history.push(`${constants.STATES_PATH}${filterData}`)
    }

    const limpiar = () => {
        setEdit(false);
        setSelected(initialData);
    };

    const handleOnchange = (requestPerPage) => {
        setRequestsPerPage(requestPerPage);
        setCurrentPage(constants.ONE_VALUE);
    };

    const handleOnchangeSearch = (event) => {
        setSearchItem(event.target.value);
    };

    const handleOnclick = (newPage) => {
        if (newPage >= constants.ONE_VALUE && newPage <= totalPages) setCurrentPage(newPage);
    };

    return (
        <Fragment>
            <Toolkit>
                <div className='countries-toolkit__search'>
                    <InputSearchSet
                        type="text"
                        placeholder={translate('transversal.search')}
                        value={searchItem}
                        onChange={handleOnchangeSearch}
                    />
                </div>
            </Toolkit>
            <div className="form__content edit__location3">
                <div className="information">
                    <div>
                        <h3 className='information__title'>{`${edit ? translate('transversal.edit') : translate('transversal.create')} ${translate('administrator.manageCountries.country')}`}</h3>
                    </div>
                    <div className="form__input">
                        <PdsInput
                            className='input__edit'
                            name='sortname'
                            value={selected.sortname}
                            onInput={handleOnInput}
                            label={translate('administrator.manageCountries.countryCode')}
                            {...(errors.sortname || {})}
                        />
                        <PdsInput
                            className='input__edit'
                            name='name'
                            value={selected.name}
                            onInput={handleOnInput}
                            label={translate('administrator.manageCountries.countryName')}
                            {...(errors.name || {})}
                        />
                        <PdsInput
                            className='input__edit'
                            type="number"
                            name='phonecode'
                            value={selected.phonecode}
                            onInput={handleOnInput}
                            label={translate('administrator.manageCountries.phoneCode')}
                            {...(errors.phonecode || {})}
                        />
                    </div>
                    <div className="form__button">
                        <PdsButton 
                            className="manage-countries__button-clean" 
                            name={translate('transversal.clean')} 
                            onClick={limpiar}
                            >
                            {translate('transversal.clean')}
                        </PdsButton>
                        <PdsButton 
                            className="manage-countries__button-edit" 
                            name={edit ? translate('transversal.edit') : translate('transversal.create')} 
                            onClick={validate}
                            >
                            {edit ? translate('transversal.edit') : translate('transversal.create')}
                        </PdsButton>
                    </div>
                </div>
            </div>
            <Table
                tableData={tableData}
                headingColums={headingColumns}
                title={null}
                breakOn='medium'
                orderBy={handleOnclickDataHeading}
                orderColumnName={orderBy.nameColumn}
                orderColumnDirection={orderBy.orderAsc}
                onItemClickListener={handleOnItemClickListener}
            />
            <div className="o-footer__table">
                <MainSelect
                    options={selectValues}
                    name={`invoicetable`}
                    value={requestsPerPage}
                    onChange={handleOnchange}
                />
                <TablePagination
                    paginate={(event, newPage) => handleOnclick(newPage)}
                    pageAmount={totalPages}
                    currentPage={currentPage}
                />
            </div>
            {loader}
        </Fragment>
    )
}

export default ManageCountries;
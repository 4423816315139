import "./message.scss";
import React from "react";
import Proptypes from "prop-types";
import CheckIcon from "../../../images/check-circle.svg";
import CancelIcon from "../../../images/cancel-circle.svg";

const Message = ({type, message, visible}) => {
    if (!visible) return (<></>);

    return (
        <div className={`message-container ${type === "success" ? `success` : `error`}`}>
            <img className={"message-icon"} src={type === "success" ? CheckIcon : CancelIcon}/>
            <span dangerouslySetInnerHTML={{__html: message}}/>
        </div>
    );
}

Message.propTypes = {
    type: Proptypes.string,
    message: Proptypes.string,
    visible: Proptypes.bool,
}

export default Message;

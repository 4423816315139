import { useContext, useEffect, useState } from 'react'
import { Fragment } from 'react';
import { useHistory } from 'react-router';
import { AppContextUser } from '../../../provider/userInSession';
import { listClientsRequest } from '../../../services/clientService';
import useLoader from '../../../utils/useLoader';
import MainSelect from '../../atoms/main-select/MainSelect';
import Toolkit from '../../atoms/toolkit/Toolkit';
import { informationAlert } from '../../molecules/alert/alert';
import InputSearchSet from '../../molecules/input-search-set/InputSearchSet';
import TablePagination from '../../molecules/table-pagination/TablePagination';
import Table from '../../molecules/table/Table';
import { useTranslation } from "react-i18next";
import './clientsTable.scss';
import clientsTableConstants from '../../../constants/clients-table-constants';
import TranslationComponentConstants from '../../../constants/translation-language-constants';

function ClientsTable({props}) {
    const constants = clientsTableConstants;
    const translationConstants = TranslationComponentConstants;
    let keyword, pageNo, pageSize, columnName, sort;

    const history = useHistory();
    const [loader, showLoader, hideLoader] = useLoader();

    const {dataUser} = useContext(AppContextUser);
    const [dataClients, setDataClients] = useState([]);
    const [searchItem, setSearchItem] = useState(constants.EMPTY_STRING);
    const [requestsPerPage, setRequestsPerPage] = useState(constants.TWENTY_VALUE);
    const [currentPage, setCurrentPage] = useState(constants.ONE_VALUE);
    const [totalPages, setTotalPages] = useState(constants.ONE_VALUE);

    const [translate] = useTranslation(translationConstants.USE_TRANSLATION_GLOBAL_VALUE);

    const [orderBy, setOrderBy] = useState({
        nameColumn: constants.NAME_COLUMN_KEY,
        orderAsc: true
    });

    const headingClientsColumns = [
        {key: constants.NAME_COLUMN_KEY, val: translate('customers.customers.name'), width: constants.THREE_VALUE},
        {key: constants.DOCUMENT_NUMBER_COLUMN_KEY, val: translate('customers.customers.nit'), width: constants.TWO_VALUE},
        {key: constants.ADDRESS_COLUMN_KEY, val: translate('customers.customers.address'), width: constants.THREE_VALUE},
        {key: constants.PHONE_COLUMN_KEY, val: translate('customers.customers.phone'), width: constants.TWO_VALUE},
        {key: constants.STATE_COLUMN_KEY, val: translate('customers.customers.state'), width: constants.TWO_VALUE}
    ];

    const selectValues = [
        {value: constants.TEN_ROWS, label: `${constants.TEN_ROWS} ${translate('components.table.resultsPerPage')}`},
        {value: constants.TWENTY_ROWS, label: `${constants.TWENTY_ROWS} ${translate('components.table.resultsPerPage')}`},
        {value: constants.FIFTY_ROWS, label: `${constants.FIFTY_ROWS} ${translate('components.table.resultsPerPage')}`}
    ];

    const handleOnChangeSearch = (event) => {
        setSearchItem(event.target.value);
    }

    const handleOnclickDataHeading = (nameTableColumn) => {
        let orderByVal = nameTableColumn === orderBy.nameColumn ? !orderBy.orderAsc : true;
        setOrderBy({
            nameColumn: nameTableColumn,
            orderAsc: orderByVal
        });
    }

    const handleOnItemClickListener = (row) => {
        const url = `${constants.KEYWORD_PARAM}${searchItem}${constants.PAGE_NO_PARAM}${currentPage}${constants.PAGE_SIZE_PARAM}${requestsPerPage}${constants.COLUMN_NAME_PARAM}${orderBy.nameColumn}${constants.SORT_PARAM}${orderBy.orderAsc}`;
        window.history.replaceState(null, null, url);
        separatePastParams(url);
        history.push(constants.CLIENT_PATH + row.clienteId);
    }

    const handleOnchange = (requestPerPage) => {
        setRequestsPerPage(requestPerPage);
        setCurrentPage(constants.ONE_VALUE);
    }

    const handleOnclick = (newPage) => {
        if (newPage >= constants.ONE_VALUE && newPage <= totalPages) setCurrentPage(newPage);
    }

    const reloadTable = () => {
        const filterData = {
            usuarioId: dataUser.userId,
            pageNo: (currentPage - constants.ONE_VALUE),
            pageSize: requestsPerPage,
            keyword: searchItem,
            columnName: orderBy.nameColumn,
            sort: orderBy.orderAsc
        };
        showLoader();
        listClientsRequest(filterData).then(response => {
            setTotalPages(response.totalPages);
            setDataClients(response.resultList.slice());
            hideLoader();
        }).catch(error => {
            hideLoader();
            setDataClients([]);
            informationAlert(constants.ERROR_LABEL, translate('alerts.oops'), error);
        })
    }

    const separatePastParams = (url) => {
        const search = url.split(constants.EQUALS);
        keyword = (search[constants.ONE_VALUE].split(constants.AMPERSAND))[constants.ZERO_VALUE];
        pageNo = (search[constants.TWO_VALUE].split(constants.AMPERSAND))[constants.ZERO_VALUE];
        pageSize = (search[constants.THREE_VALUE].split(constants.AMPERSAND))[constants.ZERO_VALUE];
        columnName = (search[constants.FOUR_VALUE].split(constants.AMPERSAND))[constants.ZERO_VALUE];
        sort = search[constants.FIVE_VALUE];
        setRequestsPerPage(pageSize);
        setSearchItem(keyword);
        setCurrentPage(parseInt(pageNo));
        setOrderBy({
            nameColumn: columnName.toString(),
            orderAsc: sort === constants.TRUE_STRING
        });
    }

    useEffect(() => {
        if(!window.location.search==constants.EMPTY_STRING){
            separatePastParams(window.location.search);
        }
    },[])

    useEffect(() => {
        reloadTable();
    }, [currentPage, requestsPerPage, dataUser, orderBy, searchItem])
    
    return(
        <Fragment>
            <Toolkit>
                <div className="client-toolkit__search">
                    <InputSearchSet 
                        type="text"
                        placeholder={translate('transversal.search')}
                        value={searchItem}
                        onChange={handleOnChangeSearch}
                    />
                </div>
            </Toolkit>
            <div className="client-table">
                <Table
                    tableData={dataClients}
                    headingColums={headingClientsColumns}
                    title={null}
                    breakOn='medium'
                    orderBy={handleOnclickDataHeading}
                    orderColumnName={orderBy.nameColumn}
                    orderColumnDirection={orderBy.orderAsc}
                    onItemClickListener={handleOnItemClickListener}
                />
            </div>
            
            <div className="o-footer__table">
                <MainSelect 
                    options={selectValues}
                    name={`invoicetable`}
                    value={requestsPerPage}
                    onChange={handleOnchange}
                />
                <TablePagination 
                    paginate={(event, newPage) => handleOnclick(newPage)}
                    pageAmount={totalPages}
                    currentPage={currentPage}
                />
            </div>
            {loader}
        </Fragment>
    )
}

export default ClientsTable;
const TranslationComponentConstants = {
    HELP_DESK_URL: "https://www.pragma.co/mesa-de-ayuda",

    USE_TRANSLATION_GLOBAL_VALUE: "global",
    LOCAL_STORAGE_TRANSLATION_ITEM: "i18nextLng",

    LANGUAGE_ENGLISH_MODE: "en",
    LANGUAGE_SPANISH_MODE: "es",

    ALT_SELECTED_LANGUAGE_ICON: "Check language icon",
    ALT_LANGUAGE_ICON: "Language icon",

    SPANISH_HOVERED_POSITION: 0,
    ENGLISH_HOVERED_POSITION: 1,

    HEIGTH_WIDTH_IMG: "26px",

    ROOT_PATH: "/",
    BILL_PATH: "/factura",
    LOGIN_PATH: "/login",
}

export default TranslationComponentConstants;
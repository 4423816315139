import React from 'react';
import './asideLink.scss';

function AsideLink({ urlImage, texto }) {

    return (
        <div className="nav-link">
            <span className="nav-item">
                {texto}
            </span>
        </div>
    )
}
export default AsideLink; 
import Proptypes from 'prop-types';
import ContainerUpload from '../../atoms/container-upload/container-upload';
import icoDelete from '../../../images/ico-delete.svg';
import Images from '../../atoms/images/images'
import './listFiles.scss';
import IconUpload from '../../atoms/icon-upload/icon-upload';
import Separator from '../../atoms/separator/separator';



const ListFile = ({ download, data, required, title, onChange, name, disabled,...otherProps }) => {
    function downloadFile(item) {
        download(item);
    }
    return (
        <div className="list-file">
            <ContainerUpload dashed={otherProps.dashed}>
            {otherProps.view ? <div className="input-file-interno__img-upload">
                <IconUpload colorFill="#8E8E8E"/>
            </div> : '' }
            {otherProps.view ? <div className="input-file__internal-text">
                <span className="input-file__message-bold">{otherProps.message}</span>
                <br/>
            </div> : ''}
            <Separator/>
            <br />
            {data.length 
                ?   <ul>
                        {data.map((item, i) => 
                            <li className="list-file__internal-file" key={item.name || item.nombreArchivo}>
                                <div className="list-file__internal-text" onClick={downloadFile.bind(this, item)}>{item.name || item.nombreArchivo}</div>
                                {!disabled &&
                                    <button 
                                        type="button" 
                                        onClick={() => otherProps.removerArchivos(i)} 
                                        className="list-file__internal-button"
                                    >
                                        <Images Imagen={icoDelete} />
                                    </button>
                                }
                            </li>
                        )}
                    </ul>
                :   <span className="message-list__bold padding-without-file">
                        No se adjuntó ningún archivo
                    </span>
                }
                </ContainerUpload>
        </div>
    );
};

ListFile.propTypes = {
    onChange: Proptypes.func.isRequired,
    required: Proptypes.string,
    title: Proptypes.string,
    data: Proptypes.arrayOf(
        Proptypes.object
    ),
}

export default ListFile;
import { informationAlert, confirmationAlert } from "../../molecules/alert/alert";
import '../input-file-block/inputFileBlock.scss';
import { useState, useEffect } from 'react';
import InputFile from "../input-file/inputFile";
import { isExtentionsValid } from "../../../utils/utils";
import { FILE_SIZE_LIMIT } from "../../../constants/file-constant";
import { useTranslation } from "react-i18next";

const InputFileGeneric = ({ status, onBlur, limitFiles, accept, title, required, name, disabled, message, category, ...props }) => {

    const [fileArray, setFileArray] = useState([]);
    const [t] = useTranslation('global');

    useEffect(() => {
        props.handleFileChange(fileArray, props.code, category);
    }, [fileArray]);

    useEffect(() => {
        setFileArray(props.data);
    }, []);

    const onChange = (e) => {
        let files = [];
        //refactorizar y validar para multiples archivos 
        if (fileArray.length < 1) {
            for (const key in e.target.files) {
                if (isNaN(parseFloat(e.target.files[key])) && !isFinite(e.target.files[key])) {
                    if (e.target.files[key].size) {
                        let nombre = e.target.files[key].name;
                        let extencion = nombre.split(".")[nombre.split(".").length - 1];
                        let peso = parseInt(e.target.files[key].size);
                        
                        if (!isExtentionsValid(["jpg", "jpeg", "png", "pdf"], extencion)) 
                            informationAlert(nombre, t('alerts.typeFileTitle'), t('alerts.typeFileDescription'));
                        else if (peso >= parseInt(FILE_SIZE_LIMIT))
                            informationAlert(nombre, t('alerts.sizeFileTitle'), t('alerts.sizeFileDescription'));
                        else {
                            files.push(e.target.files[key]);
                            setFileArray(files);
                        }
                    }
                }
            }
        } else {
            informationAlert("error", t('alerts.oneFile'));
        }
    }

    const removeFile = (index) => {
        let fileToRemove = fileArray.find((item, i) => i === index);
        confirmationAlert(
            "warning",
            t('alerts.confirmDelete'),
            "",
            t('transversal.yes'),
            t('transversal.no'),
            () => {
                /* if (fileToRemove.archivoId) {
                    deleteFile(fileToRemove).then((res) => {
                        if (res.data.status == 200) {
                            informationAlert("success", t('alerts.success'), res.data.developerMessage);
                        } else {
                            informationAlert("error", t('alerts.alert'), res.data.userMessage);
                        }
                    });
                } */
                const filteredFileArray = fileArray.filter((item, i) => i !== index);
                setFileArray(filteredFileArray);
            }
        );
    }

    const download = (files) => {
        const archivo = files;
        const element = document.createElement("a");
        element.href = !files.urlArchivo
            ? URL.createObjectURL(files)
            : files.urlArchivo
        element.download = files.nombreArchivo;
        document.body.appendChild(element);
        element.click();
    }

    return (
        <InputFile
            onBlur={onBlur}
            onChange={onChange}
            data={fileArray}
            removeFile={removeFile}
            accept={accept}
            title={title}
            required={required}
            download={download}
            name={name}
            disabled={disabled}
            message={message}
            limitFiles={limitFiles}
        />
    );
}

export default InputFileGeneric;
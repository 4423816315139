const manageStatesConstants = {
    EMPTY_STRING_INITIAL_STATE: '',
    EMPTY_STRING_INITIAL_VALUE: '',
    EMPTY_STRING_VALUE: '',
    REQUESTS_INITIAL_STATE_TWENTY: 20,
    PAGES_INITIAL_STATE_ONE: 1,
    ZERO_VALUE: 0,
    ZERO_NUMBER: 0,
    ONE_VALUE: 1,
    EQUALS: '=',
    AMPERSAND: '&',
    ZERO_STRING: '0',
    ZERO_VALUE_STRING: '0',

    DEPARTMENT_COLUMN: 'departamento',
    COUNTRY_COLUMN: 'pais',
    WIDTH_COLUMN_3: 3,

    TEN_ROWS: 10,
    TEN_ROWS_LABEL: '10',
    TWENTY_ROWS: 20,
    TWENTY_ROWS_LABEL: '20',
    FIFTY_ROWS: 50,
    FIFTY_ROWS_LABEL: '50',

    STATE_LABEL: 'estado',
    COUNTRY_LABEL: 'pais',
    COUNTRY_ID_LABEL: 'paisId',
    STATUS_CODE_200: 200,

    SUCCESS_LABEL: 'success',
    ERROR_LABEL: 'error',

    FIRST_PAGE: 1,

    KEYWORD_PARAM: '?keyword=',
    PAGE_NO_PARAM: '&pageNo=',
    PAGE_SIZE_PARAM: '&pageSize=',
    SORT_BY_PARAM: '&sortBy=',
    SORT_ASC_PARAM: '&ascSort=',
    FILTERED_PARAM: '&pageNo=1&pageSize=20&sortBy=departamento&ascSort=true',
    CITIES_PATH: '/ciudades',

    FIRST_INDEX_POSITION: 0,
    SECOND_INDEX_POSITION: 1,
    THIRD_INDEX_POSITION: 2,
    FOURTH_INDEX_POSITION: 3,
    FIFTH_INDEX_POSITION: 4,
    SIXTH_INDEX_POSITION: 5,

    DROPDOWN_SELECTOR: 'pds-dropdown',
    DROPDOWN_CLASS: '.dropdown',
    DROPDOWN_PLACEHOLDER_CLASS: '.dropdown-placeholder',
    SPAN_ELEMENT: 'span',
    DROPDOWN_WIDTH: 'width: 100%',
};

export default manageStatesConstants;
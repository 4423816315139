const UserFormConstants = {
  GLOBAL: "global",

  ROL_ID_SOLICITANTE: 4,

  ERROR: "error",
  SUCCESS: "success",

  SETDATAUSER_TYPE: "AGREGAR",

  CASE_200: 200,
  URL_USERS: "/usuarios",

  SEARCHNAME_BUSINESS: "negocios",
  SEARCHNAME_FILIAL: "filiales",

  TYPE_TEXT: "text",
  TYPE_EMAIL: "email",

  NAME_NAME: "nombre",
  NAME_LASTNAME: "apellido",
  NAME_EMAIL: "email", 
  NAME_CODESYSTEM: "codigoSistema",
  NAME_ROL: "roleId",

  TEXT_MAX_LENGTH: 3,
};

export default UserFormConstants;
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import AsideLink from '../../atoms/nav-link/asideLink';

import './submenu.scss';
import submenuComponentConstants from '../../../constants/submenu-constants';


const SubMenu = ({ item, activeLink, setActiveLink }) => {
  const constants = submenuComponentConstants;
  
  const showSubnav = () => item.title !== activeLink ? setActiveLink(item.title) : setActiveLink(constants.EMPTY_STRING_VALUE);
  
  useEffect(() => {
    var activeSubmenu = item.subNav.find(nav => nav.path === window.location.pathname);
    if(activeSubmenu !== undefined){
      showSubnav();
    }
  }, []);

  return (
    <>        
        <NavLink to={item.path} onClick={showSubnav}>
            <div className={ item.subNav && activeLink === item.title ? "sidebarLink sidebarLink--active" : "sidebarLink"}>
              <span className={item.subNav && activeLink === item.title ? 'indicator indicator--active' : 'indicator'}></span>
                <div className={item.subNav && activeLink === item.title ? 'menu_circle menu_circle--active' : 'menu_circle'}>
                  <img src={item.iconPath} alt={item.title} className='menu_icon'/>
                </div>
                <span className={item.subNav && activeLink === item.title ? 'label label--active' : 'label'}>
                    {item.title}
                </span>
                
                <div className="sidebarLink__iconOpenClose">                  
                  <img src={
                    item.subNav && activeLink === item.title
                    ? item.iconOpened
                    : item.subNav
                    ? item.iconClosed
                    : null
                  }/>
                </div>
            </div>
        </NavLink>
        <div className={`subNav ${activeLink === item.title ? 'subNavActive' : ''}`}>
      {activeLink === item.title &&
        item.subNav.map((itemSubNav, index) => {
          return (
            <NavLink key={index} exact activeClassName="active" to={itemSubNav.path}>
                <AsideLink key={index} texto={itemSubNav.title} urlImage={itemSubNav.icon} />
            </NavLink>
          );
        })}
        </div>
    </>
  );
};

export default SubMenu;

import Aside from "../../templates/aside/aside";
import Wrapper from "../../templates/wrapper/Wrapper";
import useLoader from '../../../utils/useLoader';
import HeaderInvoiceRequest from "../../organism/header-invoice-request/HeaderInvoiceRequest";
import BrandTable from "../../templates/brandsTable/brandsTable";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function Brands(props) {
  const [t] = useTranslation("global");
  const title = t('administrator.brands.title');;
  const [loader] = useLoader();   
  const [blur, setBlur] = useState(false);  

  return (
    <Wrapper aside={ <Aside blur={blur} setBlur={setBlur}/> } blur={blur} >
        <HeaderInvoiceRequest title={title}/>        
        <BrandTable />
      {loader}
    </Wrapper>
  );
}

export default Brands;
import React from "react";
import { ClientContext } from "../../../provider/cliente";
import { AppContextUser } from '../../../provider/userInSession';
import { informationAlert } from "../../molecules/alert/alert";
import { createClient, updateClient } from "../../../services/clientService";
import "./sendClientForm.scss";
import { useParams } from "react-router-dom";
import { validateClientFields, validatePermissionsFilial } from '../../../utils/utils';
import { useTranslation } from "react-i18next";
import SendDataClientConstants from "../../../constants/sendDataClient-constants";
import { PdsButton } from '@pragma-dev-kit/pragma-ds-react';

const SendClientForm = ({ user, setIsLoading }) => {
  const constants = SendDataClientConstants;

  const { dataClient, setDataClient } = React.useContext(ClientContext);
  const { dataUser } = React.useContext(AppContextUser);
  const params = useParams();
  const [buttonState, setButtonState] = React.useState(false);


  const [translate] = useTranslation(constants.GLOBAL);

  const validarCreate = () => {
    let permissionsFilial = validatePermissionsFilial(dataUser);
    if (permissionsFilial) {
      deleteAttributes();
    }
    try {
      validateClientFields(dataClient, permissionsFilial, () => {
        if (user.userId && !(user?.rol === constants.ROL.SOLICITOR || user?.rol === constants.ROL.SUPERVISOR) && params.id) {
          updateClients();
        } else {
          requestClient();
        }
      });
    } catch (e) {
      informationAlert(e.type, e.msg, e.details);
    }
  };

  const deleteAttributes = () => {
    delete dataClient.codigoPostal;
    delete dataClient.digitoVerificacion;
  }

  const requestClient = async () => {
    setIsLoading(true);
    setButtonState(true);

    const data = await {
      ...dataClient,
      numeroDocumento: parseInt(dataClient.numeroDocumento),
      clasificacionTributariaId: parseInt(dataClient.clasificacionTributariaId) || null,
      paisId: parseInt(dataClient.paisId),
      ciudadId: parseInt(dataClient.ciudadId),
      codigoPostal: parseInt(dataClient.codigoPostal),
      plazoPago: parseInt(dataClient.plazoPago),
      digitoVerificacion: parseInt(dataClient.digitoVerificacion) || null,
      actividadEconomicaId: parseInt(dataClient.actividadEconomicaId),
      iva: parseInt(dataClient.iva),
      reteIva: parseInt(dataClient.reteIva),
      reteFuente: parseInt(dataClient.reteFuente),
      reteIca: parseInt(dataClient.reteIca),
      fechaCreacion: (dataClient.clienteId ? dataClient.fechaCreacion : new Date().toISOString()),
      usuarioId: dataUser.userId
    };

    createClient(data)
      .then((res) => {
        if (res.data.status === constants.STATUS_OK) {
          setIsLoading(false);
          setDataClient({
            type: constants.RESET
          });
          informationAlert(constants.SUCCESS, translate('alerts.success'), translate('alerts.createCustomer'));
        } else {
          setIsLoading(false);
          setButtonState(false);
          informationAlert(constants.ERROR, translate('alerts.alert'), res.data.userMessage);
        }
      })
      .catch((err) => {
        setButtonState(false);
        setIsLoading(false);
        informationAlert(constants.ERROR, translate('alerts.alert'), err);
      });
  }

  const updateClients = async () => {
    setIsLoading(true);
    setButtonState(true);

    const data = await {
      ...dataClient,
      numeroDocumento: parseInt(dataClient.numeroDocumento),
      clasificacionTributariaId: parseInt(dataClient.clasificacionTributariaId),
      paisId: parseInt(dataClient.paisId),
      ciudadId: parseInt(dataClient.ciudadId),
      codigoPostal: parseInt(dataClient.codigoPostal),
      plazoPago: parseInt(dataClient.plazoPago),
      digitoVerificacion: parseInt(dataClient.digitoVerificacion),
      actividadEconomicaId: parseInt(dataClient.actividadEconomicaId),
      iva: parseInt(dataClient.iva),
      reteIva: parseInt(dataClient.reteIva),
      reteFuente: parseInt(dataClient.reteFuente),
      reteIca: parseInt(dataClient.reteIca),
      fechaCreacion: (dataClient.clienteId ? dataClient.fechaCreacion : new Date().toISOString()),
      usuarioId: dataUser.userId
    };

    updateClient(data).then((res) => {
      if (res.data.status === 200) {
        setIsLoading(false);
        informationAlert("success", translate('alerts.success'), res.data.userMessage);
      } else {
        setButtonState(false);
        setIsLoading(false);
        informationAlert("error", translate('alerts.alert'), res.data.userMessage);
      }
    }).catch((err) => {
      setButtonState(false);
      setIsLoading(false);
      informationAlert("error", translate('alerts.alert'), err.error);
    })

  };

  return (
    <div className="button__container">
      <PdsButton className="send-client__button" name={translate('transversal.send')} onClick={validarCreate} disabled={buttonState}>{translate('transversal.send')}</PdsButton>
    </div>
  );
};

export default SendClientForm;

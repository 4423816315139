const DataClientConstants = {

  GLOBAL: "global",

  EMPTY: "",

  TIME_500: 500,

  NUMBER_MINUS_ONE: -1,
  NUMBER_ZERO: 0,
  NUMBER_ONE: 1,
  NUMBER_TWO: 2,

  TYPE_ADD: "AGREGAR",
  TYPE_CHANGE: "change",
  TYPE_CHANGE_CLIENT_ID: "CHANGE-CLIENTE-ID",
  TYPE_NUMBER: "number",
  TYPE_RESET: "RESET"

};

export default DataClientConstants
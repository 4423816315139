const AddClientComponentConstants = {
    TRANSLATION_GLOBAL_VALUE: 'global',

    ADD_LABEL: 'AGREGAR',
    COUNTRY_LABEL: 'pais',
    STATE_LABEL: 'estado',
    CITY_LABEL: 'ciudad',
    USER_ID: "usuarioId",
    ERROR_MESSAGE: 'Error al hacer fetching: ',

    ZERO_STRING: '0',
    ZERO_NUMBER: 0,
    INDEX_ONE: 1,
    INDEX_ZERO: 0,
    PHONE_LIMIT: 14,

    PLUS_SIGN: '+',
    EMPTY_STRING: '',

    DROPDOWN_SELECTOR: 'pds-dropdown',
    DROPDOWN_CLASS: '.dropdown',
    WIDTH_100: 'width: 100%',

    NAME_SPLITTER: 'name="',
    BLANK_SPLITTER: '"'
}

export default AddClientComponentConstants;
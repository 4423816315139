/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';

import Text from "../../atoms/text/text";
import { useHistory } from "react-router-dom";
import Loader from "../../atoms/loader/loader";
import { useTranslation } from 'react-i18next';
import Table from '../../molecules/table/Table';
import Button from "../../atoms/buttons/button";
import useLoader from '../../../utils/useLoader';
import Message from "../../atoms/message/message";
import Toolkit from "../../atoms/toolkit/Toolkit";
import Dialog from "../../molecules/dialog/dialog";
import Checkbox from "../../atoms/checkbox/checkbox";
import ExportIcon from '../../../images/ico-export.svg'
import MainSelect from '../../atoms/main-select/MainSelect';
import { informationAlert } from '../../molecules/alert/alert';
import { AppContextUser } from '../../../provider/userInSession';
import TextBlockTwo from "../../molecules/text-blocks/textBlockTwo";
import InputSearchSet from '../../molecules/input-search-set/InputSearchSet';
import TablePagination from '../../molecules/table-pagination/TablePagination';
import { avoidNonNumericValues, parseCurrencyToVal } from '../../../utils/utils';
import { INPUT_MAX_LENGTH_WITHOUT_DECIMAL_SEPARATOR } from "../../../utils/constans";
import invoiceStateTableConstants from '../../../constants/invoice-state-table-constants';
import { generateCsv, getCurrentSequences, getInvoiceData } from '../../../services/invoiceService';
import './InvoiceTable.scss';
import { PdsButton, PdsCheckbox, PdsInput } from '@pragma-dev-kit/pragma-ds-react';

const InvoiceTable = (props) => {
    let filtro, page, requests, columnSearched, orderSearched;

    const constants = invoiceStateTableConstants;

    const history = useHistory();
    const [translate] = useTranslation(constants.GLOBAL);
    const { dataUser } = useContext(AppContextUser);
    const [loader, showLoader, hideLoader] = useLoader();

    const [estados, setEstados] = useState([]);
    const [sequences, setSequence] = useState([]);
    const [isShowingDialog, showDialog] = useState(false);
    const [sequenceErrors, setSequenceError] = useState([]);
    const [estadosPropios, setEstadosPropios] = useState([]);
    const [dataListInvoice, setDataListInvoice] = useState([]);
    const [isGeneratingCsv, setGeneratingCsv] = useState(false);
    const [trmValue, setTrm] = useState(constants.ZERO_STRING_VALUE);
    const [pageAmount, setPageAmount] = useState(constants.ONE_VALUE_INITIALIZER);
    const [currentPage, setCurrentPage] = useState(constants.ONE_VALUE_INITIALIZER);
    const [searchItem, setSearchItem] = useState(constants.EMPTY_STRING_INITIALIZER);
    const [automaticRefreshCounter, setAutomaticRefresh] = useState(constants.ZERO_VALUE);
    const [exportButtonText, setExportButtonText] = useState(translate('transversal.export'));
    const [orderBy, setorderBy] = useState({ nameColumn: constants.DATE_REQUEST, orderAsc: false });
    const [requestsPerPage, setRequestsPerPage] = useState(constants.REQUESTS_INITIAL_STATE_TWENTY);
    const [message, setMessage] = useState({ visible: false, type: constants.SUCCESS_LABEL, message: constants.EMPTY_STRING_INITIALIZER });

    useEffect(() => { if (window.location.search !== constants.EMPTY_STRING_COMPARATOR) separatePastParams(window.location.search) }, []);

    useEffect(() => { if (exportButtonText) setExportButtonText(translate('transversal.export')) }, [translate]);

    useEffect(() => {
        if (dataUser.rol === constants.ADMIN_ROL) {
            if (!estados.length)
                setEstados([constants.PENDING_STATUS, constants.REVIEWED_STATUS, constants.EXPORTED_STATUS]);
            setEstadosPropios([constants.RETURNED_STATUS, constants.SAVED_STATUS]);
        } else if (dataUser.rol === constants.APPROVER_ROL) {
            if (!estados.length) {
                setEstados([constants.PENDING_STATUS, constants.REVIEWED_STATUS, constants.EXPORTED_STATUS]);
                setEstadosPropios([constants.RETURNED_STATUS, constants.SAVED_STATUS]);
            }
        } else if (dataUser.rol === constants.SOLICITANTE_ROL || dataUser.rol === constants.SUPERVISOR_ROL) {
            if (!estados.length)
                setEstados([constants.RETURNED_STATUS, constants.PENDING_STATUS, constants.SAVED_STATUS]);
        }

        if (estados.length > constants.ZERO_VALUE) {
            const dataSend = {
                usuarioId: dataUser.userId,
                lista: [{}],
                estados: estados,
                estadosPropios: estadosPropios,
                nombreRol: dataUser.rol,
                numeroPagina: (currentPage - constants.ONE_VALUE),
                peticionesPorPagina: requestsPerPage,
                busqueda: searchItem,
                nombreColumna: orderBy.nameColumn,
                orden: orderBy.orderAsc
            }

            if (dataSend.nombreRol && estados.length > constants.ZERO_VALUE) {
                showLoader();
                const response = getInvoiceData(dataSend);
                response.then((resp) => {
                    hideLoader();
                    if (resp.status && resp.status === constants.STATUS_CODE_200) {
                        setPageAmount(resp.data.cantidadPaginasTotales);
                        let list = findFilialName(resp.data.lista);
                        setDataListInvoice(list);
                    } else {
                        informationAlert(constants.ERROR_LABEL, translate('alerts.oops'), resp.userMessage);
                        setDataListInvoice([]);
                    }
                })
            }
        }
    }, [currentPage, requestsPerPage, dataUser, orderBy, searchItem, estados, automaticRefreshCounter]);

    const selectValues = [
        { value: constants.TEN_ROWS, label: `${constants.TEN_ROWS_LABEL} ${translate('components.table.resultsPerPage')}` },
        { value: constants.TWENTY_ROWS, label: `${constants.TWENTY_ROWS} ${translate('components.table.resultsPerPage')}` },
        { value: constants.FIFTY_ROWS, label: `${constants.FIFTY_ROWS} ${translate('components.table.resultsPerPage')}` }
    ];

    const headingColumns = [
        { key: constants.FAC_REQUEST, val: translate('invoices.pendingInvoice.requestNumber'), width: constants.COLUMN_WIDTH_6 },
        { key: constants.USER_EMAIL, val: translate('invoices.pendingInvoice.user'), width: constants.COLUMN_WIDTH_11 },
        { key: constants.DATE_REQUEST, val: translate('invoices.pendingInvoice.requestDate'), width: constants.COLUMN_WIDTH_8 },
        { key: constants.LIMIT_DATE, val: translate('invoices.pendingInvoice.deadline'), width: constants.COLUMN_WIDTH_8 },
        {
            key: constants.DOC_ID, val: translate('invoices.pendingInvoice.nit'), width: constants.COLUMN_WIDTH_9, type: constants.TEXT_TYPE,
            renderAs: [constants.RENDER.PERCENTAGE, constants.DOC_ID, constants.RENDER.VERIFICATION]
        },
        { key: constants.CLIENT_NAME, val: translate('invoices.pendingInvoice.customer'), width: constants.COLUMN_WIDTH_14 },
        {
            key: constants.TOTAL_VALUE, val: translate('invoices.pendingInvoice.amount'), width: constants.COLUMN_WIDTH_10,
            align: constants.ALIGN_RIGHT, type: constants.CURRENCY, currencyTypeFormatter: constants.CURRENCY_TYPE
        },
        { key: constants.STATUS_VALUE, val: translate('invoices.pendingInvoice.state'), width: constants.COLUMN_WIDTH_8, textTransform: constants.CAPITALIZE },
        { key: constants.CONSECUTIVE_VALUE, val: translate('invoices.pendingInvoice.consecutive'), width: constants.COLUMN_WIDTH_9 },
        { key: constants.DATE_FAC, val: translate('invoices.pendingInvoice.billingDate'), width: constants.COLUMN_WIDTH_8 },
        { key: constants.FILIAL_NAME, val: translate('invoices.pendingInvoice.subsidiary'), width: constants.COLUMN_WIDTH_9 },
    ];

    const handleOnclick = (newPage) => {
        if (newPage >= constants.ONE_VALUE && newPage <= pageAmount) setCurrentPage(newPage);
    }

    const dismissDialog = () => showDialog(false);

    const handleOnChangeSearch = (event) => setSearchItem(event.target.value);

    const handleOnchange = (requestPerPage) => {
        setRequestsPerPage(requestPerPage);
        setCurrentPage(constants.ONE_VALUE);
    }

    const handleOnclickDataHeading = (nameTableColumn) => {
        let orderByVal = nameTableColumn === orderBy.nameColumn ? !orderBy.orderAsc : true;
        setorderBy({ nameColumn: nameTableColumn, orderAsc: orderByVal });
    };

    const handleOnItemClickListener = (row) => {
        const url = `${constants.FILTER_PARAM}${searchItem}${constants.PAGE_PARAM}${currentPage}${constants.REQUEST_PARAM}
            ${requestsPerPage}${constants.ORDER_BY_PARAM}${orderBy.nameColumn}${constants.ORDER_PARAM}${orderBy.orderAsc}`;
        window.history.replaceState(null, null, url);
        separatePastParams(url);
        history.push(constants.INVOICE_PATH + row.facSolicitudId);
    };

    const handleSequenceChange = (event, index) => {
        const newSequences = [...sequences];
        newSequences[index].sequence = parseInt(event.target.value || constants.ZERO_STRING_VALUE);
        setSequence(newSequences);
        validateAllSequences();
    }

    const handleOnExportCsv = async () => {
        const currentSequences = await getCurrentSequences();
        setSequence(currentSequences);
        setSequenceError([]);
        setTrm(constants.ZERO_STRING_VALUE);
        setExportButtonText(translate('transversal.export'));
        setMessage({ visible: false, type: constants.SUCCESS_LABEL, message: constants.EMPTY_STRING_INITIALIZER });
        showDialog(true);
    }

    const findFilialName = (listInvoices) => {
        listInvoices.forEach(item => {
            let filialId = +item.filialId;
            let filial = props.filiales.find(filial => filial.code === filialId);
            item[constants.FILIAL_NAME] = filial.name;
        })
        return listInvoices
    }

    const handleSuggestedChange = (event, index) => {
        if (event.target.checked) {
            const newSequences = [...sequences];
            newSequences[index].sequence = newSequences[index].suggested_sequence;
            setSequence(newSequences);
        } else {
            const newSequences = [...sequences];
            newSequences[index].sequence = constants.ZERO_STRING_VALUE;
            setSequence(newSequences);
        }
        validateAllSequences();
    }

    const separatePastParams = (url) => {
        const search = url.split(constants.EQUALS);
        const requestBurned = (search[constants.FOURTH_INDEX_POSITION].split(constants.AMPERSAND))[constants.FIRST_INDEX_POSITION];

        filtro = (search[constants.SECOND_INDEX_POSITION].split(constants.AMPERSAND))[constants.FIRST_INDEX_POSITION];
        page = (search[constants.THIRD_INDEX_POSITION].split(constants.AMPERSAND))[constants.FIRST_INDEX_POSITION];
        requests = Number.isNaN(requestBurned) ? requestBurned : constants.REQUESTS_INITIAL_STATE_TWENTY
        columnSearched = (search[constants.FIFTH_INDEX_POSITION].split(constants.AMPERSAND))[constants.FIRST_INDEX_POSITION];
        orderSearched = search[constants.SIXTH_INDEX_POSITION];

        setRequestsPerPage(requests);
        setSearchItem(filtro);
        setCurrentPage(parseInt(page));
        setorderBy({ nameColumn: columnSearched.toString(), orderAsc: orderSearched === constants.TRUE });
    }

    const validateAllSequences = () => {
        let invalidSequence = false;
        const newSequenceErrors = [...sequenceErrors];
        sequences.forEach((sequence, index) => {
            if (parseInt(sequence.sequence) < parseInt(sequence.suggested_sequence)) {
                invalidSequence = true;
                newSequenceErrors[index] = translate('invoices.pendingInvoice.alertAmountSuggested');
            } else {
                newSequenceErrors[index] = null;
            }
        });
        setSequenceError(newSequenceErrors)
        if (invalidSequence) setExportButtonText(null);
        else setExportButtonText(translate('transversal.export'));
    }

    const handleExportConfirm = () => {
        setGeneratingCsv(true);
        generateCsv({
            sedes: sequences.map(sequence => ({
                sedeId: sequence.sede_id,
                nombre: sequence.name,
                codigoZona: sequence.prefix,
                consecutivo: parseInt(sequence.sequence || constants.ZERO_VALUE),
                esConsecutivo: sequence.isSequence,
            })),
            trm: parseFloat(trmValue),
            usuarioId: dataUser.userId,
        }).then(response => {
            setExportButtonText(null);
            setGeneratingCsv(false);
            setMessage({
                visible: true,
                type: constants.SUCCESS_LABEL,
                message: `${translate('invoices.pendingInvoice.successExportList', { amount: response.invoicesCount })} <a target='_blank' href='${response.url}'>${translate('transversal.here')}</a>`,
            });
            setAutomaticRefresh(automaticRefreshCounter + constants.ONE_VALUE);
        }).catch(e => {
            setExportButtonText(translate('transversal.export'));
            setGeneratingCsv(false);
            setMessage({
                visible: true,
                type: constants.ERROR_LABEL,
                message: `${e.message}`,
            });
        });
    }

    return (
        <>
            <Dialog visible={isShowingDialog}
                title={translate('invoices.pendingInvoice.exportList')}
                positiveText={exportButtonText}
                positiveListener={handleExportConfirm}
                negativeText={translate('transversal.close')}
                negativeListener={dismissDialog}
                overlayListener={dismissDialog}>
                <div className={"exportdialog-content"}>
                    {sequences.map((sequence, index) => (
                        <div className={"sequence-item"}>
                            <span className={`sequence-prefix`}>{`${translate('invoices.pendingInvoice.consecutive')} ${sequences[index].prefix}`}</span>
                            <Text className={"sequence-value"}
                                onChange={(e) => handleSequenceChange(e, index)}
                                Type="number"
                                errorMessage={sequenceErrors[index]}
                                value={sequences[index].sequence}
                                readOnly={false} />
                            <PdsCheckbox
                                name={`cb-suggested-${sequences[index].prefix}`}
                                checked={sequence.sequence.toString() === sequence.suggested_sequence.toString()}
                                onPdschange={(e) => handleSuggestedChange(e, index)}
                            >{translate('transversal.suggested')}</PdsCheckbox>
                        </div>
                    ))}
                    <div className={"trm-item"}>
                        <span className={"sequence-prefix"}>{translate('invoices.pendingInvoice.trmPesos')}</span>
                        <TextBlockTwo
                            onChange={(e) => parseCurrencyToVal(constants.ONE_VALUE, e, (event, value) => setTrm(value))}
                            type={constants.TEXT_TYPE}
                            name="valor"
                            onKeyDown={(e) => avoidNonNumericValues(e)}
                            readOnly={false}
                            maxLength={INPUT_MAX_LENGTH_WITHOUT_DECIMAL_SEPARATOR}
                        />
                    </div>
                    <Loader isLoading={isGeneratingCsv} />
                    <Message visible={message.visible} type={message.type} message={message.message} />
                </div>
            </Dialog>

            <Toolkit>
                {!(dataUser.rol === constants.SOLICITANTE_ROL || dataUser.rol === constants.SUPERVISOR_ROL) &&
                    <PdsButton className='brand-toolkit__button' onClick={handleOnExportCsv} name={translate('invoices.pendingInvoice.exportList')} icon={ExportIcon}>{translate('invoices.pendingInvoice.exportList')}</PdsButton>
                }
                <div className="invoice-toolkit__search">
                    <InputSearchSet
                        type="text"
                        placeholder={translate('transversal.search')}
                        value={searchItem}
                        onChange={handleOnChangeSearch}
                    />
                </div>
                
            </Toolkit>
            <div className="invoice-table">
                <Table
                    tableData={dataListInvoice}
                    headingColums={headingColumns}
                    title={null}
                    breakOn='medium'
                    orderBy={handleOnclickDataHeading}
                    orderColumnName={orderBy.nameColumn}
                    orderColumnDirection={orderBy.orderAsc}
                    onItemClickListener={handleOnItemClickListener}
                />
            </div>
            
            <div className="o-footer__table">
                <MainSelect
                    options={selectValues}
                    name={`invoicetable`}
                    value={requestsPerPage}
                    onChange={handleOnchange}
                />
                <TablePagination
                    paginate={(event, newPage) => handleOnclick(newPage)}
                    pageAmount={pageAmount}
                    currentPage={currentPage}
                />
            </div>
            {loader}
        </>
    );
};

export default InvoiceTable;

import React from "react";
import Proptypes from "prop-types";
import "./checkbox.scss";

function Checkbox({ name, label, handleChange, checked, verticalMode, readOnly, shortMargin }) {

    return (
        <div className={`${verticalMode ? 'checkbox-vertical' : ''} checkbox--custom ${shortMargin ? 'checkbox--withLessMargin' : ''}`}>
            <input
                type="checkbox"
                name={name}
                id={name}
                onChange={handleChange}
                checked={checked}
                readOnly={readOnly}
            />
            <label htmlFor={name}>{label}</label>
        </div>
    );
}

Checkbox.propTypes = {
    label: Proptypes.string.isRequired,
    handleChange: Proptypes.func.isRequired,
    name: Proptypes.number.isRequired,
    checked: Proptypes.bool,
    verticalMode: Proptypes.bool,
}

export default Checkbox;

import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
import Aside from '../../templates/aside/aside';
import Wrapper from '../../templates/wrapper/Wrapper';
import HomeTemplate from '../../templates/homeTemplate/homeTemplate';

import clientIcon from '../../../images/createClient-icon.svg';
import brandicon from '../../../images/createBrand-icon.svg';
import billIcon from '../../../images/createBill-icon.svg';
import policyIcon from '../../../images/createPolicy-icon.svg';
import HomeComponentConstants from '../../../constants/home-constants';
import './home.scss';

function Home({ props }) {
    const constants = HomeComponentConstants;

    const [t] = useTranslation(constants.TRANSLATION_GLOBAL);
    const [blur, setBlur] = useState(false);

    const HomeLinks = [
        {
            image: clientIcon,
            text: t('home.createCustomer'),
            link: constants.CLIENT_PATH,
            users: [constants.USER_APPLICANT, constants.USER_SUPERVISER, constants.USER_ADMIN]
        },
        {
            image: brandicon,
            text: t('home.createBrand'),
            link: constants.BRAND_PATH,
            users: [constants.USER_ADMIN]
        },
        {
            image: billIcon,
            text: t('home.createInvoice'),
            link: constants.BILL_PATH,
            users: [constants.USER_APPLICANT, constants.USER_SUPERVISER, constants.USER_ADMIN]
        },
        {
            image: policyIcon,
            text: t('home.createPolicy'),
            link: constants.POLICY_PATH,
            users: [constants.USER_APPLICANT, constants.USER_SUPERVISER, constants.USER_ADMIN]
        },
        {
            image: billIcon,
            text: t('home.pendingInvoices'),
            link: constants.PENDING_BILL_PATH,
            users: [constants.USER_APPROVER]
        },
        {
            image: billIcon,
            text: t('home.invoiceHistory'),
            link: constants.HISTORY_BILL_PATH,
            users: [constants.USER_APPROVER]
        },
        {
            image: policyIcon,
            text: t('home.pendingInvoices'),
            link: constants.PENDING_POLICY_PATH,
            users: [constants.USER_APPROVER]
        },
        {
            image: policyIcon,
            text: t('home.invoiceHistory'),
            link: constants.HISTORY_POLICY_PATH,
            users: [constants.USER_APPROVER]
        }
    ]

    const title = t('home.title');
    const subtTitle = t('home.subTitle');
    const supportEmail = t('home.supportEmail');
    const message = <p>{t('home.help')}</p>;
    const email = <a href={`${constants.SEND_EMAIL}${supportEmail}`}>{supportEmail}</a>

    return (
        <main className='main-home'>
            <Wrapper aside={<Aside blur={blur} setBlur={setBlur} />} blur={blur}>
                <HomeTemplate title={title} subtTitle={subtTitle} links={HomeLinks} message={message} email={email} />
            </Wrapper>
        </main>
        
    )
}

export default Home;
import { ROLES, COGNITO_ROLES } from '../config/roles'
import clienteAxios from "../api/axios";
import { API_BASE_URL, API_INVOICES_CONTEXT } from "../utils/constans";

const AuthService = async (dataIdToken) => {
    let isRedirect = true;
    let rol;

    const nameArray = dataIdToken.email.split(".");
    const userName = `${nameArray[0]} ${nameArray[1].split("@")[0]}`;
    // nameArray[0][0] para extraer la primer letra de ese string
    const initialLetters = `${nameArray[0][0]}${nameArray[1].split("@")[0][0]}`;
    if (dataIdToken["cognito:groups"].length > 1) {
        dataIdToken["cognito:groups"].forEach((rolCognito) => {
            switch (rolCognito) {
                case COGNITO_ROLES.ADMIN:
                    rol = ROLES.ADMIN;
                    isRedirect = false;
                    break;
                case COGNITO_ROLES.APROBADOR:
                    rol = ROLES.APROBADOR;
                    isRedirect = false;
                    break;
                case COGNITO_ROLES.SOLICITANTE:
                    rol = ROLES.SOLICITANTE;
                    isRedirect = false;
                    break;
                case COGNITO_ROLES.AUDITOR:
                    rol = ROLES.AUDITOR;
                    isRedirect = false;
                    break;
                case COGNITO_ROLES.SUPERVISOR:
                    rol = ROLES.SUPERVISOR;
                    isRedirect = false;
                    break;
                default:
                    break;
            }
        });
    } else {
        isRedirect = true;
    }

    const data = {
        userName,
        rol,
        userId: dataIdToken.identities[0].userId,
        initialLetters,
    };

    return { data, isRedirect };
};

const doLogin = async () => {
    await clienteAxios(
        `${API_BASE_URL}${API_INVOICES_CONTEXT}login`,
        null,
        null,
        "GET")
        .then(() => {
        })
        .catch((err) => console.error(err)
        );
}

export { AuthService, doLogin };

const ConceptoConstants = {

  GLOBAL: 'global',

  BOOLEANS: {
    TRUE: true,
    FALSE: false
  },

  EMPTY: '',

  SIGNS: {
    HASHTAG: '#'
  },

  MAX_LENGTH: 500,

  NUMBERS: {
    ZERO: 0,
    ONE: 1,
    TWO: 2,
  },

  CASES:{
    BUY_ORDER: 'buyOrder',
    BRAND: 'marca',
    DESCRIPTION: 'description',
    BUSSINES: 'negocio',
    OPPORTUNITY: 'oportunidad',
    INPUT_NO_FOUND: 'input not found'
  }
}

export default ConceptoConstants
import Translation from '../../organism/translation-language/translation-language';
import Notifications from '../../organism/notifications/notifications';
import { CookiesProvider } from 'react-cookie';
import './Header.scss'

const Header = ({ showNotifications, setShowNotifications }) => {
    return (
        <header className='header'>
            <div className="header__lineLeft">
                <div className="header__lineLeft-squeareLeft"></div>
            </div>

            <div className="header__links">
                <div className="header__links-container">
                    <div className="header__links-container-squeare"></div>
                    <Translation />
                    <CookiesProvider>
                        <Notifications
                            display={showNotifications}
                            setDisplay={setShowNotifications}
                        />
                    </CookiesProvider>
                </div> 
            </div>

            <div className="header__lineRight">
                <div className="header__lineRight-squeareRight"></div>
            </div>
        </header>
    )
}

export default Header

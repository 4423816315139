import TextAreaConstants from '../../../constants/text-area-constants';
import './textArea.scss';
import React, { useState } from 'react';

const constants = TextAreaConstants

const TextArea = ({ onChange, placeholder, value, maxLength = constants.MAX_LENGTH, disabled, ...otherTextAreaProps }) =>{

  const [countChar, setcountChar] = useState(constants.NUMBERS.ZERO)
  const handleTextAreaHeight = function (e) {
    var element = e.target;

    element.style.overflow = constants.HIDDEN;
    element.style.height = constants.NUMBERS.ZERO;
    element.style.height = element.scrollHeight + constants.PX;

    setcountChar(element.value.length)
  };



  return(
    <div className="TextArea">
        <textarea 
          onKeyUp={handleTextAreaHeight}
          onChange={onChange}
          className="objeto"
          maxLength={maxLength}
          placeholder={placeholder} 
          value={value}
          disabled={disabled}
          {...otherTextAreaProps}
        />
        {!disabled && <span className="countChar">{countChar}/{maxLength}</span>} 
    </div>
  );
};

export default TextArea

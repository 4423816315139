const SendDataClientConstants = {
    GLOBAL: "global",
    STATUS_OK: 200,
    RESET: "RESET",
    ROL: {
        SOLICITOR: "Solicitante",
        SUPERVISOR: "Supervisor",
    },
    SUCCESS: "success",
    ERROR: "error",
}

export default SendDataClientConstants;
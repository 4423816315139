const ClientFilesComponentConstants = {
    ERROR_LABEL: "error",
    WARNING_LABEL: "warning",
    SUCCESS_LABEL: "success",
    ADD_LABEL: "AGREGAR",
    FILES_LABEL: "files",
    OBJECT_LABEL: "object",
    ADD_FILES_LABEL: "ADD-FILES",

    TYPE_STRING: "string",
    TYPE_PDF: "application/pdf",

    ZERO_VALUE: 0,
    ONE_VALUE: 1,
    STATUS_CODE_200: 200,
    EMPTY_VALUE: "",
    LIMIT_5_FILES: 5,
    LIMIT_1_FILE: 1,

    DOT_SIGN: ".",
    COMMA_SIGN: ",",

    JPG_EXTENSION: "jpg",
    JPG_EXTENSION_UPPERCASE: "JPG",
    JPGE_EXTENSION: "jpge",
    JPGE_EXTENSION_UPPERCASE: "JPGE",
    PNG_EXTENSION: "png",
    PNG_EXTENSION_UPPERCASE: "PNG",
    PDF_EXTENSION: "pdf",
    PDF_EXTENSION_UPPERCASE: "PDF",

    ANCHOR_ELEMENT: "a",

    NOT_FOUND_FILE: 'Archivo no encontrado o indefinido',
    NOT_VALID_FILE: 'El archivo no es ni una URL ni un Blob/File válido.',
}

export default ClientFilesComponentConstants;
import { useEffect, useState, useContext, Fragment } from "react";
import Aside from "../../templates/aside/aside";
import Wrapper from "../../templates/wrapper/Wrapper";
import HeaderInvoiceRequest from "../../organism/header-invoice-request/HeaderInvoiceRequest";
import PolicyTable from "../../templates/policyTable/policyTable";
import { useTranslation } from "react-i18next";

import { AppContextUser } from '../../../provider/userInSession';
import PolicyPendingTableConstants from "../../../constants/policy-pending-table-constants";


const PolicyPendingTable = () => {
    const constants = PolicyPendingTableConstants;
    const [translate] = useTranslation(constants.GLOBAL);
    const title = translate('policy.pendingPolicy.policyRequests');
    const description = translate('policy.pendingPolicy.description');
    const [estados, setEstados] = useState([]);
    const [estadosPropios, setEstadosPropios] = useState([]);
    const { dataUser, setDataUser } = useContext(AppContextUser);

    useEffect(() => {
        if (dataUser.rol === constants.ROLES.ADMIN) {
            if (!estados.length)
                setEstados([constants.ESTADOS.PENDIENTE, constants.ESTADOS.DEVUELTA]);
        } else if (dataUser.rol === constants.ROLES.APPROVER) {
            if (!estados.length) {
                setEstados([constants.ESTADOS.PENDIENTE]);
                setEstadosPropios([constants.ESTADOS.DEVUELTA]);
            }
        } else if (dataUser.rol === constants.ROLES.SOLICITOR || dataUser.rol === constants.ROLES.SUPERVISOR) {
            if (!estados.length)
                setEstados([constants.ESTADOS.DEVUELTA, constants.ESTADOS.PENDIENTE]);
        }
    });
    const [blur, setBlur] = useState(false);
    return (        
        <Fragment>
            <Wrapper aside={<Aside blur={blur} setBlur={setBlur}/>} blur={blur}>
                <HeaderInvoiceRequest title={title} description ={description}/>
                <PolicyTable estados={estados} estadosPropios={estadosPropios} />
            </Wrapper>
        </Fragment>
    );
}

export default PolicyPendingTable;
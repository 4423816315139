import { React, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CreateBrandForm from "../../organism/create-brand-form/createBrandForm";
import useLoader from '../../../utils/useLoader';
import Aside from "../../templates/aside/aside";
import { BrandContext } from "../../../provider/brand";
import Wrapper from "../../templates/wrapper/Wrapper";
import HeaderInvoiceRequest from "../../organism/header-invoice-request/HeaderInvoiceRequest";
import RoundButton from "../../atoms/round-button/roundButton";
import Back from "../../../images/ico-back.svg";
import "./createBrand.scss";
import { useTranslation } from "react-i18next";
import TranslationComponentConstants from "../../../constants/translation-language-constants";
import CreateBrandConstants from "../../../constants/create-brand-contants";

const CreateBrand = () => {
  const translationConstants = TranslationComponentConstants;
  const constants = CreateBrandConstants;

  const { dataBrand, setDataBrand } = useContext(BrandContext);
  const [loader, showLoader, hideLoader] = useLoader();
  const [isEditing, setIsEditing] = useState(false);
  const [translate] = useTranslation(translationConstants.USE_TRANSLATION_GLOBAL_VALUE);
  const [blur, setBlur] = useState(false);
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
      setDataBrand({ type: constants.TYPE_RESET });
    }
    return () => { };
  }, [params.id]);
  
  const TITULO_CREAR_MARCA = translate('administrator.createBrand.title');
  const TITULO_EDITAR_MARCA = translate('administrator.createBrand.edit');

  const handleBack = () => {
    window.history.back();
  };

  return (
    <Wrapper aside={<Aside blur={blur} setBlur={setBlur} />} blur={blur} >
      <header className="BrandHeader">
        <div className="BrandHeaderTitleContainer">
          <div className="BrandHeaderButtonContainer">
          <RoundButton icon={Back} onClick={handleBack} />
          </div>
          <HeaderInvoiceRequest title={isEditing ? TITULO_EDITAR_MARCA : TITULO_CREAR_MARCA} />
        </div>
      </header>
      <CreateBrandForm />
      {loader}
    </Wrapper>
  );
}

export default CreateBrand;
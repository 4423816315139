import React from "react";

function IconUpload({colorFill}) {
  
  const fillColor = colorFill || '#6429CD';
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_615_11164)">
        <path d="M8.20265 0.0398491C8.26612 0.0658814 8.32559 0.104668 8.37713 0.15621L12.5253 4.30436C12.7336 4.51264 12.7336 4.85033 12.5253 5.05861C12.317 5.26689 11.9793 5.26689 11.771 5.05861L8.53333 1.82091V10.4889C8.53333 10.7834 8.29455 11.0222 8 11.0222C7.70545 11.0222 7.46667 10.7834 7.46667 10.4889V1.82092L4.22898 5.05861C4.0207 5.26689 3.68301 5.26689 3.47473 5.05861C3.26645 4.85033 3.26645 4.51264 3.47473 4.30436L7.62168 0.157407C7.62558 0.153488 7.62953 0.14963 7.63354 0.145833C7.7291 0.0554349 7.85808 0 8 0C8.07174 0 8.14017 0.0141644 8.20265 0.0398491Z" fill={fillColor} />
        <path d="M0.533333 9.95625C0.827885 9.95625 1.06667 10.195 1.06667 10.4896V13.8081C1.06667 14.1067 1.18529 14.3931 1.39644 14.6043C1.60759 14.8154 1.89398 14.934 2.19259 14.934H13.8074C14.106 14.934 14.3924 14.8154 14.6036 14.6043C14.8147 14.3931 14.9333 14.1067 14.9333 13.8081V10.4896C14.9333 10.195 15.1721 9.95625 15.4667 9.95625C15.7612 9.95625 16 10.195 16 10.4896V13.8081C16 14.3896 15.769 14.9473 15.3578 15.3585C14.9466 15.7697 14.3889 16.0007 13.8074 16.0007H2.19259C1.61108 16.0007 1.05339 15.7697 0.642195 15.3585C0.231005 14.9473 0 14.3896 0 13.8081V10.4896C0 10.195 0.238781 9.95625 0.533333 9.95625Z" fill={fillColor} />
      </g>
      <defs>
        <clipPath id="clip0_615_11164">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconUpload;

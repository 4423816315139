import React from 'react';
import './text.scss';

const SpanGray = (Props) =>

    <span className={`SpanGray ${Props.color ? 'gray' : ''}`}>
        {Props.MostrarValor}
    </span>

export default SpanGray;

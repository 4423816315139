import { API_BASE_URL, API_PARAMETERS_CONTEXT } from "../utils/constans";
import clienteAxios from "../api/axios";
import UserServiceConstants from "../constants/user-service-constants";

const constants = UserServiceConstants;

const getUserById = async (userId) => {
  let user;
  let url = `${API_BASE_URL}${API_PARAMETERS_CONTEXT}${constants.API_ENDPOINTS.USERS}${userId}`
  await clienteAxios(url, null, null, constants.PETITIONS.GET)
    .then((response) => {
      user = response.data.datas;
    })
  return user;
};

const updateUser = async (data) => {
  return await clienteAxios(
    `${API_BASE_URL}${API_PARAMETERS_CONTEXT}${constants.API_ENDPOINTS.UPDATE_USER}`,
    data,
    null,
    constants.PETITIONS.POST
  );
};

const getUsers = async (data) => {
  let result = [];
  let url = `${API_BASE_URL}${API_PARAMETERS_CONTEXT}${constants.API_ENDPOINTS.ALL_USERS}`;
  try {
    const respuesta = await clienteAxios(url, data, null, constants.PETITIONS.POST);
    result = respuesta.data.datas.data;
    savePaginationTokenUsers(respuesta.data.datas.paginationToken);
  } catch (error) {
    return result;
  }
  return result;
};

const savePaginationTokenUsers = (paginationToken) => {
  localStorage.setItem(
    constants.PAGINATION_TOKEN,
    paginationToken
  );
}

const getPaginationTokenUsers = () => {
  return localStorage.getItem(constants.PAGINATION_TOKEN);
}

const removePaginationTokenUsers = () => {
  localStorage.removeItem(constants.PAGINATION_TOKEN);
}

export const getSubsidiaryByUser = async (userId) => {
  let filiales;
  const url = `${API_BASE_URL}${API_PARAMETERS_CONTEXT}${constants.API_ENDPOINTS.FILIALES_BY_USER}${userId}`
  await clienteAxios(url, null, null, constants.PETITIONS.GET)
    .then((response) => {
      filiales = response.data;
    })
  return filiales;
}

export { getUsers, updateUser, getUserById, getPaginationTokenUsers, removePaginationTokenUsers };

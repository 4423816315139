import { API_BASE_URL , API_PARAMETERS_CONTEXT  } from "../utils/constans";
import clienteAxios from "../api/axios";

const getRoles= async () => {
  let result = [];
  await clienteAxios(`${API_BASE_URL}${API_PARAMETERS_CONTEXT}roles`, null, null, "GET")
      .then(respuesta => {
          result = respuesta.data.datas;
      }).catch(
          err => console.log(err)
      )
  return result;
}

export { getRoles };
const ObservacionesConstants = {
  GLOBAL: "global",

  TRUE: true,
  FALSE: false,

  ACTION_ADD_OBS: "ADD OBSERVACIONES",

};

export default ObservacionesConstants
import {API_BASE_URL, API_PARAMETERS_CONTEXT} from "../utils/constans";
import clienteAxios from "../api/axios";
let negocio = [];

const BusinessService = async () => {
    await clienteAxios(`${API_BASE_URL}${API_PARAMETERS_CONTEXT}business-list`, null, null, "POST")
        .then(respuesta => {
            negocio = respuesta.data.data.slice();
        }).catch(
            err => console.log(err)
        )
    return negocio;
}

const BusinessFilialService = async (data) =>{
    await clienteAxios(`${API_BASE_URL}${API_PARAMETERS_CONTEXT}business-filial-list`, data, null, "POST")
    .then((response) => {
        negocio = response.data;
    })
    .catch((err) => {
        console.log(err);
    });
    return negocio;
}


export {
    BusinessService,
    BusinessFilialService
};

import React from 'react';
import "./pillButton.scss";

const PillButton = ({title, onClick, itemId, disabled}) => {
    return (
        <div className="main__pill__container">
            <button className="main__pill__button" onClick={(e) => {e.preventDefault()}} disabled={disabled ? disabled : false}>
                {title}
                {(onClick && !disabled) ? <a className="boxclose" onClick={() => {onClick(itemId)}}></a> : null}    
            </button>
        </div>
    )
}

export default PillButton;

import React, {useState} from "react";
import Loader from "../../atoms/loader/loader";
import BrandForm from "../../molecules/brand-form/brandForm";
import "../../templates/informacion/generalInformation.scss";
import "./createBrandForm.scss"

const CreateBrandForm = () => {
    const [isLoading, setIsLoading] = useState(false);

    return( 
        <div > 
            <Loader isLoading={isLoading}></Loader>
            <form className="brand__form">
                <BrandForm />
            </form> 
        </div>
    );
}

export default CreateBrandForm;
import {API_BASE_URL, API_CLIENTS_CONTEXT} from "../utils/constans";
import clienteAxios from "../api/axios";
let client;

const getClientByID = async (data) => {
  await clienteAxios(`${API_BASE_URL}${API_CLIENTS_CONTEXT}find-by-id`, data, null, "POST")
    .then((response) => {
      client = response.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
  return client;
};

const getClientByName = async (data) => {
  await clienteAxios(`${API_BASE_URL}${API_CLIENTS_CONTEXT}find-by-rs`, data, null, "POST")
    .then((response) => {
      client = response.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
  return client;
};

const getClientByNit = async (data) => {
  await clienteAxios(`${API_BASE_URL}${API_CLIENTS_CONTEXT}find-by-nd`, data, null, "POST")
    .then((response) => {
      client = response.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
  return client;
};

const getClientByNitInit = async (data) => {
  await clienteAxios(`${API_BASE_URL}${API_CLIENTS_CONTEXT}find-by-nitStart`, data, null, "POST")
    .then((response) => {
      client = response.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
  return client;
};

const createClient = async (data) => {
  return await clienteAxios(`${API_BASE_URL}${API_CLIENTS_CONTEXT}create`, data, null, "POST");
};

const updateClient = async (data) => {
  data.files = data.files.filter(file=> !!file.file);
  return await clienteAxios(`${API_BASE_URL}${API_CLIENTS_CONTEXT}update`, data, null, "POST");
};


const deleteFile = async (data) => {
  return await clienteAxios(
    `${API_BASE_URL}${API_CLIENTS_CONTEXT}delete-client-file`,
    data,
    null,
    "POST"
  );
};

const listClientsRequest = async (data) => {
  let result = []; 
  let url = `${API_BASE_URL}${API_CLIENTS_CONTEXT}listAll`
  await clienteAxios(url, data, null, "POST")
    .then(respuesta => {
      result = respuesta.data.data;
    }).catch(
      err => console.log(err)
    );
    return result;
}

const getClientsBrands = async (clientId) => {
  let result = [];
  let url = `${API_BASE_URL}${API_CLIENTS_CONTEXT}${clientId}/brands`
  await clienteAxios(url, null, null, 'GET')
    .then(respuesta => {
      result = respuesta.data.data;
    }).catch(
      err => console.log(err)
    );
    return result;
}

export {
  getClientByID,
  createClient,
  getClientByName,
  getClientByNit,
  getClientByNitInit,
  updateClient,
  deleteFile,
  listClientsRequest,
  getClientsBrands
};

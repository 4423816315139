import { Fragment, useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Auth } from "@aws-amplify/auth";

import { AuthService, doLogin } from "./services/authService";

import { AppContextUser } from "./provider/userInSession";
import ClientProvider from "./provider/cliente";
import FacturaProvider from "./provider/factura";
import PolicyProvider from "./provider/policy";
import Login from "./components/pages/login/login";
import CrearFactura from "./components/pages/create-facture/crearFactura";
import InvoiceRequest from "./components/pages/invoice-request/InvoiceRequest";
import crearCliente from "./components/pages/create-client/crearCliente";
import Brands from "./components/pages/brands/brands";
import CreateBrand from "./components/pages/create-brand/createBrand";
import InvoiceState from "./components/pages/invoice-state/InvoiceState";
import ErrorPage from "./components/pages/error/ErrorPage";
import { setErrorHandler } from "./api/axios";
import { createBrowserHistory } from "history";
import PolicyRequest from "./components/pages/policy-request/policyRequest";
import PolicyPendingTable from "./components/pages/policy-pending/policyPendingTable";
import PolicyState from "./components/pages/policy-state/policyState";
import BrandProvider from "./provider/brand";
import Clients from "./components/pages/clients/clients";
import Home from "./components/pages/home/home";
import Cities from "./components/pages/cities/cities";
import States from "./components/pages/states/states";
import Countries from "./components/pages/countries/countries";
import Users from "./components/pages/users/users";
import UserProvider from "./provider/user";
import EditUser from "./components/pages/edit-user/editUser";
import { getSubsidiaryByUser } from "./services/userService";

// Constants
import {
  OFFLINE,
  PATH_ERROR,
} from './utils/constans';

const App = (Props) => {
  const { dispatch, dataUser } = useContext(AppContextUser);
  const [redirectLogin, setRedirectLogin] = useState();
  const [userHasRol, setUserHasRol] = useState();
  const browserHistory = createBrowserHistory();
  const [installable, setInstallable] = useState(false);

  setErrorHandler((e) => {
    if (e !== OFFLINE && !window.location.href.endsWith(PATH_ERROR)) {
      pushBrowserHistory(e);
    }
  });

  const pushBrowserHistory = (event) => {
    browserHistory.push(PATH_ERROR);
    window.location.reload();
  };

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      window.deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      setInstallable(true);
    });

    window.addEventListener('appinstalled', () => {
      // Log install to analytics
      console.log('INSTALL: Success');
    });
  }, []);

  useEffect(() => {
    Auth.currentSession()
      .then(async (res) => {
        let dataIdToken = res.getIdToken().decodePayload();
        const { data, isRedirect } = await AuthService(dataIdToken);
        const filiales = await getSubsidiaryByUser(`Google_${data.userId}`);
        if (isRedirect) {
          setUserHasRol(false);
          setTimeout(() => {
            Auth.signOut();
          }, 2200);
        } else {
          setUserHasRol(true);
          await doLogin(); //Envía la información del usuario
          dispatch({
            type: "VALIDATE_TOKEN",
            payload: {
              ...data,
              filiales
            },
          });
        }
        setRedirectLogin(false);
      })
      .catch((err) => {
        setRedirectLogin(true);
      });
  }, []);

  const renderBrandAdmin = (
    <Fragment>
      <Route exact path="/marcas/crear">
        <BrandProvider>
          <CreateBrand />
        </BrandProvider>
      </Route>
      <Route exact path="/marcas" component={Brands} />
      <Route exact path="/marcas/editar/:id">
        <BrandProvider>
          <CreateBrand />
        </BrandProvider>
      </Route>
      <Route exact path="/usuarios" component={Users} />
      <Route exact path="/usuario/:userId">
        <UserProvider>
          <EditUser />
        </UserProvider>
      </Route>
      <Route exact path="/ciudades" component={Cities} />
      <Route exact path="/estados" component={States} />
      <Route exact path="/paises" component={Countries} />
    </Fragment>
  );

  return redirectLogin !== undefined ? (
    <Router history={browserHistory}>
      {redirectLogin && <Redirect to="/login" />}
      <Switch>
        <Route
          path="/login"
          render={(props) => <Login {...props} isAuthed={userHasRol} />}
        />

        <ClientProvider>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/factura">
            <FacturaProvider>
              <CrearFactura />
            </FacturaProvider>
          </Route>
          <Route exact path="/poliza">
            <PolicyProvider>
              <PolicyRequest />
            </PolicyProvider>
          </Route>
          <Route exact path="/poliza/:idPolicy">
            <PolicyProvider>
              <PolicyRequest />
            </PolicyProvider>
          </Route>
          <Route exact path="/factura/:idFacturas">
            <FacturaProvider>
              <CrearFactura />
            </FacturaProvider>
          </Route>

          {dataUser.rol === "Administrador" ? renderBrandAdmin : null}

          <Route exact path="/facturas-pendientes" component={InvoiceRequest} />
          <Route exact path="/historico-de-facturas" component={InvoiceState} />

          <BrandProvider>
            <Route exact path="/cliente" component={crearCliente} />
            <Route exact path="/clientes" component={Clients} />
            <Route exact path="/cliente/:id" component={crearCliente} />
          </BrandProvider>

          <Route
            exact
            path="/polizas-pendientes"
            component={PolicyPendingTable}
          />
          <Route exact path="/historico-de-polizas" component={PolicyState} />

          <Route path={PATH_ERROR} component={ErrorPage} />
        </ClientProvider>
      </Switch>
    </Router>
  ) : null;
};

export default App;

import React from 'react';
import './text.scss';

const Text = (props) => {

  let className = "input-text ";
  if (props.className) {
    className += props.className;
  }

  const getOnChangeHandler = (event) => {
   if (props.onChange) {
     return props.onChange(event);
   }
  };

  return(
  <div className={className}>
    <label className="input-text__body">
      <input
        disabled={props.disabled}
        id={props.id}
        required
        className={`
          input-text__input
          ${props.login ? 'input-text__input--login' : ''}
          ${props.errorMessage != null ? 'error-indicator' : ''}
        `}
        type={props.Type}
        placeholder=" "
        name={props.name || ""}
        value={props.value}
        onChange={getOnChangeHandler}
        onBlur={props.onBlur}
        maxLength={props.maxLength}
        defaultValue={props.defaultValue}
        onKeyDown={props.onKeyDown}
        minLength={props.minLength}
      />
      <span
        className={`
        input-text__placeholder
          ${props.login ? 'input-text__placeholder--login' : ''}
          ${props.trmLabel ? 'input-text__placeholder--label-trm' : ''}
        `}
      >
        {props.placeholder}
      </span>
      {props.errorMessage != null && <span className={"error-message"}>{props.errorMessage}</span>}
    </label>
  </div>
  )
}
export default Text;


import { useReducer, createContext } from 'react';
import invoiceReducer, { initialState } from '../reducers/invoiceReducer';

export const FacturaContext = createContext();

const FacturaProvider = ({children}) => {
    const [dataFactura, setDataFactura] = useReducer(invoiceReducer, initialState);
    return (
        <FacturaContext.Provider value={[dataFactura, setDataFactura]}>
            {children}
        </FacturaContext.Provider>
    );
}

export default FacturaProvider;
import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { deleteFile } from "../../../services/clientService";

import { ClientContext } from "../../../provider/cliente";
import { FILES_CATEGORIES, LIMIT_SIZE_FILE } from '../../../utils/constans';
import { informationAlert, confirmationAlert } from "../../molecules/alert/alert";
import InputFileBlock from "../../molecules/input-file-block/inputFileBlock";

import "./clientFiles.scss";
import { useTranslation } from "react-i18next";
import TranslationComponentConstants from "../../../constants/translation-language-constants";
import ClientFilesComponentConstants from "../../../constants/client-file-constants";

function ClientFields() {
  const translationConstants = TranslationComponentConstants;
  const componentConstants = ClientFilesComponentConstants;

  const [translate] = useTranslation(translationConstants.USE_TRANSLATION_GLOBAL_VALUE);
  const [fileArray, setFileArray] = useState({
    rut: [],
    camara_comercio: [],
    otros: [],
  });
  const { dataClient, setDataClient } = useContext(ClientContext);
  const params = useParams();
  useEffect(() => {
    if (params.id) {
      const camara_comercio = dataClient.files.filter((file) => {
        return file.categoria === FILES_CATEGORIES.CAMARA_COMERCIO;
      });
      const rut = dataClient.files.filter((file) => {
        return file.categoria === FILES_CATEGORIES.RUT;
      });
      const otros = dataClient.files.filter((file) => {
        return file.categoria === FILES_CATEGORIES.OTROS;
      });

      setFileArray({
        camara_comercio,
        rut,
        otros,
      });
    }
    if (!dataClient.files.length) {
      setFileArray({
        rut: [],
        camara_comercio: [],
        otros: []
      });
    }
  }, [dataClient]);

  const onFileUpload = (e, target) => {
    let archivos = [];
    let files = fileArray[target];
    let sw = true;

    for (const key in e.target.files) {
      if (
        isNaN(parseFloat(e.target.files[key])) &&
        !isFinite(e.target.files[key])
      ) {
        if (
          target !== FILES_CATEGORIES.OTROS &&
          (files.length >= componentConstants.ONE_VALUE || e.target.files.length > componentConstants.ONE_VALUE)
        ) {
          informationAlert(componentConstants.ERROR_LABEL, translate('alerts.oneFile'));
          sw = false;
          break;
        } else if (files.length > componentConstants.ZERO_VALUE) {
          for (let index = componentConstants.ZERO_VALUE; index < files.length; index++) {
            if (files[index].name === e.target.files[key].name) {
              sw = false;
            }
          }
        }
        if (sw && e.target.files[key].size) {

          let nombre = e.target.files[key].name;
          let extencion = nombre.split(componentConstants.DOT_SIGN)[nombre.split(componentConstants.DOT_SIGN).length - componentConstants.ONE_VALUE];
          let peso = parseInt(e.target.files[key].size);
          if (extencion !== componentConstants.JPG_EXTENSION && extencion !== componentConstants.JPG_EXTENSION_UPPERCASE && extencion !== componentConstants.JPGE_EXTENSION && extencion !== componentConstants.JPGE_EXTENSION_UPPERCASE && extencion !== componentConstants.PNG_EXTENSION && extencion !== componentConstants.PNG_EXTENSION_UPPERCASE && extencion !== componentConstants.PDF_EXTENSION && extencion !== componentConstants.PDF_EXTENSION_UPPERCASE) {
            informationAlert(nombre, translate('alerts.typeFileTitle'), translate('alerts.typeFileDescription'));
          } else if (peso >= LIMIT_SIZE_FILE) {
            informationAlert(nombre, translate('alerts.sizeFileTitle'), translate('alerts.sizeFileDescription'));
          } else {
            archivos.push(e.target.files[key]);
            toBase64(e.target.files[key], target);
          }
        }
      }
    }

    let arreglo2 = files?.concat(archivos);
    setFileArray({ ...fileArray, [target]: arreglo2 });
  };

  function removeFile(index, target) {
    let fileToRemove = fileArray[target].find((item, element) => element === index);
    confirmationAlert(
      componentConstants.WARNING_LABEL,
      translate('alerts.confirmDelete'),
      componentConstants.EMPTY_VALUE,
      translate('transversal.yes'),
      translate('transversal.no'),
      () => {
        if (fileToRemove.archivoId) {
          deleteFile(fileToRemove).then((res) => {
            if (res.data.status == componentConstants.STATUS_CODE_200) {
              informationAlert(componentConstants.SUCCESS_LABEL, translate('alerts.success'), res.data.developerMessage);
            } else {
              informationAlert(componentConstants.ERROR_LABEL, translate('alerts.alert'), res.data.userMessage);
            }
          });
        }
        
        dataClient.files = dataClient.files.filter(
          (item) => item?.nombreArchivo !== fileToRemove.nombreArchivo && item?.nombreArchivo !== fileToRemove.name
        );
        setDataClient({
          type: componentConstants.ADD_LABEL,
          payload: { ...dataClient }
        });
        let archivos = fileArray[target].filter((item, element) => element !== index);
        setFileArray({ ...fileArray, [target]: archivos });
      }
    );
  }

  const toBase64 = (file, category) => {
    let array = dataClient[componentConstants.FILES_LABEL];

    if (typeof file === componentConstants.OBJECT_LABEL) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const fileData = {
          categoria: category,
          nombreArchivo: file.name,
          file: reader.result.split(componentConstants.COMMA_SIGN)[componentConstants.ONE_VALUE],
        };
        delete fileData.name;
        array.push(fileData)
        setDataClient({
          type: componentConstants.ADD_FILES_LABEL,
          payload: { ...dataClient }
        });
      };
    }
  };

  const insertarArchivos = (e) => {
    let array = dataClient[e.target.name];
    let arreglo = array?.concat(e.target.value);
  };

  const download = (files) => {
    let archivo = files;
  
    if (!archivo) {
      console.error(componentConstants.NOT_FOUND_FILE);
      return;
    }
  
    const element = document.createElement(componentConstants.ANCHOR_ELEMENT);
  
    if (archivo.file && typeof archivo.file === componentConstants.TYPE_STRING) {
      const byteCharacters = atob(archivo.file);
      const byteNumbers = new Uint8Array(byteCharacters.length);
      for (let i = componentConstants.ZERO_VALUE; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const blob = new Blob([byteNumbers], { type: componentConstants.TYPE_PDF });
      element.href = URL.createObjectURL(blob);
      element.download = archivo.nombreArchivo;
      
    } else if (archivo instanceof File) {
      element.href = URL.createObjectURL(archivo);
      element.download = archivo.name;
  
    } else if (archivo.urlArchivo) {
      element.href = archivo.urlArchivo;
      element.download = archivo.nombreArchivo;
  
    } else {
      console.error(componentConstants.NOT_VALID_FILE);
      return;
    }
  
    document.body.appendChild(element);
    element.click();
  
    URL.revokeObjectURL(element.href);
  };
  

  return (
    <div className="client-files">
      <div className="container">
        <div>
          <h3 className="main__title">{translate('customers.createCustomer.attchedFiles.title')}</h3>
        </div>
        <div className="files">
          <div className="first__file">
            <h3 className="title__h3">{translate('customers.createCustomer.attchedFiles.commercialDocument')}</h3>
            <InputFileBlock
              onBlur={insertarArchivos}
              onChange={(e) => onFileUpload(e, FILES_CATEGORIES.CAMARA_COMERCIO)}
              required="required"
              data={fileArray.camara_comercio}
              removeFile={(index) => removeFile(index, FILES_CATEGORIES.CAMARA_COMERCIO)}
              download={download}
              name="filesCamara_Comercio"
              status={"upload"}
              limitFiles={componentConstants.LIMIT_1_FILE}
              title={translate('components.inputFile.limitFile')}
            />
          </div>
          <div className="second__file">
            <h3 className="title__h3">{translate('customers.createCustomer.attchedFiles.taxIdentificationDocument')}</h3>
            <InputFileBlock
              onBlur={insertarArchivos}
              onChange={(e) => onFileUpload(e, FILES_CATEGORIES.RUT)}
              required="required"
              data={fileArray.rut}
              removeFile={(index) => removeFile(index, FILES_CATEGORIES.RUT)}
              download={download}
              name="filesRut"
              status={"upload"}
              limitFiles={componentConstants.LIMIT_1_FILE}
              title={translate('components.inputFile.limitFile')}
            />
          </div>
          <div className="third__file">
            <h3 className="title__h3">{translate('customers.createCustomer.attchedFiles.others')}</h3>
            <InputFileBlock
              onBlur={insertarArchivos}
              onChange={(e) => onFileUpload(e, FILES_CATEGORIES.OTROS)}
              required="required"
              data={fileArray.otros}
              removeFile={(index) => removeFile(index, FILES_CATEGORIES.OTROS)}
              download={download}
              name="filesOtros"
              status={"upload"}
              limitFiles={componentConstants.LIMIT_5_FILES}
              title={translate('components.inputFile.limitFiles', { limit: componentConstants.LIMIT_5_FILES })}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientFields;

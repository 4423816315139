const ApproveClientConstants = {
    GLOBAL: "global",

    ADD_TYPE: "AGREGAR",
    CHECKBOX_TYPE: "checkbox",

    MIN_LENGTH: 2,
    SELECTED_ACTIVITY: 1,
    ONE_POSITION_LIST: 1,
    FIRST_POSITION: 0,
    REFRESH_RATE: 500,

    NUMBER_TYPE: "number",

    FIELDS: {
        ACT_NAME: "actividadEconomicaId",
        LOCATION_NAME: "sedeId",
        IVA_NAME: "iva",
        RETE_IVA_NAME: "reteIva",
        RETE_FUENTE_NAME: "reteFuente",
        RETE_ICA_NAME: "reteIca",
        RENT_NAME: "autoRenta",
        WHITHOLDING_NAME: "agenteRetenedor",
        ACTIVE_NAME: "activo",
    }
}

export default ApproveClientConstants;
import {useEffect, useState} from "react"
import Button from '../../atoms/buttons/button';
import TextAreaBlock from "../../molecules/text-area-block/textAreaBlock";
import { parseCurrencyToVal } from '../../../utils/utils';
import InputFileGeneric from '../../molecules/input-file-generic/inputFileGeneric';
import TextBlockTwo from "../../molecules/text-blocks/textBlockTwo";
import { FILES_CATEGORIES } from '../../../utils/constans';
import './invoiceCheck.scss'
import { useTranslation } from "react-i18next";
import { PdsButton } from "@pragma-dev-kit/pragma-ds-react";

const InvoiceCheck = (props) => {
  const [t] = useTranslation('global');
  const [buttons, setButtons] = useState([]);
  const text = `${t('invoices.createInvoice.reasonsChangeStatus')}.`;
  const text_strong = t('invoices.createInvoice.requiredClarification');
  const text_an = t('invoices.createInvoice.reasonsApproved');

  const listButtons = () => {
    return setButtons(props.buttons)
  }

  useEffect(() => {
    listButtons();
  }, [props.buttons]);

  return (
    <section className="check-invoice">
      <section className="check-invoice__inputs-container">
        <div className="check-invoice__title">
          <h3>{props.headerTitle ? props.headerTitle : t('invoices.createInvoice.changeStatus')}</h3>
        </div>
        {props.showComponent && <div className="approval-invoice ">
          <div className="primaryDate align_item_center">
            <div className="containerBlocks">
              <div className="containerTextTwoBlocks">
                <div className="secondary-text-block-two">
                    <TextBlockTwo
                      disabled={props.disabled ? "disabled" : ""}
                      onChange={props.handleDataChange}
                      placeholder={t('invoices.createInvoice.consecutive')}
                      value={props.consecutivo}
                      type="text"
                      name="consecutivo"
                      defaultValue="ME"
                    />
                </div>
                <div className="secondary--align_end">
                <TextBlockTwo
                  disabled={props.disabled ? "disabled" : ""}
                  onChange={(e) => parseCurrencyToVal(1, e, (event, value) => props.handleUptadeTRM(value))}
                  placeholder={t('invoices.createInvoice.trmPesos')}
                  value={props.trm}
                  type="text"
                  name="trm"
                />
              </div>
            </div>
          </div>
            <div className="approval-invoice__inputs--files" >
              <InputFileGeneric
                title={`${t('invoices.createInvoice.attachInvoice')}.`}
                handleFileChange={props.handleFileChange}
                category={FILES_CATEGORIES.INVOICE}
                data={props.files}
                manageFiles={true}
                multiple={false}
                message={props.disabled ? t('components.inputFile.approverDocuments') : t('invoices.createInvoice.attachInvoice')}
                disabled={props.disabled}
                areFilesVisible={props.files.length >= 1}
                limitFiles={props.limitFiles}
              />
            </div>
          </div>
        </div>
        }
        {
          props.showTextArea ?
            <div className="inputs-text">
              <TextAreaBlock maxLength={500}
                             text={text}
                             textStrong={text_an}
                             value={props.observacionesEstado}
                             disabled="Disabled">
              </TextAreaBlock>
            </div> :
            <div className="inputs-text">
              <TextAreaBlock maxLength={500}
                             text={text}
                             textStrong={text_strong}
                             onChange={props.handleObservacionesChange}
                             placeholder={t('invoices.createInvoice.addObservations')}>
              </TextAreaBlock>
            </div>
        }
        </section>
        <div className="check-invoice__button-container">
          {buttons.map((button, index) => {
            return (
              <div className="check-invoice__button-container-button">
                <PdsButton 
                key={index} 
                variant={button.variant}
                className={button.primary} 
                name={button.name} 
                onClick={button.onClick} 
                disabled={button.disabled} 
                >
                  {button.name}
                </PdsButton>
              </div>
            )
          })}
        </div>
    </section>
  )
};

export default InvoiceCheck;

import UpArrow from '../../../images/ico-arrow-up.svg'
import DownArrow from '../../../images/ico-arrow-down.svg'
import "../../molecules/table/Table.scss"

const ThTable = ({ headingColums, orderColumnName, orderColumnDirection, orderBy }) => {

    const getStyles = (column) => {
        const styles = {};
        if (column.width) {
            styles.width = `${column.width}%`;
            styles.maxWidth = `${column.width}%`;
        }
        return styles;
    }

    const getOrderIcon = (column) => {
        let orderIcon;
        if (orderColumnName === column.key && orderColumnDirection) { orderIcon = UpArrow; }
        if (orderColumnName === column.key && !orderColumnDirection) { orderIcon = DownArrow; }
        return orderIcon;
    }

    const isVisible = (column) => {
        return column.visible == null || column.visible === true;
    }

    return (
        <tr>
            {headingColums.map((column, index) => {
                if (isVisible(column)) {
                    let orderIcon = getOrderIcon(column);
                    return (
                        <th
                            style={getStyles(column)}
                            className={`${orderColumnName === column.key ? `orderingColumn` : ``}`}
                            key={index}
                            onClick={() => orderBy? orderBy(column.key): null}>
                            <div className={`tableHeaderOrder`}>
                                {orderIcon &&
                                    <div className={"tableHeaderOrderIcon"}><img src={orderIcon} /></div>}
                                <span>{column.val}</span>
                            </div>
                        </th>
                    );
                } else {
                    return (<></>);
                }
            })
            }
        </tr>);

}

export default ThTable;

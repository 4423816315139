import React, {Fragment, useEffect, useState} from 'react';
import Wrapper from '../../templates/wrapper/Wrapper';
import InvoiceStateTable from '../../organism/invoice-state-table/InvoiceStateTable';
import Aside from '../../templates/aside/aside';
import { getFiliales } from "../../../services/parametersService";

import './InvoiceState.scss';
import HeaderInvoiceState from "../../organism/header-invoice-state/HeaderInvoiceState";

const InvoiceState = (Props) => {

    const [blur, setBlur] = useState(false);
    const [allFiliales, setAllFiliales] = useState(null);

     useEffect(() => { getAllFiliales() }, []);

     const getAllFiliales = async () => {
        await getFiliales()
          .then((filialesResponse) => {
            setAllFiliales(filialesResponse);
          })
          .catch((error) => {
            setAllFiliales([]);
          });
      }

    return (
        <Fragment>
            <Wrapper aside={<Aside blur={blur} setBlur={setBlur}/>} blur={blur}>
                <HeaderInvoiceState/>
                {allFiliales && <InvoiceStateTable filiales={allFiliales}/>}
            </Wrapper>
        </Fragment>
    );
}

export default InvoiceState; 

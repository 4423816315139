const policyTableConstants = {
    EMPTY_STRING_INITIALIZER: '',
    EMPTY_STRING_COMPARATOR: '',
    AMPERSAND: '&',
    TRUE: 'true',
    EQUALS: '=',

    GLOBAL: 'global',

    ONE_VALUE: 1,
    ZERO_VALUE: 0,
    ZERO_STRING_VALUE: "0",
    ONE_VALUE_INITIALIZER: 1,
    REQUESTS_INITIAL_STATE_TWENTY: 20,

    SOLICITANTE_ROL: 'Solicitante',
    SUPERVISOR_ROL: 'Supervisor',
    ADMIN_ROL: 'Administrador',
    APPROVER_ROL: 'Aprobador',

    POL_COLUMN: 'polSolicitud',
    USER_EMAIL: 'usuarioEmail',
    DATE_REQUEST: 'fechaSolicitud',
    LIMIT_DATE: 'fechaLimiteEntrega',
    DOC_ID: 'numeroDocumento',
    CLIENT_NAME: 'nombreCliente',
    TOTAL_VALUE: 'valorTotal',
    STATUS_VALUE: 'estado',
    CONSECUTIVE_VALUE: 'consecutivo',
    DATE_FAC: 'fechaFacturacion',
    FILIAL_NAME: 'filialName',
    DATE_CREATION: "fechaCreacion",

    COLUMN_WIDTH_6: 6,
    COLUMN_WIDTH_8: 8,
    COLUMN_WIDTH_9: 9,
    COLUMN_WIDTH_10: 10,
    COLUMN_WIDTH_11: 11,
    COLUMN_WIDTH_12: 12,
    COLUMN_WIDTH_14: 14,

    TEXT_TYPE: 'text',
    CAPITALIZE: 'capitalize',
    ALIGN_RIGHT: 'right',
    CURRENCY: 'currency',
    CURRENCY_TYPE: 'monedaId',
    RENDER: {
        PERCENTAGE: '$1%-$2%',
        VERIFICATION: 'digitoVerificacion',
    },

    TEN_ROWS: 10,
    TWENTY_ROWS: 20,
    FIFTY_ROWS: 50,
    FIFTY_ROWS_LABEL: '50',
    TWENTY_ROWS_LABEL: '20',
    TEN_ROWS_LABEL: '10',

    STATUS_CODE_200: 200,
    ERROR_LABEL: 'error',
    SUCCESS_LABEL: 'success',

    PENDING_STATUS: "PENDIENTE",
    REVIEWED_STATUS: "REVISADA",
    SAVED_STATUS: "GUARDADA",
    EXPORTED_STATUS: "EXPORTADA",
    RETURNED_STATUS: "DEVUELTA",
    NULL_STATUS: 'ANULADA',
    APPROVED_STATUS: 'APROBADA',
    CANCELLED_STATUS: 'CANCELADA',

    FILTER_PARAM: '?filtro=',
    PAGE_PARAM: '&page=',
    REQUEST_PARAM: '&requests=',
    ORDER_BY_PARAM: '}&orderby=',
    ORDER_PARAM: '&order=',
    POLICY_PATH: '/poliza/',
    HISTORY_PATH: '/historico-de-polizas',

    FIRST_INDEX_POSITION: 0,
    SECOND_INDEX_POSITION: 1,
    THIRD_INDEX_POSITION: 2,
    FOURTH_INDEX_POSITION: 3,
    FIFTH_INDEX_POSITION: 4,
    SIXTH_INDEX_POSITION: 5,
};

export default policyTableConstants;
import { useContext } from 'react';
import { AppContextUser } from '../../../provider/userInSession';
import QuickAccessLink from '../../molecules/quick-access-link/quickAccessLink';
import './homeTemplate.scss';

function HomeTemplate({ title, subtTitle, links, message, email, ...otherHomeTemplateProps }) {
    const { dataUser } = useContext(AppContextUser);
    return (
        <div className="home">
            <div className="home-menu">
                <div className='home-menu__text'>
                    <h3>{title}</h3>
                    <p>{subtTitle}</p>
                </div>
                <div className="access-links">
                    {links.map((link, index) => {
                        if (link.users.includes(dataUser.rol)) {
                            return <QuickAccessLink key={index} link={link} />
                        }
                        return null;
                    })}
                </div>
            </div>
            <div className='home-message'>
                <div>{message}{email}</div>
            </div>
        </div>
    )
}

export default HomeTemplate;
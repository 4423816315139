import PropTypes from 'prop-types';
import TrTable from '../../atoms/tr-table/tr-table';
import ThTable from '../../atoms/th-table/th-table';
import './Table.scss';
import TableConstants from '../../../constants/table-constants';
import { useTranslation } from 'react-i18next';

const constants = TableConstants;

const Table = ({
    orderBy,
    tableData,
    headingColums,
    checkeableRows,
    orderColumnName,
    breakOn = constants.MEDIUM,
    onItemClickListener,
    orderColumnDirection,
}) => {

    const [translate] = useTranslation(constants.GLOBAL)

    const getClassTable = () => {
        let classTable = constants.BASE_CLASS;
        switch (breakOn) {
            case constants.SMALL:
                return classTable += constants.SMALL_CLASS;
            case constants.MEDIUM:
                return classTable += constants.MEDIUM_CLASS;
            case constants.LARGE:
                return classTable += constants.LARGE_CLASS;
            default:
                return classTable;
        }
    }

    const handleOnClickRow = (event, row) => {
        if (event.target.tagName !== constants.INPUT && !event.target.type) {
            onItemClickListener(row);
            return;
        }
        alert(constants.ALERT_CHECK);
    }

    return (
        <section className="table-container">
            <table className={getClassTable()}>
                <thead>
                    <ThTable
                        orderBy={orderBy}
                        headingColums={headingColums}
                        orderColumnName={orderColumnName}
                        orderColumnDirection={orderColumnDirection}
                    />
                </thead>
                <tbody className="table__body">
                    {tableData.map((row, index) => {
                        row[constants.CHECKED_STATUS] = false;
                        return (
                            <TrTable
                                row={row}
                                key={index}
                                indexRow={index}
                                breakOn={breakOn}
                                headingColums={headingColums}
                                checkeableRows={checkeableRows}
                                handleOnClickRow={handleOnClickRow}
                            />
                        )
                    })}
                    {tableData.length === 0 && <tr className={"m-emptyTable"}>
                        <td colSpan={headingColums.length}>{translate('components.table.noEntries')}</td>
                    </tr>}
                </tbody>
            </table>
        </section>
    )
}

Table.propTypes = {
    checkeableRows: PropTypes.bool,
    orderColumnName: PropTypes.string,
    onItemClickListener: PropTypes.func,
    orderColumnDirection: PropTypes.bool,
    breakOn: PropTypes.oneOf([constants.SMALL, constants.MEDIUM, constants.LARGE]),
    tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
    headingColums: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Table;
import { useReducer, createContext } from "react";

export const ClientContext = createContext();

const initialStateClient = {
  razonSocialRut: "",
  numeroDocumento: "",
  digitoVerificacion: "",
  direccionRut: "",
  paisId: "",
  departamentoId: "",
  ciudadId: "",
  codigoPostal: "",
  emailFacturacion: "",
  telefonoRut: "",
  celularRut: "",
  usuarioId: "",
  plazoPago: "",
  clasificacionTributariaId: "",
  files: [],
  actividadEconomicaId: "", // YA ESTA
  reteIva: "", // YA ESTA
  reteFuente: "",
  reteIca: "",
  iva: "", // YA ESTA
  autoRenta: false,
  agenteRetenedor: false, //  YA ESTA
  activo: false, //  YA ESTA,
  fechaCreacion: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'RESET':
      initialStateClient.files = [];
      return initialStateClient;
    default:
      return action.payload;
  }
}

const ClientProvider = ({ children }) => {
  const [dataClient, setDataClient] = useReducer(reducer, initialStateClient);
  return (
    <ClientContext.Provider value={{ dataClient, setDataClient }}>
      {children}
    </ClientContext.Provider>
  );
};
export default ClientProvider;

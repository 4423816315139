import { useState, useContext, useEffect } from 'react';
import { deleteFile } from '../../../services/facturaService';
import Text from '../../atoms/text/text';
import { useParams } from "react-router-dom";
import InputFileBlock from '../../molecules/input-file-block/inputFileBlock';
import RadioButtons from '../../molecules/radio-buttons/radioButtons';
import { informationAlert, confirmationAlert } from "../../molecules/alert/alert";
import { FILES_CATEGORIES, LIMIT_SIZE_FILE } from '../../../utils/constans';
import { isExtentionsValid, isInvoiceRequestDisable } from '../../../utils/utils';
import './paymentConditions.scss';
import { useTranslation } from 'react-i18next';
import PaymentConditionsConstants from '../../../constants/payment-conditions-constants';
import { PdsInput } from '@pragma-dev-kit/pragma-ds-react';

const PaymentConditions = (props) => {
    const constants = PaymentConditionsConstants;

    const params = useParams();
    const [translate] = useTranslation(constants.GLOBAL);
    let daysOfPaymentTerm = [
        { label: translate('transversal.daysAmount', { amount: constants.NUMBER_THIRTY_DAYS }), value: constants.NUMBER_THIRTY_DAYS_TEXT },
        { label: translate('transversal.daysAmount', { amount: constants.NUMBER_SIXTY_DAYS }), value: constants.NUMBER_SIXTY_DAYS_TEXT },
        { label: translate('transversal.cash'), value: constants.NUMBER_ZERO_STRING }
    ]

    let [fileArray, setFileArray] = useState([]);
    const [estado, setEstado] = useState(constants.DISABLED);
    const [otherRadioButtonState, setOtherRadioButtonState] = useState(false)
    const [contextData, setContextData] = useContext(props.context);

    const toBase64 = (file) => {
        return new Promise((resolve) => {
            if (typeof file === constants.OBJECT) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    const fileData = {
                        categoria: props.policy ? FILES_CATEGORIES.POLICY_REQUEST : FILES_CATEGORIES.INVOICE_REQUEST,
                        nombreArchivo: file.name,
                        file: reader.result.split(constants.COMMA_SEPARATOR)[constants.NUMBER_ONE],
                    };
                    resolve(fileData);
                };
            }
        });
    };
    
    const onFileUpload = async (event) => {
        let archivos = [];
        for (const key in event.target.files) {
            if (isNaN(parseFloat(event.target.files[key])) && !isFinite(event.target.files[key])) {
                let sw = true;
                if (fileArray.length > constants.NUMBER_ZERO) {
                    for (let i = constants.NUMBER_ZERO; i < fileArray.length; i++) {
                        if (fileArray[i].name === event.target.files[key].name) {
                            sw = false;
                        }
                    }
                }
                if (sw && event.target.files[key].size) {
                    let nombre = event.target.files[key].name;
                    let extencion = nombre.split(constants.POINT_SEPARATOR)[nombre.split(constants.POINT_SEPARATOR).length - constants.NUMBER_ONE];
                    let peso = parseInt(event.target.files[key].size);
                    if (!isExtentionsValid([constants.EXTENSIONS.JPG, constants.EXTENSIONS.JPEG, constants.EXTENSIONS.PNG, constants.EXTENSIONS.PDF], extencion)) {
                        informationAlert(nombre, translate('alerts.fileTypeNotAllowedTitle'), translate('alerts.fileTypeNotAllowedDescription'));
                    } else if (peso >= LIMIT_SIZE_FILE) {
                        informationAlert(nombre, translate('alerts.invalidFileWeightTitle'), translate('alerts.invalidFileWeightDescription'));
                    } else {
                        let fileData = await toBase64(event.target.files[key]);
                        archivos.push(fileData);
                        setFileArray(prev => [...prev, event.target.files[key]]);
                        setContextData({
                            type: constants.TYPE_ADD_FILE,
                            payload: { ...contextData, files: [...contextData.files, fileData] }
                        });
                    }
                }
            }
        } 
    }

    function removeFile(index) {
        let fileToRemove = fileArray.find((item, i) => i === index);
        confirmationAlert(
            constants.ALERT_WARNING,
            translate('alerts.confirmDelete'),
            constants.EMPTY,
            translate('transversal.yes'),
            translate('transversal.no'),
            () => {
                if (fileToRemove.archivoId) {
                    deleteFile(fileToRemove).then((res) => {
                        if (res.data.status == constants.STATUS_200) {
                            informationAlert(constants.ALERT_SUCCES, translate('alerts.success'), res.data.developerMessage);
                        } else {
                            informationAlert(constants.ALERT_ERROR, translate('alerts.alert'), res.data.userMessage);
                        }
                    });
                }
                let archivos = fileArray.filter((item, i) => i !== index);
                setFileArray(archivos);
                const nameFileToRemove = fileToRemove.name ? fileToRemove.name : fileToRemove.nombreArchivo
                contextData.files = contextData.files.filter(
                    (item) => item?.nombreArchivo !== nameFileToRemove
                );
                setContextData({
                    type: constants.TYPE_ADD,
                    payload: { ...contextData }
                });
            }
        );
    }

    const actualizarDiasPago = event => {
        if (!props.policy) {
            if (event.target.value === constants.NUMBER_MINUS_ONE_STRING) {
                setEstado(constants.EMPTY);
                setOtherRadioButtonState(constants.TRUE)
                contextData.plazoPago = constants.EMPTY;
            } else {
                contextData.plazoPago = parseInt(event.target.value);
                setEstado(constants.DISABLED);
                setOtherRadioButtonState(constants.FALSE)
            }
            setContextData({
                type: constants.TYPE_ADD_FILE,
                payload: { ...contextData }
            });
        } else {
            informationAlert(constants.ALERT_ERROR, translate('alerts.alert'), translate('alerts.paymentTermRequired'));
        }
    };

    const actualizarDiasPagoText = event => {
        if (!props.policy) {
            contextData.plazoPago = parseInt(event.target.value);
            setContextData({
                type: constants.TYPE_ADD_FILE,
                payload: { ...contextData }
            });
        } else {
            informationAlert(constants.ALERT_ERROR, translate('alerts.alert'), translate('alerts.paymentTermRequired'));
        }
    }

    const download = (files) => {
        const archivo = files;
        const element = document.createElement(constants.ELEMENT_A);
        element.href = !files.urlArchivo
            ? URL.createObjectURL(archivo)
            : files.urlArchivo
        element.download = archivo.nombreArchivo;
        document.body.appendChild(element);
        element.click();
    }

    useEffect(() => {
        if (params.idFacturas) {
            setFileArray(contextData?.files.filter(file => file.categoria === FILES_CATEGORIES.INVOICE_REQUEST))
            if (contextData.plazoPago === constants.NUMBER_ZERO || contextData.plazoPago === constants.NUMBER_SIXTY_DAYS || contextData.plazoPago === constants.NUMBER_THIRTY_DAYS || contextData.plazoPago === constants.NUMBER_MINUS_ONE) {
                setEstado(constants.DISABLED);
                setOtherRadioButtonState(constants.FALSE)
            } else {
                setEstado(constants.EMPTY);
                setOtherRadioButtonState(constants.TRUE)
            }
        }

        if (params.idPolicy) {
            setFileArray(contextData?.files.filter(file => file.categoria === FILES_CATEGORIES.POLICY_REQUEST));
        }

        if (!contextData.files.length && contextData.reset) {
            setFileArray([]);
        }
    }, [contextData]);

    const getTitleInputFileBlock = () => {
        let message = translate('components.inputFile.limitFile');
        if (props.limitFiles > constants.NUMBER_ONE) message = translate('components.inputFile.limitFiles', { limit: props.limitFiles });
        return  message;
    }

    return (
        <div className="ContentPaymentConditions">
            <div className="HeaderDocumentsClient HeaderDataClient">
                {props.show ? <h3>{translate('invoices.createInvoice.attachments')}</h3> : <h3>{translate('invoices.createInvoice.attachDocuments')}</h3>}
            </div>
            <div className="filesContainer">
                <div className={props.policy ? `DocumentPolicy ${!isInvoiceRequestDisable(contextData) ? 'create' : ''}` : !isInvoiceRequestDisable(contextData) ? "Documents" : "DocumentsList"}>
                    <InputFileBlock
                        onChange={onFileUpload}
                        required={props.policy && contextData.monedaId === constants.NUMBER_TWO ? "required" : ""}
                        data={fileArray}
                        removeFile={(index) => removeFile(index)}
                        download={download}
                        title={getTitleInputFileBlock()}
                        status={isInvoiceRequestDisable(contextData) ? 'view' : 'upload'}
                        limitFiles={props.limitFiles}
                    />
                </div>
            </div> 
            {!(isInvoiceRequestDisable(contextData) && props.policy) &&
                <div className={props.policy ? "PaymentConditionsPolicy" : "PaymentConditions"}>
                    {!props.policy && !isInvoiceRequestDisable(contextData) &&       
                        <div className="OptionsButton">
                            <hr/>
                            <b>{translate('invoices.createInvoice.paymentConditions')}</b>
                            <div className="OptionsButton__checkboxs">
                                <RadioButtons onChange={actualizarDiasPago} 
                                    name='Payment' 
                                    data={daysOfPaymentTerm} 
                                    orientation="horizontal"
                                    selected={(contextData.facSolicitudId || props.isDuplicate) ? contextData.plazoPago.toString() : ""} 
                                    disabled={isInvoiceRequestDisable(contextData)} />
                                <div className="OptionsButton__other">
                                    <p className="custom-checkbox">
                                        <label className="custom-radio-checkbox">
                                            <input
                                                required
                                                className="custom-radio-checkbox__input"
                                                onChange={actualizarDiasPago}
                                                type="radio"
                                                name="Payment"
                                                value="-1"
                                                selected=""
                                                checked={otherRadioButtonState}
                                                disabled={isInvoiceRequestDisable(contextData)}
                                            />
                                            <span
                                                className="custom-radio-checkbox__show custom-radio-checkbox__show--radio">
                                            </span>
                                            <span className="custom-radio-checkbox__text">{translate('transversal.other')}</span>
                                        </label>
                                    </p>
                                    <PdsInput
                                        className={"options-button__otro-dia " + estado}
                                        type='number'
                                        id="text-otro"
                                        maxLength="3"
                                        onInput={actualizarDiasPagoText}
                                        label={translate('invoices.createInvoice.numberDays')}
                                        placeholder={translate('invoices.createInvoice.daysPlaceholder')}
                                        value={contextData.plazoPago}
                                        disabled={isInvoiceRequestDisable(contextData)}
                                    />
                                </div>
                            </div>
                        </div>}
                        {!props.policy && isInvoiceRequestDisable(contextData) &&
                        <div className="displayConditions">
                            <b className="conditions">{translate('invoices.createInvoice.paymentConditions')}:  </b> <span className="selectedDays">{contextData.plazoPago} {translate('transversal.days')}</span> 
                            
                        </div>}
                    
                </div>}
        </div>
    )
}

export default PaymentConditions;
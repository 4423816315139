import {API_BASE_URL, API_PARAMETERS_CONTEXT} from "../utils/constans";
import clienteAxios from "../api/axios";
let tipoIngreso = [];

const OpportunitiesService = async () => {
    let result = [];
    await clienteAxios(`${API_BASE_URL}${API_PARAMETERS_CONTEXT}tipo-ingreso-list`, null, null, "POST")
        .then(respuesta => {
            result = respuesta.data.data.slice();
        }).catch(
            err => console.log(err)
        )
    return result;
}

const OpportunitiesFilialService = async (data) => {
    await clienteAxios(`${API_BASE_URL}${API_PARAMETERS_CONTEXT}tipo-ingreso-filail-list`, data, null, "POST")
    .then((response) => {
        tipoIngreso = response.data;
    })
    .catch((err) => {
        console.log(err);
    });
    return tipoIngreso;
}

export {
    OpportunitiesService,
    OpportunitiesFilialService
} 

import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import useLoader from "../../../utils/useLoader";
import {
  getUsers,
  getPaginationTokenUsers,
  removePaginationTokenUsers,
} from "../../../services/userService";
import { Fragment } from "react";
import Toolkit from "../../atoms/toolkit/Toolkit";
import InputSearchSet from "../../molecules/input-search-set/InputSearchSet";
import { informationAlert } from "../../molecules/alert/alert";
import "./usersTable.scss";
import TableInfinite from "../../organism/table-infinite/table-infinite";
import { useTranslation } from "react-i18next";
import TranslationComponentConstants from "../../../constants/translation-language-constants";
import UsersTableConstants from "../../../constants/users-table-constants";

function UsersTable() {
  const translationConstants = TranslationComponentConstants;
  const constants = UsersTableConstants;

  const [translate] = useTranslation(translationConstants.USE_TRANSLATION_GLOBAL_VALUE);
  const history = useHistory();
  const [loader, showLoader, hideLoader] = useLoader();
  const [dataUsers, setDataUsers] = useState([]);
  const [searchItem, setSearchItem] = useState(constants.EMPTY_STRING_INITIAL_STATE);
  const [requestsPerPage] = useState(constants.INITIAL_20_ROWS_STATE);

  const headingUsersColumns = [
    { key: constants.NAME_COLUMN, val: translate('administrator.users.name'), width: constants.COLUMN_WIDTH_3 },
    { key: constants.LASTNAME_COLUMN, val: translate('administrator.users.lastname'), width: constants.COLUMN_WIDTH_2 },
    { key: constants.EMAIL_COLUMN, val: translate('administrator.users.email'), width: constants.COLUMN_WIDTH_2 },
  ];

  const handleOnChangeSearch = (event) => {
    setDataUsers([]);
    setSearchItem(event.target.value);
  };

  const handleOnItemClickListener = (row) => {
    history.push(constants.USER_PATH + row.userId);
  };

  const reloadTable = () => {
    const filterData = {
      pageSize: requestsPerPage,
      paginationToken: getPaginationTokenUsers()
        ? getPaginationTokenUsers()
        : null,
      userEmail: searchItem,
    };
    showLoader();
    getUsers(filterData)
      .then((response) => {
        dataUsers.push(...response);
        setDataUsers(dataUsers);
        hideLoader();
      })
      .catch((error) => {
        hideLoader();
        setDataUsers([]);
        informationAlert(constants.INFORMATION_ERROR, translate('alerts.oops'), error);
      });
  };

  useEffect(() => {
    removePaginationTokenUsers();
    reloadTable();
  }, [requestsPerPage, searchItem]);

  return (
    <Fragment>
      <div className="users-toolkit__search">
        <Toolkit>
          <InputSearchSet
            type={constants.TYPE_TEXT}
            placeholder={translate('administrator.users.searchEmail')}
            value={searchItem}
            onChange={handleOnChangeSearch}
          />
        </Toolkit>
      </div> 
      <div className="usersTable">
        <TableInfinite
          tableData={dataUsers}
          headingColums={headingUsersColumns}
          breakOn={constants.TABLE_BREAKON}
          reloadTable={reloadTable}
          onItemClickListener={handleOnItemClickListener}
        />
      </div>
      {loader}
    </Fragment>
  );
}

export default UsersTable;

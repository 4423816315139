import './inputFile.scss';
import Proptypes from 'prop-types';
import Separator from '../../atoms/separator/separator';
import ListFiles from '../list-files/listFiles';
import ContainerUpload from '../../atoms/container-upload/container-upload';
import AttachFile from '../attach-file/attach-file';
import IconUpload from '../../atoms/icon-upload/icon-upload';
import InputFileConstants from '../../../constants/input-file-constants';

const InputFile = ({ 
    removeFile, 
    download, 
    data, 
    required, 
    title, 
    onChange, 
    name, 
    accept = ".jpg,.png,.pdf,.jpeg", 
    multiple = true,
    limitFiles,
    disabled, 
    ...props 
}) => {

    const constants = InputFileConstants;

    function removerArchivos(index) {
        removeFile(index);
    }

    function downloadFile(item) {
        download(item);
    }

    /*
    * Este método se usa para modificar el "value" del input de tipo "file". 
    * El value siempre se carga con una ruta tipo (C:/fakepath/<nombre-archivo-seleccionado>) y esto generaba que
    * si borras un archivo y vuelves a subir el mismo, la ruta no cambia y por ende no se ejecuta cargaba el archivo.
    */
    const changingFileValue = () => {
        if (data.length < constants.NUMBERS.ONE) return constants.EMPTY_STRING; //Retorna vacío porque es la única manera que programaticamente se puede modificar el value.
    }

    /**
     * Esta funcion se encarga de validar que los archivos adjuntos no superen el limite de archivos.
     * @returns boolean
     */
    const disableBlockFile = () =>  data.length >= limitFiles;
    
    const showListFiles = () => data.length >= constants.NUMBERS.ONE;

    return (
        
        <div id="inputFile" className={multiple ? "input-file" : "input-file-generic"}>
            <ContainerUpload dashed> 
            <div className="input-file-interno-generic">
                <div className="input-file-interno__img-upload">
                    <IconUpload colorFill={disableBlockFile() ? constants.GRAY_DISABLED: constants.PURPLE_ENABLED}/>
                </div>
                <div className="attach-file">
                    <span className="attach-file__message-bold">{props.message}</span>
                </div>

                { showListFiles() &&
                    <div className="container-files">
                        <ListFiles
                            onChange={onChange}
                            data={data}
                            accept={accept}
                            title={title}
                            required={required}
                            download={download}
                            name={name}
                            disabled={disabled}
                            removerArchivos={removerArchivos}
                        />
                    </div>
                }

                {multiple 
                    ?   <AttachFile 
                            multiple 
                            message={props.message}
                            disableBlockFile={disableBlockFile}
                        />

                    :   <div className="input-file__internal-text">
                            <span className="input-file__message-bold">{props.message}</span>
                        </div>
                }

                {(multiple && !disableBlockFile()) && (
                    <input
                        id="file-uploader" 
                        multiple={multiple} 
                        className="file-input" 
                        key={name} 
                        title={title} 
                        name={name} 
                        type="file" 
                        accept={accept} 
                        onChange={onChange} 
                        required={required} 
                        {...props} 
                    />
                )}
            </div>
        </ContainerUpload> 
        </div>

    );
};

InputFile.propTypes = {
    onChange: Proptypes.func.isRequired,
    required: Proptypes.string,
    title: Proptypes.string,
    data: Proptypes.arrayOf(
        Proptypes.object
    ),
}

export default InputFile;
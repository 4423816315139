import Images from '../../atoms/images/images'
import factura from '../../../images/factura.svg'

import './HeaderWrapper.scss'
import headerWrapperConstants from '../../../constants/header-wrapper-constants'

const HeaderWrapper = ({image,mainTitle,secondaryTitle, description, ...props}) => {
    const constants = headerWrapperConstants;
    let secondaryClass = constants.SECONDARY_LABEL;
    let hideSecondary = (secondaryTitle)? secondaryClass : constants.HIDE_CLASS;

    return ( 
        <div className="Header">
            <div className="Primary">
                <div className="TitleContent">
                    <h2>{mainTitle}</h2>
                    <p>{description}</p>
                </div>
                <hr />
            </div>
            <div className={hideSecondary}>
                <h4>{secondaryTitle}</h4>
            </div>
        </div>
    )
}

export default HeaderWrapper;

import { useReducer, createContext } from "react";

export const UserContext = createContext();

const initialStateUser = {
  userId:"",
  email: "",
  nombre: "",
  apellido: "",
  codigoSistema: "",
  negocios: [],
  roleId: null,
  filiales:[]
};

const reducer = (state, action) => {
  switch (action.type) {
    case "RESET":
      return initialStateUser;
    default:
      return action.payload;
  }
};

const UserProvider = ({ children }) => {
  const [dataUser, setDataUser] = useReducer(reducer, initialStateUser);
  return (
    <UserContext.Provider value={{ dataUser, setDataUser }}>
      {children}
    </UserContext.Provider>
  );
};
export default UserProvider;

const CheckboxContainerConstants = {

  GLOBAL: "global",
  EMPTY: "",

  ONE: 1,
  
  TRUE: true,
  FALSE: false,

  CASE: {
    CHECKBOX: "checkbox",
    INPUT_FILE_GENERIC: "inputFileGeneric", 
  },

  DESCRIPTION: {
    QUALITY_SERVICE: "Calidad del servicio",
    CONTRACT_PERFORMANCE: "Cumplimiento del contrato",
    MANAGEMENT_ADVANCE: "Manejo del anticipo",
    PAYMENT_WAGES_SOCIAL_BENEFITS: "Pago de salarios y prestaciones sociales",
    CIVIL_LIABILITY: "Responsabilidad civil",
    NON_CONTRACTUAL_CIVIL_LIABILITY: "Responsabilidad civil extracontractual",
    SERIOUSNESS_OFFER: "Seriedad de la oferta",
    OTHER: "Otra",
  },

  TRANSLATE:{
    QUALITY_SERVICE:'policy.requestPolicy.qualityService',
    CONTRACT_PERFORMANCE:'policy.requestPolicy.contractPerformance',
    MANAGEMENT_ADVANCE:'policy.requestPolicy.managementAdvance',
    PAYMENT_WAGES_SOCIAL_BENEFITS:'policy.requestPolicy.paymentWagesSocialBenefits',
    CIVIL_LIABILITY:'policy.requestPolicy.civilLiability',
    NON_CONTRACTUAL_CIVIL_LIABILITY:'policy.requestPolicy.nonContractualCivilLiability',
    SERIOUSNESS_OFFER:'policy.requestPolicy.seriousnessOffer',
    OTHER:'policy.requestPolicy.other',
  }


};

export default CheckboxContainerConstants
import React from "react";
import "./textSearchNombreCliente.scss";

const TextSearchNombreCliente = ({
  name,
  value,
  onKeyUp,
  onChange,
  placeholder,
  data,
  disabled,
  autoComplete,
  label
}) => {
  return (
    <div className={disabled ? "input-text input-text--disabled" : "input-text input-text--text"}>
      <label className="input-text__body">
        <label className={disabled ? "input-text__label--disabled" : "input-text__label"} >{label}</label>
        <input
          className="input-text__input input-text__input--image"
          type="search"
          id={name}
          list="listaNombre"
          value={value}
          onKeyUp={onKeyUp}
          onChange={onChange}
          placeholder=" "
          name={name}
          required
          disabled={disabled}
          autoComplete={autoComplete}
        />
        <span className="input-text__placeholder">{placeholder}</span>
        <datalist id="listaNombre">
          {!!data &&
            data.map((item) => (
              <option
                value={item.razonSocialRut}
                key={item.razonSocialRut}
              >
                {item.razonSocialRut}
              </option>
            ))}
        </datalist>
      </label>
    </div>
  );
};
export default TextSearchNombreCliente;

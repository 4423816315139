import React, { useState, useContext, useEffect } from "react";
import {getClientByName, getClientByNitInit} from "../../../services/clientService";
import { ClientContext } from '../../../provider/cliente';
import TextSearchBlockNitCliente from "../../molecules/text-search-block-nit-cliente/textSearchBlockNitCliente";
import TextSearchBlockNombreCliente from "../../molecules/text-search-block-nombre-cliente/textSearchBlockNombreCliente";
import listarRegimenTributario from "../../../services/listarRegimenTributario";
import "./dataClient.scss";
import { isInvoiceRequestDisable} from '../../../utils/utils';
import { getCityById } from "../../../services/getLocationList";
import { useTranslation } from "react-i18next";
import { PdsInput } from "@pragma-dev-kit/pragma-ds-react";
import { validateField } from "../../../utils/utils";
import DataClientConstants from "../../../constants/data-client-constants";

let timer;

const DataClient = (Props) => {
  const constants = DataClientConstants; 

  const [data, setData] = useContext(Props.context);
  const { dataClient, setDataClient } = useContext(ClientContext);
  const [regimenTributario, setRegimenTributario] = React.useState([]);
  const [ciudad, setCiudad] = useState(constants.EMPTY);
  const [dataListClientes, setDataListClientes] = useState([]);
  const [errors, setErrors] = useState({});
  const [changeClient, setChangeClient] = Props.changeClient;
  const [translate] = useTranslation(constants.GLOBAL);

  useEffect(() => {
    obtenerRegimenTributario();
  }, []);
  
  const actualizarDatos = (event) => {
    setDataClient({
      type: constants.TYPE_ADD,
      payload: { ...dataClient, [event.target.name]: event.target.value }
    });
  };

  const handleOnInput = (event) => {
    const { name, value } = event.target;
    updateData(event);

    const fieldErrors = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      ...fieldErrors,
    }));
  };

  const updateData = (event) => {
    setData({
      type: constants.TYPE_ADD,
      payload: { ...data, [event.target.name]: event.target.value }
    });
  };
  const ConsultaClienteByNit = (event) => {
    if (dataClient.clienteId && event.type === constants.TYPE_CHANGE) {
      resetDataClient();
    } else {
      actualizarDatos(event);
      clearTimeout(timer);
      timer = setTimeout(async () => {
        if(dataClient.numeroDocumento.length > constants.NUMBER_TWO){
          const responseData = await getClientByNitInit(dataClient);
          setDataListClientes(responseData);
          if (responseData?.length === constants.NUMBER_ONE) {
            setDataClient({
              type: constants.TYPE_ADD,
              payload: responseData[constants.NUMBER_ZERO]
            });
            data.clienteId = responseData[constants.NUMBER_ZERO].clienteId;
          }
        }
      }, constants.TIME_500);
    }
  };

  const ConsultaClienteByName = (event) => {
    if (dataClient.clienteId && event.type === constants.TYPE_CHANGE) {
      resetDataClient();
    } else {
      actualizarDatos(event);
      clearTimeout(timer);
      timer = setTimeout(async () => {
        if(dataClient.razonSocialRut.length > constants.NUMBER_TWO){
          const responseData = await getClientByName(dataClient);
          setDataListClientes(responseData);
          if (responseData?.length === constants.NUMBER_ONE) {
            setDataClient({
              type: constants.TYPE_ADD,
              payload: responseData[constants.NUMBER_ZERO]
            });
            data.clienteId = responseData[constants.NUMBER_ZERO].clienteId;
          }
        }
      }, constants.TIME_500);
    }
  };

  function obtenerRegimenTributario() {
    listarRegimenTributario().then((result) => {
      setRegimenTributario(result.data);
    });
  }

  function obtenerCiudadPorId(ciudadId) { 
    getCityById(ciudadId).then(result => {
      setCiudad(result.name)
    }).catch(err => {
      setCiudad(constants.EMPTY);
    })
  }

  const resetDataClient = () => {
    setDataClient({
      type: constants.TYPE_RESET
    })
    setData({
      type : constants.TYPE_CHANGE_CLIENT_ID,
      payload : {...data, clienteId: constants.NUMBER_MINUS_ONE}
    })
    setChangeClient(true);
  }

  useEffect(() => {
    if (dataClient.ciudadId) {
      obtenerCiudadPorId(dataClient.ciudadId)
    }
  }, [dataClient])

  return (
    <div className="DataClient">
      <div className="HeaderDataClient">
        <h3>{translate('invoices.createInvoice.customerData')}</h3>
      </div>
      <div className={`BodyDataClient ${dataClient.razonSocialRut && dataClient.numeroDocumento ? '' : 'reduce'}`}>
        <div className="primaryDate">
          <div className="primary margin margin__bottom responsiveMargin">
            <TextSearchBlockNombreCliente
              onChange={ConsultaClienteByName}
              name="razonSocialRut"
              onKeyUp={(actualizarDatos, ConsultaClienteByName)}
              data={dataListClientes}
              label={translate('invoices.createInvoice.name')}
              placeholder={translate('invoices.createInvoice.searchName')}
              value={dataClient.razonSocialRut}
              disabled={ isInvoiceRequestDisable(data) ? "disabled" : ""}
              autoComplete="off"
            />
          </div>
          <div className="primary margin margin__bottom responsiveMargin">
            <TextSearchBlockNitCliente
              onChange={ConsultaClienteByNit}
              title={dataClient.numeroDocumento}
              type="number"
              name="numeroDocumento"
              onKeyUp={(actualizarDatos, ConsultaClienteByNit)}
              data={dataListClientes}
              label={translate('invoices.createInvoice.nit')}
              placeholder={translate('invoices.createInvoice.searchNit')}
              value={dataClient.numeroDocumento}
              digitoVerificacion={dataClient.digitoVerificacion}
              disabled={isInvoiceRequestDisable(data) ? "disabled" : ""}
              autoComplete="off"
            />
          </div>
          { dataClient.razonSocialRut && dataClient.numeroDocumento ? 
            <div className={`primary margin__bottom responsiveMargin ${dataClient.razonSocialRut && dataClient.numeroDocumento ? 'showInput' : 'hideInput'}`}>
              <PdsInput 
                disabled={isInvoiceRequestDisable(data) ? true : false}
                label={translate('invoices.createInvoice.taxRegime')}
                value={dataClient.clasificacionTributariaId}
                placeholder={translate('invoices.createInvoice.taxRegime')}
                type="text"
                name="nombreRegimenTributario"
                onInput={handleOnInput}
                {...(errors.nombreRegimenTributario || {})}/>
            </div>
              :
            <></>
          }
          
        </div>
        { dataClient.razonSocialRut && dataClient.numeroDocumento ?
          <div className={`primaryDate secondaryDate ${dataClient.razonSocialRut && dataClient.numeroDocumento ? '' : 'hideInput'}`} >
          <div className="info-client">
            <div className="secondary margin__bottom responsiveMargin">
              <PdsInput 
                disabled={isInvoiceRequestDisable(data) ? true : false}
                value={dataClient.direccionRut}
                label={translate('invoices.createInvoice.address')}
                placeholder={translate('invoices.createInvoice.address')}
                type="text"
                name="direccion"
                onInput={handleOnInput}
                {...(errors.direccion || {})}
                />
            </div>
            <div className="secondary responsiveMargin">
              <PdsInput
                disabled={isInvoiceRequestDisable(data) ? true : false}
                value={ciudad}
                label={translate('invoices.createInvoice.city')}
                placeholder={translate('invoices.createInvoice.city')}
                type="text"
                name="ciudad"
                onInput={handleOnInput}
                {...(errors.ciudad || {})}
              />
            </div>
            <div className="secondary responsiveMargin">
              <PdsInput
                disabled={isInvoiceRequestDisable(data) ? true : false}
                value={dataClient.telefonoRut}
                label={translate('invoices.createInvoice.phone')}
                placeholder={translate('invoices.createInvoice.phone')}
                type="text"
                name="telefono"
                onInput={handleOnInput}
                {...(errors.telefono || {})}
              />
            </div>
          </div>  
          <div className="info-client">
            <div className="secondary responsiveMargin info-secundary">
              <PdsInput
                label={translate('invoices.createInvoice.contact')}
                type="text"
                required=""
                name="contacto"
                onInput={handleOnInput}
                value={data.contacto}
                disabled={isInvoiceRequestDisable(data) ? true : false}
                {...(errors.contacto || {})}
              />
            </div>
            <div className="secondary responsiveMargin info-secundary">
              <PdsInput
                label={translate('invoices.createInvoice.job')}
                type="text"
                name="cargoContacto"
                onInput={handleOnInput}
                value={data.cargoContacto}
                disabled={isInvoiceRequestDisable(data) ? true : false}
                {...(errors.cargoContacto || {})}
              />
            </div>
          </div>
        </div>
          :
          <></>
        }
        
      </div>
    </div>
  );
};

export default DataClient;

import { useState } from "react";
import useLoader from "../../../utils/useLoader";
import HeaderInvoiceRequest from "../../organism/header-invoice-request/HeaderInvoiceRequest";
import Aside from "../../templates/aside/aside";
import UsersTable from "../../templates/usersTable/usersTable";
import Wrapper from "../../templates/wrapper/Wrapper";
import { useTranslation } from "react-i18next";
import TranslationComponentConstants from "../../../constants/translation-language-constants";

function Users() { 
  const translationConstants = TranslationComponentConstants; 

  const [translate] = useTranslation(translationConstants.USE_TRANSLATION_GLOBAL_VALUE);
  const [loader] = useLoader();
  const title = translate('administrator.users.title');
  const description = translate('administrator.users.description');
  const [blur, setBlur] = useState(false);

  return (
    <Wrapper aside={<Aside blur={blur} setBlur={setBlur} />} blur={blur}>
        <HeaderInvoiceRequest title={title} description={description} />
        <UsersTable />
      {loader}
    </Wrapper>
  );
}

export default Users;
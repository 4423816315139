import { useReducer, createContext } from 'react';
import policyReducer, { initialState } from '../reducers/policyReducer';

export const PolicyContext = createContext();

const PolicyProvider = ({children}) => {
    const [dataPolicy, setDataPolicy] = useReducer(policyReducer, initialState);
    return (
        <PolicyContext.Provider value={[dataPolicy, setDataPolicy]}>
            {children}
        </PolicyContext.Provider>
    );
}

export default PolicyProvider;
import React, { useEffect, useContext, useState, useRef } from "react";
import { useParams, useHistory } from 'react-router-dom';

import GeneralInformation from '../../templates/informacion/generalInformation';
import Observaciones from '../../templates/observaciones/observaciones';
import CheckboxContainer from '../../molecules/checkbox/checkbox-container';
import Wrapper from '../../templates/wrapper/Wrapper';
import Aside from '../../templates/aside/aside';
import { informationAlert, confirmationAlert } from '../../molecules/alert/alert';
import RelationsPolicy from "../../organism/policy-relationships/policyRelations";

import { toBase64, validatePolicyFields, formatDate } from '../../../utils/utils';
import { POLICY_ID, STATUS_ID } from '../../../utils/constans';
import { ROLES } from '../../../config/roles'

import { getPolicyTypes, getPolicyById, createPolicyRequest, updatePolicy } from '../../../services/policyService';
import { getClientByID } from '../../../services/clientService';
import useLoader from '../../../utils/useLoader';
import { PolicyContext } from '../../../provider/policy';
import { ClientContext } from '../../../provider/cliente';
import { AppContextUser } from '../../../provider/userInSession';
import { useTranslation } from "react-i18next";
import PolicyRequestConstants from "../../../constants/policy-request-constants";

function PolicyRequest(props) {
  const constants = PolicyRequestConstants;
  const [translate] = useTranslation(constants.GLOBAL);

  const limitFiles = constants.NUMBER_FIVE;
  const SCROLLIDOBSERVATIONS = constants.NUMBER_FOUR;
  const text = `${translate('policy.requestPolicy.specifyPageGuarantees')},`;
  const textStrong = `${translate('policy.requestPolicy.eitherPurchaseOrder')}.`;
  const initialText = translate('components.inputFile.dropDocumentsSelectFile');
  let date = new Date();
  const stringDate = JSON.stringify(date);
  let currentYear = stringDate.substring(constants.NUMBER_ONE, constants.NUMBER_FIVE);
  let currentMonth = stringDate.substring(constants.NUMBER_SIX, constants.NUMBER_EIGHT);
  let currentDay = stringDate.substring(constants.NUMBER_NINE, constants.NUMBER_ELEVEN);
  let fullDeadlineDateMin = currentYear + constants.MIDDLE_DASH + currentMonth + constants.MIDDLE_DASH + currentDay;
  var lastDay = new Date(date.getFullYear(), date.getMonth() + constants.NUMBER_ONE, constants.NUMBER_ZERO);
  const stringLastDay = JSON.stringify(lastDay)
  let numberOfLastDay = stringLastDay.substring(constants.NUMBER_NINE, constants.NUMBER_ELEVEN)
  let fullDeadlineDateMax = currentYear + constants.MIDDLE_DASH + currentMonth + constants.MIDDLE_DASH + numberOfLastDay;

  const { setDataClient } = useContext(ClientContext);
  const [dataPolicy, setDataPolicy] = useContext(PolicyContext);
  const { dataUser } = useContext(AppContextUser);
  const { idPolicy } = useParams();
  const [policyTypes, setPolicyTypes] = useState([]);
  const [status, setStatus] = useState(constants.FALSE);
  const changeClientState = useState(constants.FALSE);

  const [message, setMessage] = useState(initialText);
  const history = useHistory();
  const [loader, showLoader, hideLoader] = useLoader();
  const observationRef = useRef(constants.NULL);
  const executeScroll = () => observationRef.current.scrollIntoView();

  const onChangeText = event => {
    const otherPolicy = dataPolicy.tiposPolizasSolicitud.find(element => element.tipoPolizaId === POLICY_ID.OTRA);
    otherPolicy.descripcionOtra = event.target.value;
    setDataPolicy({
      action: constants.SETDATAPOLICY_AGREGAR,
      payload: { ...dataPolicy }
    });
  }

  const getDataClient = async clientId => {
    const data = { clienteId: clientId };
    const client = await getClientByID(data);
    setDataClient({
      type: constants.SETDATAPOLICY_AGREGAR,
      payload: { ...client }
    });
  }

  const getPolicyStatusName = (statusId) => {
    return Object.keys(STATUS_ID).find(key => STATUS_ID[key] === statusId);
  }

  const updateDate = event => {
    if (event.type) {
      dataPolicy.fechaLimiteEntrega = event.target.value;
      setDataPolicy({
        type: constants.SETDATAPOLICY_AGREGAR,
        payload: { fechaLimiteEntrega: dataPolicy.fechaLimiteEntrega }
      });
    } else {
      switch (event.tag) {
        case constants.FECHA_SOLICITUD:
          dataPolicy.fechaSolicitud = event.value;
          break;
        case constants.FECHA_LIMITE_ENTREGA:
          dataPolicy.fechaLimiteEntrega = event.value;
          break;
        default:
          informationAlert(constants.ALERT_ERROR, translate('alerts.oops'), translate('alerts.dateError'));
          break;
      }
      setDataPolicy({
        type: constants.SETDATAPOLICY_AGREGAR,
        payload: { ...dataPolicy }
      });
    }

    setPolicyInformation([
      {
        tag: `${translate('policy.requestPolicy.applicationDate')} `,
        value: formatDate(dataPolicy.fechaSolicitud, constants.FORMAT_DATE),
        type: constants.TYPE_ORANGE
      },
      {
        tag: `${translate('policy.requestPolicy.deliveryDeadline')} `,
        value: formatDate(dataPolicy.fechaLimiteEntrega, constants.DATE),
        type: constants.TYPE_DATE,
        callback: updateDate,
        min: fullDeadlineDateMin,
        max: fullDeadlineDateMax
      }
    ])
  }

  const INITIAL_INFORMATION = [
    {
      tag: `${translate('policy.requestPolicy.applicationDate')}: `,
      value: formatDate(dataPolicy.fechaSolicitud, constants.FORMAT_DATE),
      type: constants.TYPE_ORANGE
    },
    {
      tag: `${translate('policy.requestPolicy.deliveryDeadline')}: `,
      value: formatDate(dataPolicy.fechaLimiteEntrega, constants.DATE),
      type: constants.TYPE_DATE, callback: updateDate,
      min: fullDeadlineDateMin,
      max: fullDeadlineDateMax
    }
  ];

  const [policyInformation, setPolicyInformation] = useState(INITIAL_INFORMATION);
  React.useEffect(() => {
    const _policyInformation = [...policyInformation];
    if (_policyInformation[constants.NUMBER_TWO]) {
      _policyInformation[constants.NUMBER_ZERO].tag = `${translate('policy.requestPolicy.requestedBy')}`;
      _policyInformation[constants.NUMBER_ONE].tag = `${translate('policy.requestPolicy.state')}`;
      _policyInformation[constants.NUMBER_TWO].tag = `${translate('policy.requestPolicy.noRequest')}`;
      _policyInformation[constants.NUMBER_THREE].tag = `${translate('policy.requestPolicy.applicationDate')} `;
      _policyInformation[constants.NUMBER_FOUR].tag = `${translate('policy.requestPolicy.deliveryDeadline')} `;
    } else {
      _policyInformation[constants.NUMBER_ZERO].tag = `${translate('policy.requestPolicy.applicationDate')} `;
      _policyInformation[constants.NUMBER_ONE].tag = `${translate('policy.requestPolicy.deliveryDeadline')} `;
    }
    setPolicyInformation(_policyInformation);
  }, [translate]);

  const getData = async () => {
    if (idPolicy) {
      const data = { polSolicitudId: idPolicy }
      showLoader();
      await getPolicyById(data).then(response => {
        hideLoader();
        if (response.data.status === constants.STATUS_200) {
          setDataPolicy({
            type: constants.SETDATAPOLICY_AGREGAR,
            payload: response.data.data
          });
          getDataClient(response.data.data.clienteId);
          const policyDataToShow = [
            { tag: `${translate('policy.requestPolicy.requestedBy')}:`, value: response.data.data.usuarioEmail },
            { tag: `${translate('policy.requestPolicy.state')}:`, value: getPolicyStatusName(response.data.data.estadoId) },
            { tag: `${translate('policy.requestPolicy.noRequest')}:`, value: response.data.data.polSolicitudId },
            { tag: `${translate('policy.requestPolicy.applicationDate')}: `, value: formatDate(response.data.data.fechaSolicitud, constants.FORMAT_DATE) },
            { tag: `${translate('policy.requestPolicy.deliveryDeadline')}: `, value: formatDate(response.data.data.fechaLimiteEntrega, constants.FORMAT_DATE)},
          ];
          setPolicyInformation(policyDataToShow);
          if (response.data.data.estadoId === SCROLLIDOBSERVATIONS) {
            focusObservations();
          }
        } else {
          informationAlert(constants.ALERT_ERROR, translate('alerts.alert'), response.data.userMessage);
          response.data.status === constants.STATUS_403 ? history.push(constants.URL_ROOT) : history.push(constants.URL_POLIZA);
        }
      }).catch(err => {
        hideLoader();
        informationAlert(constants.ALERT_ERROR, translate('alerts.error'), err);
      });
    } else {
      setDataPolicy({
        type: constants.TYPE_INITIAL_STATE,
      });
      setDataClient({
        type: constants.TYPE_RESET,
      });
    }
  };

  const setFileOnProvider = async (files, key, fileCategory) => {
    const filesInBase64 = [];
    if (files.length) {
      files.map(file => {
        if (!file.archivoId)
          toBase64(file, fileCategory, otherFile => { filesInBase64.push(otherFile) })
      });
    }
    if (key !== constants.NUMBER_ZERO)
      dataPolicy.tiposPolizasSolicitud.map(element => {
        if (element.tipoPolizaId === key) {
          element.files = filesInBase64;
          setDataPolicy({
            type: constants.TYPE_ADD_FILE,
            payload: { ...dataPolicy }
          });
        }
      });
  }

  const requestPolicy = async () => {
    try {
      validatePolicyFields(dataPolicy, () => {
        showLoader();
        createPolicyRequest(dataPolicy)
          .then(response => {
            hideLoader();
            switch (response.data.status) {
              case constants.STATUS_200:
                informationAlert(constants.ALERT_SUCCES, translate('alerts.success'), response.data.userMessage)
                setDataPolicy({
                  type: constants.TYPE_RESET
                });
                setDataClient({
                  type: constants.TYPE_RESET
                });
                break;
              default:
                informationAlert(constants.ALERT_ERROR, translate('alerts.oops'), response.data.userMessage);
                break;
            }
          })
          .catch(err => {
            hideLoader();
            throw ({ type: constants.ALERT_ERROR, msg: constants.THROW_MSG, details: constants.EMPTY });
          });
      });
    } catch (error) {
      informationAlert(error.type, error.msg, error.details);
      return constants.FALSE;
    }
  };

  function focusObservations() {
    executeScroll();
  }

  const approvePolicyRequest = async () => {
    showLoader();
    const policyData = await {
      ...dataPolicy,
      estadoId: STATUS_ID.APROBADA
    };
    try {
      validatePolicyFields(dataPolicy, () => {
        updatePolicy(policyData)
          .then(response => {
            switch (response.data.status) {
              case constants.STATUS_200:
                informationAlert(constants.ALERT_SUCCES, translate('alerts.success'), response.data.userMessage)
                history.push(constants.URL_POLIZAS_PENDIENTES);
                break;
              default:
                informationAlert(constants.ALERT_ERROR, translate('alerts.oops'), response.data.userMessage);
                break;
            }
            hideLoader();
          })
          .catch(err => {
            hideLoader();
            throw ({ type: constants.ALERT_ERROR, msg: constants.THROW_MSG, details: constants.EMPTY });
          });
      });
    } catch (error) {
      hideLoader();
      informationAlert(error.type, error.msg, error.details);
      return constants.FALSE;
    }
  };

  const updateTypePolicies = (event) => {
    if (event.target.checked) {
      if (parseInt(event.target.id) === POLICY_ID.OTRA)
        setStatus(constants.TRUE);
      dataPolicy.tiposPolizasSolicitud.push({
        tipoPolizaId: parseInt(event.target.id),
        descripcionOtra: constants.EMPTY
      });

    } else {
      if (parseInt(event.target.id) === POLICY_ID.OTRA)
        setStatus(false);
      dataPolicy.tiposPolizasSolicitud = dataPolicy.tiposPolizasSolicitud.filter(policy => policy.tipoPolizaId !== parseInt(event.target.id));
    }
    setDataPolicy({
      action: constants.SETDATAPOLICY_AGREGAR,
      payload: { ...dataPolicy }
    });
  }

  const clearInfo = () => {
    confirmationAlert(
      constants.ALERT_WARNING,
      translate('alerts.clearAllData'),
      constants.EMPTY,
      translate('transversal.yes'),
      translate('transversal.no'),
      () => {
        setDataPolicy({
          type: constants.TYPE_INITIAL_STATE,
        });
        setDataClient({
          type: constants.TYPE_RESET,
        });
      })
  }

  const shouldShowButton = () => {
    if (dataPolicy.estadoId === STATUS_ID.APROBADA && (dataUser.rol === ROLES.SOLICITANTE || dataUser.rol === ROLES.SUPERVISOR)) return constants.FALSE;
    if (dataPolicy.estadoId === STATUS_ID.APROBADA) return constants.FALSE;
    if (dataPolicy.polSolicitudId && STATUS_ID.PENDIENTE && (dataUser.rol === ROLES.SOLICITANTE || dataUser.rol === ROLES.SUPERVISOR)) return constants.FALSE;
    return constants.TRUE;
  }

  const shouldDisableFields = () => {
    if (dataPolicy.estadoId === STATUS_ID.APROBADA) return constants.TRUE;
    if (dataPolicy.polSolicitudId && (dataUser.rol === ROLES.SOLICITANTE || dataUser.rol === ROLES.SUPERVISOR)) {
      return constants.TRUE;
    }
    return constants.FALSE;
  }

  useEffect(() => {
    if (status && !dataPolicy.tiposPolizasSolicitud.length)
      setStatus(constants.FALSE);

    if (dataPolicy.polSolicitudId && (dataUser.rol === ROLES.SOLICITANTE || dataUser.rol === ROLES.SUPERVISOR))
      setMessage(translate('alerts.actionNotAllowed'));

    if (dataPolicy.polSolicitudId && dataPolicy.estadoId === STATUS_ID.APROBADA)
      setMessage(constants.EMPTY);
  }, [dataPolicy]);

  const buttons = [
    {
      name: translate('transversal.clean'),
      primary: constants.PRIMARY_WHITE,
      onClick: clearInfo,
      hide: dataPolicy.polSolicitudId ? constants.TRUE : constants.FALSE
    },
    {
      name: dataPolicy.polSolicitudId ? translate('transversal.approve') : translate('transversal.request'),
      primary: dataPolicy.polSolicitudId ? constants.PRIMARY_WHITE : constants.PRIMARY_DARK,
      onClick: dataPolicy.polSolicitudId ? approvePolicyRequest : requestPolicy
    }
  ];

  useEffect(() => {
    getPolicyTypes().then(response => {
      setPolicyTypes(response);
    });

    getData();
    return () => { };
  }, []);

  const [blur, setBlur] = useState(false);

  return (
    <Wrapper aside={<Aside blur={blur} setBlur={setBlur} />} blur={blur}>
      <form>
        <GeneralInformation
          policy
          limitFiles={limitFiles}
          context={PolicyContext}
          show={dataPolicy.polSolicitudId}
          dataToShow={policyInformation}
          changeClient={changeClientState}
        />

        <CheckboxContainer handleChange={updateTypePolicies} handleChangeText={onChangeText} handleFileChange={setFileOnProvider}
          showOtherPolicyInput={status} message={message}
          data={dataPolicy.polSolicitudId ? dataPolicy.tiposPolizasSolicitud : policyTypes}
          componentType={dataPolicy.polSolicitudId ? "inputFileGeneric" : "checkbox"}
          checked={dataPolicy.reset ? false : undefined}
          disabledFields={shouldDisableFields()} />

        <RelationsPolicy context={PolicyContext} isEditing={dataPolicy.polSolicitudId} changeClientState={changeClientState} />

        <div ref={observationRef}>
          <Observaciones handleEvent={dataPolicy.polSolicitudId ? approvePolicyRequest : requestPolicy}
            title={translate('transversal.observations')}
            context={PolicyContext} 
            policy 
            text={text} 
            textStrong={textStrong}
            buttonText={dataPolicy.polSolicitudId ? translate('transversal.approve') : translate('transversal.request')}
            showButton={shouldShowButton()}
            buttons={buttons} />
        </div>
        {loader}
      </form>
    </Wrapper>
  );
}

export default PolicyRequest;
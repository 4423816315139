import { useReducer, createContext } from "react";

export const BrandContext = createContext();

const initialStateBrand = {
  name: "",
  costCenter: "",
  active: true,
  filiales: [],
  clients: [],
  code: 0
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'RESET':
      return initialStateBrand;
    default:
      return action.payload;
  }
}

const BrandProvider = ({ children }) => {
  const [dataBrand, setDataBrand] = useReducer(reducer, initialStateBrand);
  return (
    <BrandContext.Provider value={{ dataBrand, setDataBrand }}>
      {children}
    </BrandContext.Provider>
  );
};
export default BrandProvider;

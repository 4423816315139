import { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { BrandContext } from "../../../provider/brand";
import { ClientContext } from "../../../provider/cliente";
import { getClientByName } from "../../../services/clientService";
import {
  getBrandFullinformation,
  saveNewBrand,
} from "../../../services/listarMarcasService";
import { getFiliales } from "../../../services/parametersService";
import useLoader from "../../../utils/useLoader";
import { validateBrandField } from "../../../utils/utils";
import CheckboxSwitch from "../../atoms/checkbox-switch/CheckboxSwitch";
import { informationAlert } from "../../molecules/alert/alert";
import "../../templates/informacion/generalInformation.scss";
import FinderSelector from "../finder-selector/finderSelector";
import "./brandForm.scss";
import { useTranslation } from "react-i18next";
import { PdsButton, PdsInput } from "@pragma-dev-kit/pragma-ds-react";
import TranslationComponentConstants from "../../../constants/translation-language-constants";
import brandFormComponentConstants from "../../../constants/brandform-constants";
import { validateField } from "../../../utils/utils";

const BrandForm = ({ isFromClient }) => {
  const translationConstants = TranslationComponentConstants;
  const constants = brandFormComponentConstants;
  const [translate] = useTranslation(translationConstants.USE_TRANSLATION_GLOBAL_VALUE);
  let timer;
  const { dataBrand, setDataBrand } = useContext(BrandContext);
  const { dataClient, setDataClient } = useContext(ClientContext);
  const [dataListClient, setDataListClient] = useState([]);
  const [dataFiliales, setDataFiliales] = useState([]);
  const [dataButtons, setDataButtons] = useState([]);
  const [dataButtonsFiliales, setDataButtonsFiliales] = useState([]);
  const [errors, setErrors] = useState({});
  const [loader, showLoader, hideLoader] = useLoader();
  const idBrandParam = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const history = useHistory();


  useEffect(() => {
    async function fetchBrandById(id) {
      showLoader();
      try {
        const response = await getBrandFullinformation(id);
        setDataButtons(response.clients);
        setDataButtonsFiliales(response.filiales);
        setDataBrand({
          type: constants.ADD_LABEL,
          payload: response,
        });
        hideLoader();
      } catch (error) {
        hideLoader();
      }
    }

    if (idBrandParam.id != null && !isFromClient) {
      setIsEditing(true);
      fetchBrandById(idBrandParam.id);
    }
  }, []);


  useEffect(() => {
    const fetchFiliales = async () => {
      try {
        const filiales = await getFiliales();
        setDataFiliales(filiales);
      } catch {
        setDataFiliales([]);
      }
    };

    fetchFiliales();
  }, []);


  const updateData = (event) => {
    const updatedData = {
      ...dataClient,
      [event.target.name]: event.target.value
    };
    setDataClient({
      type: constants.ADD_LABEL,
      payload: updatedData,
    });
  };

  const resetDataClient = () => {
    setDataClient({
      type: constants.RESET_LABEL,
    });
  };

  const getClienteByName = (event) => {
    if (dataClient.clienteId && event.type === constants.CHANGE_LABEL) {
      resetDataClient();
    } else {
      clearTimeout(timer);
      updateData(event);
      timer = setTimeout(async () => {
        if (dataClient.razonSocialRut.length > constants.LENGTH_2) {
          const responseData = await getClientByName(dataClient);
          setDataListClient(responseData);
          if (responseData?.length === constants.LENGTH_1) {
            var existButton = dataButtons.find(
              (dataButton) => dataButton.itemId === responseData[constants.FIRST_INDEX_POSITION].clienteId
            );
            if (!existButton) {
              dataButtons.push({
                itemId: responseData[constants.FIRST_INDEX_POSITION].clienteId,
                itemName: responseData[constants.FIRST_INDEX_POSITION].razonSocialRut,
              });
              setDataClient({
                type: constants.ADD_LABEL,
                payload: responseData[constants.FIRST_INDEX_POSITION],
              });
              updateArrays(constants.CLIENTS_LABEL, dataButtons);
            }
            resetDataClient();
          }
        }
      }, constants.TIMER_500);
    }
  };

  const removeItemPill = (itemId) => {
    const newDataButtons = dataButtons.filter((element) => element.itemId !== itemId);
    setDataButtons(newDataButtons);
    updateArrays(constants.CLIENTS_LABEL, newDataButtons);
  };

  const removeItemPillFilial = (itemId) => {
    const newDataButtonsFiliales = dataButtonsFiliales.filter((element) => element.itemId !== itemId);
    setDataButtonsFiliales(newDataButtonsFiliales);
    updateArrays(constants.AFFILIATES_LABEL, newDataButtonsFiliales);
  };

  const handleOnChange = (event) => {
    setDataBrand({
      type: constants.ADD_LABEL,
      payload: {
        ...dataBrand,
        [event.target.name]:
          event.target.type === constants.CHECKBOX_LABEL ? event.target.checked : event.target.value,
      },
    });
  };

  const handleOnInput = (event) => {
    const { name, value } = event.target;
    handleOnChange(event);

    const fieldErrors = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      ...fieldErrors,
    }));
  };

  const getFilialName = (id) => {
    const filial = dataFiliales.find((filial) => filial.code === parseInt(id));
    return filial ? filial.name : informationAlert(constants.ERROR_LABEL, translate('alerts.oops'), translate('alerts.notFound'));
  };

  const handleOnChangeFilial = (event) => {
    let filialesBrand = [...dataButtonsFiliales];
    if (
      !dataButtonsFiliales.find((item) => item.itemId === event.target.value)
    ) {
      filialesBrand.push({
        itemId: event.target.value,
        itemName: getFilialName(event.target.value),
      });
      setDataButtonsFiliales([
        ...dataButtonsFiliales,
        {
          itemId: event.target.value,
          itemName: getFilialName(event.target.value),
        },
      ]);
    }
    updateArrays(constants.AFFILIATES_LABEL, filialesBrand);
  };

  const updateArrays = (name, array) => {
    setDataBrand({
      type: constants.ADD_LABEL,
      payload: { ...dataBrand, [name]: array },
    });
  };

  const handleSubmit = () => {
    try {
      validateBrandField(dataBrand, () => {
        showLoader();
        saveNewBrand(dataBrand)
          .then((response) => {
            hideLoader();
            switch (response.data.status) {
              case constants.STATUS_CODE_200:
                history.push(constants.BRANDS_ROUTE);
                informationAlert(constants.SUCCESS_LABEL, translate('alerts.success'), response.data.userMessage);
                break;
              default:
                informationAlert(constants.ERROR_LABEL, translate('alerts.oops'), response.data.userMessage);
                break;
            }
          })
          .catch((error) => {
            hideLoader();
            informationAlert(constants.ERROR_LABEL, translate('alerts.oops'), error.message);
          });
        hideLoader();
      });
    } catch (error) {
      informationAlert(error.type, error.msg, error.details);
    }
  };

  return (
    <Fragment>
      <div className="form__content">
        <div className="form__content__header">
          {isEditing ? <h3>{translate('administrator.createBrand.editing')}</h3> : <h3>{translate('administrator.createBrand.create')}</h3>}
        </div>
        <div className="form__content__formBody">
          <div className="form__content__input">
            <PdsInput
              type={constants.TYPE_TEXT}
              name={constants.NAME_NAME}
              label={translate('administrator.createBrand.name')}
              required
              value={dataBrand.name}
              onInput={handleOnInput}
              {...(errors.name || {})}
            />
          </div>
          <div className="form__content__input">
            {!isFromClient && (
              <PdsInput
                type={constants.TYPE_NUMBER}
                name={constants.NAME_COSTCENTER}
                label={translate('administrator.createBrand.costCenter')}
                required
                value={dataBrand.costCenter}
                onInput={handleOnInput}
                minLength={constants.LENGTH_3}
                {...(errors.costCenter || {})}
              />

            )}
          </div>
          <div className="form__content__formBody__formChecked">
            {!isFromClient && (
              <CheckboxSwitch
                checked={dataBrand.active}
                onChange={handleOnChange}
                name={constants.NAME_ACTIVE}
                disabledText={translate('administrator.createBrand.checkboxInactive')}
                enabledText={translate('administrator.createBrand.checkboxActive')}
              />
            )}
          </div>
        </div>
        <div className="form__content__formBody__client">
          {!isFromClient && (
            <FinderSelector
              labelSelect={translate('administrator.createBrand.customers')}
              placeholder={translate('administrator.createBrand.selectCustomer')}
              dataSelect={dataListClient}
              searchName={constants.SEARCHNAME_RAZONSOCIAL}
              onChange={getClienteByName}
              onKeyUp={(updateData, getClienteByName)}
              dataButtons={dataButtons}
              removeItemPill={removeItemPill}
              value={dataClient.razonSocialRut}
            />
          )}
          <FinderSelector
            placeholder={translate('administrator.createBrand.selectFilial')}
            dataSelect={dataFiliales}
            searchName={constants.SEARCHNAME_FILIALES}
            onChange={(event) => handleOnChangeFilial(event)}
            dataButtons={dataButtonsFiliales}
            removeItemPill={removeItemPillFilial}
            labelSelect={translate('administrator.createBrand.subsidiary')}
            isSelect
          />
        </div>
      </div>
      <div className="form__content__formButton">
        <PdsButton className="brand-form__button" onClick={handleSubmit}>
          {translate('administrator.createBrand.saveBrand')}
        </PdsButton>
      </div>
      {loader}
    </Fragment>
  );
};

export default BrandForm;

import React from "react";
import Lottie from "react-lottie";
import Loading from "../../../images/animations/loading.json"
import "./loader.scss";
import LoaderConstants from "../../../constants/loader-constants";
const Loader = ({ isLoading }) => {
  const constants = LoaderConstants;

  const defaultOptions = {
    loop: constants.TRUE,
    autoplay: constants.TRUE,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: constants.PRESERVE_ASPECT,
    },
  };


  return (
    <div className={`content-loader ${isLoading && 'loading'}`}>
      <div className="loader">
        <Lottie options={{animationData: Loading, ...defaultOptions}} />
      </div>
    </div>
  );
};

export default Loader;

import {API_BASE_URL, API_INVOICES_CONTEXT} from "../utils/constans";
import clienteAxios from "../api/axios";

const getInvoiceData = async (data) => {
    let invoiceList;
    await clienteAxios(
        `${API_BASE_URL}${API_INVOICES_CONTEXT}list`,
        data,
        null,
        "POST")
        .then((response) => {
            invoiceList = response.data;
        }).catch((err) => {
            console.log(err);
        });

    return invoiceList;
}

const getCurrentSequences = async () => {
    let currentSequences = [];

    let response = await clienteAxios(
        `${API_BASE_URL}${API_INVOICES_CONTEXT}consecutivos`,
        null,
        null,
        "GET");

    if (response.data) {
        if (response.data.status === 200) {
            currentSequences = response.data.data.map(sequence => ({
                sede_id: sequence.sedeId,
                name: sequence.nombre,
                isSequence: sequence.esConsecutivo,
                prefix: sequence.codigoZona,
                sequence: sequence.consecutivo.toString(),
                suggested_sequence: sequence.consecutivo.toString(),
            }));
        }
    }

    return currentSequences;
}

const generateCsv = async (payload) => {
    let response = await clienteAxios(
        `${API_BASE_URL}${API_INVOICES_CONTEXT}export-invoices`,
        payload,
        null,
        "POST");
    response = response.data;

    console.log(response);
    if (response.status === 200) {
        if (response.data) {
            if (response.data.cantidadFacturas > 0) {
                return {
                    url: response.data.url,
                    invoicesCount: response.data.cantidadFacturas,
                }
            }
        }
    } else if (response.status === 450) {
        throw new Error("No existen facturas en estado revisado disponibles para exportar");
    } else if (response.status >= 400 && response.status < 500) {
        throw new Error(response.userMessage);
    }

    throw new Error("Ocurrió un error al generar el archivo.");
}

export {getInvoiceData, getCurrentSequences, generateCsv};

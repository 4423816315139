import React from 'react';
import Proptypes from 'prop-types';
import './images.scss';
const Images = ({ Imagen, alt, ...otherImagesProps}) => (
    <div className="Images">
      <img {...otherImagesProps} src={Imagen} alt={alt} />
    </div>
  );
Images.defaultProps = {
    alt: '',
}
Images.propTypes = {
    alt: Proptypes.string,
    Imagen: Proptypes.string,
}
export default Images;
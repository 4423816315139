import React from 'react';
import Proptypes from 'prop-types';
import './radioButton.scss'

const RadioButton = ({ name, value, label, onChange, selected, disabled }) => (
    
    <p>
        <label className="custom-radio-checkbox">
        {value === selected ? 
            <input required className="custom-radio-checkbox__input" onChange={onChange} type="radio" name={name} value={value} id={label} checked disabled={disabled} />:
            <input required className="custom-radio-checkbox__input" onChange={onChange} type="radio" name={name} value={value} id={label} disabled={disabled} />

        }
            <span className="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
            <span className="custom-radio-checkbox__text">{label}</span>
        </label>
    </p>
);
RadioButton.propTypes = {
    name: Proptypes.string,
    value: Proptypes.string,
    label: Proptypes.string,
    onChange: Proptypes.func,
    selected: Proptypes.string
}    
export default RadioButton;
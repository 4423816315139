import { React, useState, useEffect } from 'react';
import Proptypes from "prop-types";
import './MainSelect.scss';
import { useTranslation } from "react-i18next";
import expandMore from '../../../images/expand-more.svg'
import TranslationComponentConstants from '../../../constants/translation-language-constants';
import mainSelectConstants from '../../../constants/main-select-constants';

const MainSelect = ({ options, onChange, ...props }) => {
    const translationConstants = TranslationComponentConstants;
    const constants = mainSelectConstants;
    const [translate] = useTranslation(translationConstants.USE_TRANSLATION_GLOBAL_VALUE);
    const [showOptions, setShowOptions] = useState(false);

    const handleIconClick = () => {
        setShowOptions(!showOptions);
    };

    const setPagination = (value) => {
        onChange(value);
        setShowOptions(false);
    }

    const handleOutsideClick = (event) => {
        if (event.target.closest(constants.SELECT_CONTAINER_CLASS)) return;
        setShowOptions(false);
    };

    useEffect(() => {
        document.addEventListener(constants.MOUSE_DOWN_EVENT, handleOutsideClick);
        return () => {
            document.removeEventListener(constants.MOUSE_DOWN_EVENT, handleOutsideClick);
        };
    }, []);

    return (
        <div className={"select-container"}>
            <span className='select-pages'>{props.value}</span>
            <span className='select-pages'>{translate('components.table.resultsPerPage')}</span>
            {showOptions && (
                <div className={"select-options"}>
                    {options.map((option, index) => (
                        <button
                            key={index}
                            className={`select-option ${props.value === option.value ? 'selected' : ''}`}
                            onClick={() => setPagination(option.value)}
                            disabled={props.disabled ? props.disabled : false}
                            required={props.required ? props.required : false}
                        >
                            {option.label}
                        </button>
                    ))}
                </div>
            )}
            <div className={"select-icon"} onClick={handleIconClick}>
                <img src={expandMore} alt={"Ver opciones"} />
            </div>
        </div>
    );
}

MainSelect.propTypes = {
    label: Proptypes.string,
    name: Proptypes.string,
    onChange: Proptypes.func,
};

export default MainSelect;

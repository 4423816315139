import React from 'react'
import './headerClient.scss';
function HeaderClient({ title, description }) {
    return (
        <div className="primary">
            <div className="title__content">
                <h1>{title}</h1>
            </div>
            <p className="information__description">{description}</p>
            <hr />
        </div>
    );
}

export default HeaderClient;

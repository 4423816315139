import { API_BASE_URL, API_POLICY_CONTEXT } from "../utils/constans";
import clienteAxios from "../api/axios";

const getPolicyTypes = async () => {
    let responseList = [];

    await clienteAxios(`${API_BASE_URL}${API_POLICY_CONTEXT}tipo-poliza`, null, null, "GET").then((response) => {
        responseList = response.data.data;
    }).catch((err) => {
        console.log(err);
    });
    return responseList;
};

const createPolicyRequest = async (data) => {
    return await clienteAxios(`${API_BASE_URL}${API_POLICY_CONTEXT}policy`, data, null, "POST");
};

const listPolicyRequest = async (data) => {
    let response;
    await clienteAxios(`${API_BASE_URL}${API_POLICY_CONTEXT}list`, data, null, "POST").then(res => {
        response = res.data;
    }).catch(error => {
        console.error("Error from service", error);
    });
    return response;
}

const getPolicyById = async (data) => {
    return await clienteAxios(`${API_BASE_URL}${API_POLICY_CONTEXT}find-by-id`, data, null, "POST")
};

const updatePolicy = async (data) => {
    return await clienteAxios(`${API_BASE_URL}${API_POLICY_CONTEXT}policy/approver`, data, null, "PUT")
}
export { createPolicyRequest, getPolicyTypes, getPolicyById, listPolicyRequest, updatePolicy };

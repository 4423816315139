const PolicyRelationsConstants = {
  GLOBAL: "global",
  EMPTY: "",

  NUMBER_MINUS_ONE_STRING: "-1",

  NUMBER_ZERO: 0,

  TRUE: true,
  FALSE: false,

  BRANDS: "marcas",
  FILIAL_ID: "filialId",
  BUSINESS: "negocios",

  ROLE_ADMIN: "Administrador",
  ROLE_APPROVER: "Aprobador",

  TYPE_ADD: "AGREGAR",

  STATUS_200: 200,

  FIVE_VALUE: 5

};

export default PolicyRelationsConstants
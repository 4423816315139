import axios from "axios";
import { getAxiosHeaders } from "../utils/utils";

import {
    NETWORK_ERROR,
    OFFLINE
} from '../utils/constans';

axios.interceptors.request.use(async (req) => {
    req.headers = {
        ...req.headers,
        ...await getAxiosHeaders()
    };

    return req;
});

const setErrorHandler = (callback = () => { }) => {
    axios.interceptors.response.use(res => res, error => {
        if (error.response) {
            callback(error.response.status);
        }
        else if (error.message.includes(NETWORK_ERROR)) {
            callback(OFFLINE);
        } else {
            callback(500);
        }
    });
}

const clienteAxios = async (url, data, headers = {}, method) => {
    return await axios({
        method,
        url,
        data,
        headers: {
            ...headers
        }
    })
};

export { setErrorHandler };
export default clienteAxios;

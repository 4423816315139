/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';

import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Table from '../../molecules/table/Table';
import useLoader from '../../../utils/useLoader';
import Toolkit from "../../atoms/toolkit/Toolkit";
import MainSelect from '../../atoms/main-select/MainSelect';
import { informationAlert } from '../../molecules/alert/alert';
import { AppContextUser } from '../../../provider/userInSession';
import { getInvoiceData } from '../../../services/invoiceService';
import InputSearchSet from '../../molecules/input-search-set/InputSearchSet';
import TablePagination from '../../molecules/table-pagination/TablePagination';
import invoiceStateTableConstants from '../../../constants/invoice-state-table-constants';
import './InvoiceStateTable.scss';

const InvoiceStateTable = (props) => {

    const constants = invoiceStateTableConstants;

    const history = useHistory();
    const { dataUser } = useContext(AppContextUser);
    const [translate] = useTranslation(constants.GLOBAL);
    const [loader, showLoader, hideLoader] = useLoader();

    const [estados, setEstados] = useState([]);
    const [estadosPropios, setEstadosPropios] = useState([]);
    const [dataListInvoice, setDataListInvoice] = useState([]);
    const [requestsPerPage, setRequestsPerPage] = useState(constants.REQUESTS_INITIAL_STATE_TWENTY);
    const [pageAmount, setPageAmount] = useState(constants.ONE_VALUE_INITIALIZER);
    const [currentPage, setCurrentPage] = useState(constants.ONE_VALUE_INITIALIZER);
    const [searchItem, setSearchItem] = useState(constants.EMPTY_STRING_INITIALIZER);
    const [orderBy, setorderBy] = useState({ nameColumn: constants.DATE_REQUEST, orderAsc: false });

    useEffect(() => { if (window.location.search !== constants.EMPTY_STRING_COMPARATOR) separatePastParams(window.location.search) }, [])

    useEffect(() => {
        if (dataUser.rol === constants.SOLICITANTE_ROL || dataUser.rol === constants.SUPERVISOR_ROL) {
            if (!estados.length)
                setEstados([constants.NULL_STATUS, constants.APPROVED_STATUS, constants.CANCELLED_STATUS]);
        } else {
            if (!estados.length) {
                setEstados([constants.NULL_STATUS, constants.APPROVED_STATUS]);
                setEstadosPropios([constants.CANCELLED_STATUS]);
            }
        }

        if (estados.length > constants.ZERO_VALUE) {
            const dataSend = {
                usuarioId: dataUser.userId,
                lista: [{}],
                estados: estados,
                estadosPropios: estadosPropios,
                nombreRol: dataUser.rol,
                numeroPagina: (currentPage - constants.ONE_VALUE),
                peticionesPorPagina: requestsPerPage,
                busqueda: searchItem,
                nombreColumna: orderBy.nameColumn,
                orden: orderBy.orderAsc
            }

            if (dataSend.nombreRol) {
                showLoader();
                const response = getInvoiceData(dataSend);
                response.then(resp => {
                    hideLoader();
                    if (resp.status && resp.status === constants.STATUS_CODE_200) {
                        setPageAmount(resp.data.cantidadPaginasTotales);
                        let list = findFilialName(resp.data.lista);
                        setDataListInvoice(list);
                    } else {
                        informationAlert(constants.ERROR_LABEL, translate('alerts.oops'), resp.userMessage);
                        setDataListInvoice([]);
                    }
                }).catch(err => {
                    console.error(err)
                });
            }
        }
    }, [currentPage, requestsPerPage, dataUser, orderBy, searchItem, estados]);

    const selectValues = [
        { value: constants.TEN_ROWS, label: `${constants.TEN_ROWS_LABEL} ${translate('components.table.resultsPerPage')}` },
        { value: constants.TWENTY_ROWS, label: `${constants.TWENTY_ROWS} ${translate('components.table.resultsPerPage')}` },
        { value: constants.FIFTY_ROWS, label: `${constants.FIFTY_ROWS} ${translate('components.table.resultsPerPage')}` }
    ];

    const headingColumns = [
        { key: constants.FAC_REQUEST, val: translate('invoices.pendingInvoice.requestNumber'), width: constants.COLUMN_WIDTH_6 },
        { key: constants.USER_EMAIL, val: translate('invoices.pendingInvoice.user'), width: constants.COLUMN_WIDTH_11 },
        { key: constants.DATE_REQUEST, val: translate('invoices.pendingInvoice.requestDate'), width: constants.COLUMN_WIDTH_8 },
        { key: constants.LIMIT_DATE, val: translate('invoices.pendingInvoice.deadline'), width: constants.COLUMN_WIDTH_8 },
        {
            key: constants.DOC_ID, val: translate('invoices.pendingInvoice.nit'), width: constants.COLUMN_WIDTH_9, type: constants.TEXT_TYPE,
            renderAs: [constants.RENDER.PERCENTAGE, constants.DOC_ID, constants.RENDER.VERIFICATION]
        },
        { key: constants.CLIENT_NAME, val: translate('invoices.pendingInvoice.customer'), width: constants.COLUMN_WIDTH_14 },
        {
            key: constants.TOTAL_VALUE, val: translate('invoices.pendingInvoice.amount'), width: constants.COLUMN_WIDTH_10,
            align: constants.ALIGN_RIGHT, type: constants.CURRENCY, currencyTypeFormatter: constants.CURRENCY_TYPE
        },
        { key: constants.STATUS_VALUE, val: translate('invoices.pendingInvoice.state'), width: constants.COLUMN_WIDTH_8, textTransform: constants.CAPITALIZE },
        { key: constants.CONSECUTIVE_VALUE, val: translate('invoices.pendingInvoice.consecutive'), width: constants.COLUMN_WIDTH_9 },
        { key: constants.DATE_FAC, val: translate('invoices.pendingInvoice.billingDate'), width: constants.COLUMN_WIDTH_8 },
        { key: constants.FILIAL_NAME, val: translate('invoices.pendingInvoice.subsidiary'), width: constants.COLUMN_WIDTH_9 },
    ];

    const handleOnclick = (newPage) => {
        if (newPage >= constants.ONE_VALUE && newPage <= pageAmount) setCurrentPage(newPage);
    }

    const handleOnChangeSearch = (event) => setSearchItem(event.target.value);

    const handleOnchange = (requestPerPage) => {
        setRequestsPerPage(requestPerPage);
        setCurrentPage(constants.ONE_VALUE);
    }

    const handleOnclickDataHeading = (nameTableColumn) => {
        let orderByVal = nameTableColumn === orderBy.nameColumn ? !orderBy.orderAsc : true;
        setorderBy({ nameColumn: nameTableColumn, orderAsc: orderByVal });
    };

    const handleOnItemClickListener = (row) => {
        const url = `${constants.FILTER_PARAM}${searchItem}${constants.PAGE_PARAM}${currentPage}${constants.REQUEST_PARAM}
            ${requestsPerPage}${constants.ORDER_BY_PARAM}${orderBy.nameColumn}${constants.ORDER_PARAM}${orderBy.orderAsc}`;
        window.history.replaceState(null, null, url);
        separatePastParams(url);
        history.push(constants.INVOICE_PATH + row.facSolicitudId);
    };

    const findFilialName = (listInvoices) => {
        listInvoices.forEach(item => {
            let filialId = +item.filialId;
            let filial = props.filiales.find(filial => filial.code === filialId);
            item[constants.FILIAL_NAME] = filial.name;
        });
        return listInvoices
    }

    const separatePastParams = (url) => {
        const search = url.split(constants.EQUALS);
        const requestBurned = (search[constants.FOURTH_INDEX_POSITION].split(constants.AMPERSAND))[constants.FIRST_INDEX_POSITION];

        const filtro = (search[constants.SECOND_INDEX_POSITION].split(constants.AMPERSAND))[constants.FIRST_INDEX_POSITION];
        const page = (search[constants.THIRD_INDEX_POSITION].split(constants.AMPERSAND))[constants.FIRST_INDEX_POSITION];
        const requests = Number.isNaN(requestBurned) ? requestBurned : constants.REQUESTS_INITIAL_STATE_TWENTY
        const columnSearched = (search[constants.FIFTH_INDEX_POSITION].split(constants.AMPERSAND))[constants.FIRST_INDEX_POSITION];
        const orderSearched = search[constants.SIXTH_INDEX_POSITION];

        setRequestsPerPage(requests);
        setSearchItem(filtro);
        setCurrentPage(parseInt(page));
        setorderBy({ nameColumn: columnSearched.toString(), orderAsc: orderSearched === constants.TRUE });
    }

    return (
        <>  <div className="o-invoice__header">
                <Toolkit>
                    <InputSearchSet
                        type={constants.TEXT_TYPE}
                        placeholder={translate('transversal.search')}
                        value={searchItem}
                        onChange={handleOnChangeSearch}
                    />
                </Toolkit>
            </div>
            <div className='invoice-table'>
                <Table
                    tableData={dataListInvoice}
                    headingColums={headingColumns}
                    title={null}
                    breakOn='medium'
                    orderBy={handleOnclickDataHeading}
                    orderColumnName={orderBy.nameColumn}
                    orderColumnDirection={orderBy.orderAsc}
                    onItemClickListener={handleOnItemClickListener}
                />
            </div>
            
            <div className="o-footer__table">
                <MainSelect
                    options={selectValues}
                    name={`invoicetable`}
                    value={requestsPerPage}
                    onChange={handleOnchange}
                />
                <TablePagination
                    paginate={(e, newPage) => handleOnclick(newPage)}
                    pageAmount={pageAmount}
                    currentPage={currentPage}
                />
            </div>
            {loader}
        </>
    );
};

export default InvoiceStateTable;

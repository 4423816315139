import React from 'react';
import HeaderWrapper from '../../molecules/header-wrapper/HeaderWrapper';

const HeaderInvoiceRequest = (props) => {
    let mainTitle = props.title;
    let description = props.description;

    return (
        <HeaderWrapper
            mainTitle={mainTitle}
            description={description}
        />
    )
}

export default HeaderInvoiceRequest;

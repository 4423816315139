const InputFileConstants = {

  EMPTY_STRING: '',

  NUMBERS: {
    ONE: 1
  },

  GRAY_DISABLED: "#808080",
  PURPLE_ENABLED: "#6f2fd3",

}

export default InputFileConstants
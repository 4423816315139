import {API_BASE_URL, API_PARAMETERS_CONTEXT} from "../utils/constans";
import clienteAxios from "../api/axios";

const getFiliales = async () => {
    let result = [];
    await clienteAxios(`${API_BASE_URL}${API_PARAMETERS_CONTEXT}filiales/filiales-list`, null, null, "GET")
        .then(respuesta => {
            result = respuesta.data.data.slice();
        }).catch(
            err => console.log(err)
        )
    return result;
}

const getNovedades = async () => {
    let result = [];
    await clienteAxios(`${API_BASE_URL}${API_PARAMETERS_CONTEXT}novedades/list`, null, null, "GET")
        .then(respuesta => {
            result = respuesta.data;
        }).catch(
            err => console.log(err)
        )
    return result;
}

export { 
    getFiliales,
    getNovedades
};

import InputFile from '../input-file/inputFile';
import ListFiles from '../list-files/listFiles';
import { useTranslation } from "react-i18next";
import './inputFileBlock.scss';

const InputFileBlock = ({ status='view', onChange, data, limitFiles, removeFile, accept, title, required, download, name, disabled }) => {
    const isUpload = () => status == 'upload';
    const isView = () => status == 'view';
    const [t] = useTranslation("global");

    return (
        <div className={`container-input-file-block ${isView() ? 'view' : ''}`} >
            {isUpload() && 
                <InputFile
                    onChange={onChange}
                    data={data}
                    removeFile={removeFile}
                    accept={accept}
                    title={title}
                    required={required}
                    download={download}
                    name={name}
                    disabled={disabled}
                    message={t('components.inputFile.dropDocuments')}
                    limitFiles={limitFiles}
                />
            }
            
            {isView() && 
                <ListFiles
                    className="listFiles"
                    dashed
                    onChange={onChange}
                    data={data}
                    accept={accept}
                    title={title}
                    required={required}
                    download={download}
                    name={name}
                    disabled={true}
                    message={t('components.inputFile.requesterDocuments')}
                    view={true}
                />
            }

            {isUpload() && <p className="input-file-block">{title}</p>}
        </div>
    );
}

export default InputFileBlock;
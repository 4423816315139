import Proptypes from 'prop-types';
import './dialog.scss'
import { PdsButton } from '@pragma-dev-kit/pragma-ds-react';

const Dialog = ({
                    title,
                    visible,
                    children,
                    positiveText,
                    positiveListener,
                    negativeText,
                    negativeListener,
                    overlayListener
                }) => {

    if (visible) {
        return (
            <div className={"dialog-overlay"} onClick={overlayListener}>
                <div className={"dialog-container"} onClick={(event) => { event.stopPropagation() }}>
                    <div className={"dialog-title"}><h3>{title}</h3></div>
                    <div className={"dialog-content"}>{children}</div>
                    <div className={"dialog-buttons"}>
                        { positiveText != null && <PdsButton onClick={positiveListener} name={positiveText} variant='primary'>{positiveText}</PdsButton> }
                        { negativeText != null && <PdsButton onClick={negativeListener} name={negativeText} variant='secondary'>{negativeText}</PdsButton> }
                    </div>
                </div>
            </div>
        );
    };
    return (<div/>);
}

Dialog.defaultProps = {}

Dialog.propTypes = {
    positiveText: Proptypes.string,
    negativeText: Proptypes.string,
    positiveListener: Proptypes.func,
    negativeListener: Proptypes.func,
    overlayListener: Proptypes.func,
    visible: Proptypes.bool,
    title: Proptypes.string
}

export default Dialog;

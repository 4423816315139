import Button from '../../atoms/buttons/button';
import { useEffect, useState, useContext } from "react";
import NotificationsIcon from '../../../images/notification-icon.svg';
import Close from '../../../images/close-purple-icon.svg';
import Novelty from '../../molecules/novelty/novelty';
import { AppContextUser } from "../../../provider/userInSession";
import './notifications.scss';
import { useCookies } from 'react-cookie';
import { getNovedades } from '../../../services/parametersService';
import { useTranslation } from "react-i18next"
import NotificationsComponentConstants from '../../../constants/notifications-constants';

const Notifications = ({display, setDisplay, ...otherProps}) => {
    const constants = NotificationsComponentConstants;
    const { dataUser } = useContext(AppContextUser);
    const [cookies, setCookie] = useCookies([constants.NOVEDAD_KEY]);
    const [novedades, setNovedades] = useState();
    const [creacion, setCreacion] = useState(constants.EMPTY_STRING_VALUE);

    let hoy = new Date();
    let limitDate;

    async function updateNovedades() {
        await getNovedades().then(response => {
            setNovedades(response.datas);
        }).catch(error => {
            setNovedades([]);
        })
    }

    useEffect(() => {
        
        if(dataUser?.userName){
            updateNovedades()
        }
        
        if (!cookies.novedad) {
            setCookie(constants.NOVEDAD_KEY, { value: true, fecha: creacion }, { path: constants.ROOT_PATH });
        }
    }, []);
    
    useEffect(() => {
        if (novedades && novedades[constants.FIRST_INDEX_POSITION]) {
            setCreacion(new Date(novedades[constants.FIRST_INDEX_POSITION].fechaCreacion));
            limitDate = new Date(novedades[constants.FIRST_INDEX_POSITION].fechaCreacion);
            limitDate.setDate(limitDate.getDate() + constants.LIMIT_DAYS);
        }

        if(limitDate < hoy){
            setCookie(constants.NOVEDAD_KEY, { value: true, fecha: cookies.novedad.fecha }, { path: constants.ROOT_PATH });
        }

    }, [novedades])
    
    const handleToggle = () => {
        if(cookies.novedad.value){
            setCookie(constants.NOVEDAD_KEY, { value: false, fecha: cookies.novedad.fecha }, { path: constants.ROOT_PATH });
        }
        setDisplay(!display);
    }

    const [t] = useTranslation(constants.USE_TRANSLATION_GLOBAL_VALUE);

    return (
        <div className="notifications">
            <div className={display? 'showNotifications': ''}>
                <Button 
                        className="testButton"
                        onClick={handleToggle} 
                        icon={NotificationsIcon}
                        borderless 
                        reverseIcon
                        noHover
                />
            </div>
            <aside className="notificationsAside">
                <div className="closeNotifications" onClick={handleToggle}>
                    <Button borderless noHover icon={Close} onClick={handleToggle}/>
                </div>
                <div className="notificationsContent">
                    {novedades && novedades.length > constants.ZERO_LENGTH ? novedades.map((novedad) => { 
                    return <Novelty data={novedad}/>
                    }) : <p className='empty-notification'>{t('notifications.noNotifications')}</p>}
                </div>
            </aside> 
        </div>
    )
}

export default Notifications;
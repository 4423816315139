/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsconfig = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_TwsywfrID",
    "aws_user_pools_web_client_id": "59rti0149slhivcvt3gf1q152c",
    "oauth": {
        "domain": "pragma-perfil.auth.us-east-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "d3jvm6ee97l28q.cloudfront.net/cv",
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": window.location.origin + "/login",
        "redirectSignOut": window.location.origin + "/logout",
        // "redirectSignIn": "https://d3d89meuv2noqy.cloudfront.net/login",
        // "redirectSignOut": "https://d3d89meuv2noqy.cloudfront.net/logout",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "cookieStorage": {
        "domain": window.location.hostname,
        "secure": false,
        "path": "/",
        "expires": 365
    }
};

export default awsconfig;

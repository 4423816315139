const clientsTableConstants = {
    EMPTY_STRING: "",
    ERROR_LABEL: 'error',
    TRUE_STRING: 'true',

    EQUALS: "=",
    AMPERSAND: "&",
    
    ZERO_VALUE: 0,
    ONE_VALUE: 1,
    TWO_VALUE: 2,
    THREE_VALUE: 3,
    FOUR_VALUE: 4,
    FIVE_VALUE: 5,
    TWENTY_VALUE: 20,

    TEN_ROWS: 10,
    TWENTY_ROWS: 20,
    FIFTY_ROWS: 50,

    NAME_COLUMN_KEY: 'nombre',
    DOCUMENT_NUMBER_COLUMN_KEY: 'numeroDocumento',
    ADDRESS_COLUMN_KEY: 'direccion',
    PHONE_COLUMN_KEY: 'telefono',
    STATE_COLUMN_KEY: 'estado',

    KEYWORD_PARAM: '?keyword=',
    PAGE_NO_PARAM: '&pageNo=',
    PAGE_SIZE_PARAM: '&pageSize=',
    COLUMN_NAME_PARAM: '&columnName=',
    SORT_PARAM: '&sort=',

    CLIENT_PATH: "/cliente/",
};

export default clientsTableConstants;
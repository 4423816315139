const PaymentConditionsConstants = {

  GLOBAL: "global",
  EMPTY: "",
  ELEMENT_A: "a",

  TRUE: true,
  FALSE: false,

  ALERT_WARNING: "warning",
  ALERT_SUCCES: "success",
  ALERT_ERROR: "error",

  NUMBER_MINUS_ONE: -1,
  NUMBER_ZERO: 0,
  NUMBER_ONE: 1,
  NUMBER_TWO: 2,

  NUMBER_ZERO_STRING: "0",
  NUMBER_MINUS_ONE_STRING: "-1",

  NUMBER_THIRTY_DAYS: 30,
  NUMBER_SIXTY_DAYS: 60,
  NUMBER_THIRTY_DAYS_TEXT: "30",
  NUMBER_SIXTY_DAYS_TEXT: "60",

  TYPE_ADD_FILE: "ADD-FILE",
  TYPE_ADD: "AGREGAR",

  DISABLED: "disabled",

  FILES: "files",
  OBJECT: "object",

  COMMA_SEPARATOR: ",",
  POINT_SEPARATOR: ".",

  STATUS_200: 200,

  EXTENSIONS : {
    JPG: "jpg",
    PNG: "png",
    PDF: "pdf",
    JPEG: "jpeg"
  }

};

export default PaymentConditionsConstants
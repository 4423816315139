import React from 'react';
import Proptypes from "prop-types";
import './InputSearch.scss';
import SearchIcon from '../../../images/buscar.svg'

const InputSearch = ({type, onChange, placeholder, value, ...props}) => {
    return (
        <div className={"searchContainer"}>
            <input
                className="a-inputSearch"
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            />
            <div className={"searchIconContainer"}>
                <img className={"searchIcon"} src={SearchIcon} alt={placeholder}/>
            </div>
        </div>
    );
}

InputSearch.propTypes = {
    type: Proptypes.string,
    placeholder: Proptypes.string,
    value: Proptypes.string,
    onChange: Proptypes.func,
};

export default InputSearch;

import Proptypes from "prop-types";

import Images from "../images/images";

import selectIcon from "../../../images/icon-drop_down.svg";
import selectIconDisabled from "../../../images/icon-drop_down_disabled.svg";
import "./select.scss";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import TranslationComponentConstants from "../../../constants/translation-language-constants";
import SelectConstants from "../../../constants/select-constants";


const constants = SelectConstants
const renderData = (data, isBrand, handleSelectChange) => {
  const translateConstants = TranslationComponentConstants;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [translate, i18n] = useTranslation(translateConstants.USE_TRANSLATION_GLOBAL_VALUE);

  if (data && data.length > constants.NUMBERS.ZERO) {
    const orderData = data.sort(orderItems);
    return orderData.map((item) => (
      <li
        className="select__option"
        key={item.code || item.id || item.sedeId || item.roleId}
        data-value={(item.code || item.id || item.sedeId || item.roleId).toString()}
        onClick={() => handleSelectChange((item.code || item.id || item.sedeId || item.roleId).toString())}
      >
        {i18n.language === translateConstants.LANGUAGE_SPANISH_MODE ? item.name || item.nombre : item.englishName || item.name}
      </li>
    ));
  } else if (isBrand) {
    return (
      <li key={constants.NUMBERS.ZERO} data-value={constants.NUMBERS.ZERO} className="select__option">
        {translate('invoices.createInvoice.noBrandOption')}
      </li>
    );
  } else {
    return [];
  }
};

const orderItems = (a, b) => {
  if (a.name > b.name) return constants.NUMBERS.ONE;
  if (a.name < b.name) return constants.NUMBERS.MINUS_ONE;
  return constants.NUMBERS.ZERO;
};

const Select = ({
  code,
  messageDisabled,
  data,
  label,
  name,
  onChange,
  disabled,
  value,
  isBrand,
  hoverHint,
  required,
  placeholder,
  className
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectChange = (value) => {
    const event = {
      target: {
        name,
        value
      }
    };
    onChange(event);
    setIsOpen(false);
  };

  return (
    <div className={`select ${required ? `select-disabled` : ``} ${disabled ? `dropdown-disabled` : ``} ${className ? className : ''}`}>
      <span className={`${value && "shrink"} title`}>{label}</span>
      <div className={`select__dropdown ${isOpen ? 'open' : ''}`} onClick={() => !disabled && setIsOpen(!isOpen)}>
        <span className="select__placeholder">
          {value ? data.find(item => item.code == value || item.id == value || item.sedeId == value || item.roleId == value)?.name : (disabled ? messageDisabled : placeholder)}
        </span>
        <ul className={`select__list ${disabled ? 'disabled' : ''}`} required={required} data-value={value || constants.NUMBERS.ZERO}>
          {hoverHint && <li className="select__option"></li>}
          {!hoverHint &&
            <li key="0" data-value="0" className="select__option" hidden>{disabled ? messageDisabled : placeholder}</li>
          }
          {renderData(data, isBrand, handleSelectChange)}
        </ul>
      </div>
        <Images Imagen={disabled ? selectIconDisabled : selectIcon} className={`Images ${isOpen ? 'rotate' : ''}`} />
    </div>
  );
};
Select.propTypes = {
  label: Proptypes.string,
  name: Proptypes.string,
  onChange: Proptypes.func,
  hoverHint: Proptypes.string,
};
export default Select;

const HeaderConstants = {
  EMPTY: "",

  TRUE: true,
  FALSE: false,

  NUMBER_ONE: 1,
  NUMBER_FIVE: 5,
  NUMBER_SIX: 6,
  NUMBER_EIGHT: 8,
};

export default HeaderConstants
import clienteAxios from '../api/axios'
import { BASE_URL } from '../constants/api-constants';
import { API_BASE_URL, API_PARAMETERS_CONTEXT} from "../utils/constans";

export const getAllCities = async (data) => {
    let result = [];
    await clienteAxios(`${API_BASE_URL}${API_PARAMETERS_CONTEXT}ciudades/all${data}`, null, null, 'GET')
        .then(respuesta => {
            result = respuesta.data.datas;
        }).catch(
            err => console.log(err)
        )
    return result
}

export const updateCity = async (data) => {
    return await clienteAxios(`${BASE_URL}${API_PARAMETERS_CONTEXT}ciudades`, data, null, 'POST');
}

export const getCityById = async (cityCode) => {
    let result = {};
    await clienteAxios(`${BASE_URL}${API_PARAMETERS_CONTEXT}ciudades/${cityCode}`, null, null, 'GET')
        .then(response => {
            result = response.data.datas;
        }).catch(err => {
            result = err;
        });
    return result;
}

export const getCitiesByStateId = async (stateId) => {
    let result = {};
    await clienteAxios(`${BASE_URL}${API_PARAMETERS_CONTEXT}ciudades?stateId=${stateId}`, null, null, 'GET')
        .then(response => {
            result = response.data.datas;
        }).catch(err => {
            result = err;
        })
    return result;
}

export const getAllStates = async (data) => {
    let result = [];
    await clienteAxios(`${API_BASE_URL}${API_PARAMETERS_CONTEXT}departamentos/all${data}`, null, null, 'GET')
        .then(respuesta => {
            result = respuesta.data.datas;
        }).catch(
            err => console.log(err)
        )
    return result
}

export const updateState = async (data) => {
    return await clienteAxios(`${BASE_URL}${API_PARAMETERS_CONTEXT}departamentos`, data, null, 'PUT');
}

export const saveState = async (data) => {
    return await clienteAxios(`${BASE_URL}${API_PARAMETERS_CONTEXT}departamentos`, data, null, 'POST');
}

export const getStatesByCountryId = async (paisId) => {
    let result = {};
    await clienteAxios(`${BASE_URL}${API_PARAMETERS_CONTEXT}departamentos?paisId=${paisId}`, null, null, 'GET')
        .then(response => {
            result = response.data.datas;
        }).catch(err => {
            result = err;
        })
    return result;
}

export const getAllCountriesPaginated = async (data) => {
    let result = [];
    await clienteAxios(`${API_BASE_URL}${API_PARAMETERS_CONTEXT}paises/all${data}`, null, null, 'GET')
        .then(respuesta => {
            result = respuesta.data.datas;
        }).catch(
            err => console.log(err)
        )
    return result
}

export const updateCountry = async (data) => {
    return await clienteAxios(`${BASE_URL}${API_PARAMETERS_CONTEXT}paises`, data, null, 'POST');
}

export const getAllCountries = async () => {
    let result = [];
    await clienteAxios(`${API_BASE_URL}${API_PARAMETERS_CONTEXT}paises`, null, null, 'GET')
        .then(respuesta => {
            result = respuesta.data.datas;
        }).catch(
            err => console.log(err)
        );
    return result;
}
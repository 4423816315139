import { useState, useContext } from 'react';

import Images from '../../atoms/images/images';
import FooterAside from '../../molecules/footer_aside/footer_aside';

import pragmaLogo from '../../../images/pragma-logo.svg';
import seikoLogo from '../../../images/seiko-logo.svg';

import adminIcon from '../../../images/settings-icon.svg';
import customerIcon from '../../../images/user-icon.svg';
import billIcon from '../../../images/clipboard-icon.svg';
import policyIcon from '../../../images/folder-icon.svg';

import NavBar from '../../../images/menu.svg';
import Close from '../../../images/ico-close.svg';

import SubMenu from '../../organism/submenu/submenu';
import { AppContextUser } from '../../../provider/userInSession';


import './aside.scss';
import ExpandMore from '../../../images/expand-more.svg'
import ExpandLess from '../../../images/expand-less.svg'
import { useHistory } from 'react-router';
import { useTranslation } from "react-i18next";
import asideComponentConstants from '../../../constants/aside-constants';


const Aside = ({blur, setBlur}) => {
  const constants = asideComponentConstants;

    const [display, setDisplay] = useState(false);
    const [t] = useTranslation(constants.USE_TRANSLATION_GLOBAL_VALUE);
    const SidebarData = [
        {
          title: t('administrator.title'),    
          path: constants.HASH_PATH,
          iconClosed: ExpandMore,
          iconOpened: ExpandLess,
          iconPath: adminIcon,
          user: constants.ADMIN_USER,      
          subNav: [
            {
              title: t('administrator.brands.title'),
              path: constants.BRANDS_PATH,        
            },
            {
              title: t('administrator.createBrand.title'),
              path: constants.CREATE_BRAND_PATH,        
            },
            {
              title: t('administrator.users.title'),
              path: constants.USERS_PATH,        
            },
            {
              title: t('administrator.manageCities.title'),
              path: constants.MANAGE_CITIES_PATH,        
            },
            {
              title: t('administrator.manageStates.title'),
              path: constants.MANAGE_STATES_PATH,        
            },
            {
              title: t('administrator.manageCountries.title'),
              path: constants.MANAGE_COUNTRIES_PATH,        
            },
          ]
        },
        {
          title: t('customers.customers.title'),    
          path: constants.HASH_PATH,
          iconClosed: ExpandMore,
          iconOpened: ExpandLess,
          iconPath: customerIcon,
          user: constants.EMPTY_USER,
          subNav: [
            {
              title: t('customers.customers.title'),
              path: constants.CUSTOMERS_PATH,        
            },
            {
              title: t('customers.createCustomer.title'),
              path: constants.CREATE_CUSTOMER_PATH,        
            }
          ]
        },
        {
          title: t('invoices.title'),
          path: constants.HASH_PATH,    
          iconClosed: ExpandMore,
          iconOpened: ExpandLess,
          iconPath: billIcon,
          user: constants.EMPTY_USER,
          subNav: [
            {
              title: t('invoices.createInvoice.title'),
              path: constants.INVOICE_PATH,        
            },
            {
              title: t('invoices.pendingInvoice.title'),
              path: constants.PENDING_INVOICES_PATH,        
            },
            {
              title: t('invoices.historicInvoice.title'),
              path: constants.HISTORY_INVOICES_PATH,        
            }
          ]
        },
        {
          title: t('policy.title'),    
          path: constants.HASH_PATH,
          iconClosed: ExpandMore,
          iconOpened: ExpandLess,
          iconPath: policyIcon,
          user: constants.EMPTY_USER,
          subNav: [
            {
              title: t('policy.requestPolicy.title'),
              path: constants.POLICY_PATH,        
            },
            {
              title: t('policy.pendingPolicy.title'),
              path: constants.PENDING_POLICIES_PATH,        
            },
            {
              title: t('policy.historicPolicy.title'),
              path: constants.HISTORY_POLICIES_PATH,        
            }
          ]
        }
      ];
    const handleTogle = () => {
        setDisplay(!display);
        setBlur(!blur)
    };
    const { dataUser } = useContext(AppContextUser);
    const [activeLink, setActiveLink] = useState(constants.EMPTY_STRING_VALUE);
    const history = useHistory();

    const goToHome = () => {
        history.push(constants.ROOT_PATH);
    }

    return (
        <div className="aside">
            <div onClick={handleTogle}  className={`content-button ${display ? 'content-button-open' : ''}`}>
                <button>
                    <Images Imagen={display ? Close : NavBar} />
                </button>
            </div>
            <nav>
                <div className="content-logo-aside">
                    <div className="content-image">
                        <a onClick={goToHome} className="content-image-container">
                          <img src={pragmaLogo}></img>
                          <img src={seikoLogo}></img>
                        </a>
                    </div>
                </div>    
                <div className="items">
                    {SidebarData.map((item, index) => {
                        if(item.user===dataUser.rol || item.user===constants.EMPTY_STRING_VALUE){
                            return <SubMenu item={item} key={index} activeLink={activeLink} setActiveLink={setActiveLink} />;
                        }else{ return null}
                    })}            
                </div>
                <div className="content-footer-aside">
                    <FooterAside />
                </div>
            </nav>
        </div>
    );
}
export default Aside;

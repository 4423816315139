import { useEffect } from 'react';

const useInputValidation = () => {
  useEffect(() => {
    // Función que aplica el MutationObserver a todos los 'pds-input'
    const applyObserverToInputs = () => {
      const inputs = document.querySelectorAll('pds-input'); // Selecciona todos los 'pds-input'

      inputs.forEach((input) => {
        if (input.shadowRoot) {
          const shadowRoot = input.shadowRoot;

          // Callback que se ejecutará cuando haya cambios en el shadowRoot
          const mutationCallback = (mutationsList) => {
            for (const mutation of mutationsList) {
              if (mutation.type === 'childList') {
                // Verifica si el elemento con la clase 'pds-x' fue añadido
                const targetElement = shadowRoot.querySelector('.pds-x');
                if (targetElement) {
                  // Modifica el estilo del elemento cuando es detectado
                  targetElement.style.display = 'inline-block'  // Cambia el color del texto
                }
              }
            }
          };

          // Crea una instancia de MutationObserver y observa el shadowRoot
          const observer = new MutationObserver(mutationCallback);

          // Opciones de configuración para el observer
          const observerConfig = { childList: true, subtree: true };

          // Comienza a observar el shadowRoot
          observer.observe(shadowRoot, observerConfig);

          // Limpia el observer cuando el componente se desmonte
          return () => {
            observer.disconnect();
          };
        }
      });
    };

    // Llama a la función para aplicar el observer a todos los 'pds-input'
    applyObserverToInputs();
  }, []);
};

export default useInputValidation;
import { Fragment, useEffect, useState, useCallback } from 'react';
import { getAllCities, getAllCountries, getStatesByCountryId, updateCity } from '../../../services/getLocationList';
import useLoader from '../../../utils/useLoader';
import { getValueDropdown, validateLocationData } from '../../../utils/utils';
import MainSelect from '../../atoms/main-select/MainSelect';
import { informationAlert } from '../../molecules/alert/alert';
import InputSearchSet from '../../molecules/input-search-set/InputSearchSet';
import TablePagination from '../../molecules/table-pagination/TablePagination';
import Table from '../../molecules/table/Table';
import './manageCities.scss';
import { useTranslation } from "react-i18next";
import manageCitiesConstants from '../../../constants/manage-cities-constants';
import TranslationComponentConstants from '../../../constants/translation-language-constants';
import Toolkit from '../../atoms/toolkit/Toolkit';
import { PdsButton, PdsDropdown, PdsDropdownOption, PdsInput } from '@pragma-dev-kit/pragma-ds-react';
import { validateField } from "../../../utils/utils";


function ManageCities({ props }) {
    const constants = manageCitiesConstants;
    const translationConstants = TranslationComponentConstants;

    let keyword, pageNo, pageSize, sortBy, ascSort;
    const [translate] = useTranslation(translationConstants.USE_TRANSLATION_GLOBAL_VALUE);

    const [loader, showLoader, hideLoader] = useLoader();

    const [tableData, setTableData] = useState([]);

    const [searchItem, setSearchItem] = useState(constants.EMPTY_STRING_INITIAL_STATE);
    const [requestsPerPage, setRequestsPerPage] = useState(constants.REQUESTS_INITIAL_STATE_TWENTY);
    const [currentPage, setCurrentPage] = useState(constants.PAGES_INITIAL_STATE_ONE);
    const [totalPages, setTotalPages] = useState(constants.PAGES_INITIAL_STATE_ONE);
    const [errors, setErrors] = useState({});

    const [edit, setEdit] = useState(false);
    const [paises, setPaises] = useState([]);
    const [estados, setEstados] = useState([]);

    const [resetKey, setResetKey] = useState(constants.ZERO_VALUE);

    const [orderBy, setOrderBy] = useState({
        nameColumn: constants.NAME_COLUMN,
        orderAsc: true
    });

    const selectValues = [
        { value: constants.TEN_ROWS, label: `${constants.TEN_ROWS_LABEL} ${translate('components.table.resultsPerPage')}` },
        { value: constants.TWENTY_ROWS, label: `${constants.TWENTY_ROWS_LABEL} ${translate('components.table.resultsPerPage')}` },
        { value: constants.FIFTY_ROWS, label: `${constants.FIFTY_ROWS_LABEL} ${translate('components.table.resultsPerPage')}` },
    ];

    const initialData = {
        paisId: constants.EMPTY_STRING_INITIALIZER,
        estadoId: constants.EMPTY_STRING_INITIALIZER,
        ciudad: constants.EMPTY_STRING_INITIALIZER
    }

    const [selected, setSelected] = useState(initialData);

    const headingColumns = [
        { key: constants.COUNTRY_COLUMN, val: translate('administrator.manageCities.country'), width: constants.COLUMN_WIDTH_3 },
        { key: constants.DEPARTMENT_COLUMN, val: translate('administrator.manageCities.state'), width: constants.COLUMN_WIDTH_4 },
        { key: constants.NAME_COLUMN, val: translate('administrator.manageCities.city'), width: constants.COLUMN_WIDTH_3 },
    ];


    const validate = async () => {
        const dataSend = {
            id: selected.ciudadId ? selected.ciudadId : constants.ZERO_VALUE,
            name: selected.ciudad,
            state_id: parseInt(selected.estadoId)
        }
        try {
            validateLocationData(constants.CITY_LABEL, selected, () => {
                showLoader();
                updateCity(dataSend).then(response => {
                    hideLoader();
                    switch (response.data.status) {
                        case constants.STATUS_CODE_200:
                            informationAlert(constants.SUCCESS_LABEL, translate('alerts.success'), response.data.userMessage);
                            setSelected(initialData);
                            setEdit(false);
                            reloadTable();
                            break;
                        default:
                            informationAlert(constants.ERROR_LABEL, translate('alerts.oops'), response.data.userMessage);
                            break;
                    }
                }).catch(err => {
                    hideLoader();
                    throw ({ type: constants.ERROR_LABEL, msg: translate('alerts.oopsWrong'), details: constants.EMPTY_STRING_VALUE });
                })
            })
            limpiar();
        } catch (e) {
            informationAlert(e.type, e.msg, e.details);
            return false;
        }
    }

    const handleOnChangeSearch = (event) => {
        setSearchItem(event.target.value);
    }

    const handleOnclickDataHeading = (nameTableColumn) => {
        let orderByVal = nameTableColumn === orderBy.nameColumn ? !orderBy.orderAsc : true;
        setOrderBy({
            nameColumn: nameTableColumn,
            orderAsc: orderByVal
        });
    }

    const handleOnItemClickListener = async (row) => {
        setEdit(true);

        const pais = paises.filter(country => row.pais === country.name);

        showLoader();
        await getStatesByCountryId(pais[constants.FIRST_INDEX_POSITION].id).then(res => {
            const estado = res.filter(state => row.departamento === state.name);
            setSelected({
                ciudadId: row.ciudadId,
                paisId: pais[constants.FIRST_INDEX_POSITION]?.id,
                estadoId: estado[constants.FIRST_INDEX_POSITION]?.id,
                ciudad: row.nombre
            })
            hideLoader();
        });
        window.scrollTo(constants.ZERO_VALUE, constants.ZERO_VALUE);
    }

    const handleOnchange = (requestPerPage) => {
        setRequestsPerPage(requestPerPage);
        setCurrentPage(constants.FIRST_PAGE);
    }

    const handleOnclick = (newPage) => {
        if (newPage >= constants.ONE_VALUE && newPage <= totalPages) setCurrentPage(newPage);
    }

    const reloadTable = () => {
        const filterData = `${constants.KEYWORD_PARAM}${searchItem}${constants.PAGE_NO_PARAM}${currentPage - constants.ONE_VALUE}${constants.PAGE_SIZE_PARAM}${requestsPerPage}${constants.SORT_BY_PARAM}${orderBy.nameColumn}${constants.SORT_ORDER_PARAM}${orderBy.orderAsc}`;
        showLoader();
        getAllCities(filterData).then(response => {
            setTotalPages(response.totalPages);
            setTableData(response.resultList.slice());
            hideLoader();
        }).catch(error => {
            hideLoader();
            setTableData([]);
            informationAlert(constants.ERROR_LABEL, translate('alerts.oops'), error);
        });
    }

    const separatePastParams = (url) => {
        const search = url.split(constants.EQUALS);
        keyword = (search[constants.SECOND_INDEX_POSITION].split(constants.AMPERSAND))[constants.FIRST_INDEX_POSITION];
        pageNo = (search[constants.THIRD_INDEX_POSITION].split(constants.AMPERSAND))[constants.FIRST_INDEX_POSITION];
        pageSize = (search[constants.FOURTH_INDEX_POSITION].split(constants.AMPERSAND))[constants.FIRST_INDEX_POSITION];
        sortBy = (search[constants.FIFTH_INDEX_POSITION].split(constants.AMPERSAND))[constants.FIRST_INDEX_POSITION];
        ascSort = search[constants.SIXTH_INDEX_POSITION];
        setRequestsPerPage(pageSize);
        setSearchItem(keyword);
        setCurrentPage(parseInt(pageNo));
        setOrderBy({
            nameColumn: sortBy.toString(),
            orderAsc: ascSort
        });
    }

    useEffect(() => {
        showLoader();
        const dataSend = `${constants.KEYWORD_PARAM}${searchItem}${constants.PAGE_NO_PARAM}${currentPage - constants.ONE_VALUE}${constants.PAGE_SIZE_PARAM}${requestsPerPage}${constants.SORT_BY_PARAM}${orderBy.nameColumn}${constants.SORT_ORDER_PARAM}${orderBy.orderAsc}`;
        if (window.location.search == constants.EMPTY_STRING_VALUE) {
            getAllCities(dataSend).then(response => {
                setTotalPages(response.totalPages);
                setTableData(response.resultList.slice());
                hideLoader();
            }).catch(error => {
                hideLoader();
                setTableData([]);
                informationAlert(constants.ERROR_LABEL, translate('alerts.oops'), error);
            })
        } else {
            separatePastParams(window.location.search);
        }
    }, [])

    useEffect(() => {
        reloadTable();
    }, [currentPage, requestsPerPage, orderBy, searchItem])

    const actualizarData = (fragment, event) => {
        let value;
        try {
            value = event.detail ? event.detail : event.target.value;
        } catch (e) {
            value = event;
        }

        if (fragment === constants.COUNTRY_ID) {
            setSelected({ ...selected, [fragment]: value, estadoId: constants.EMPTY_STRING_VALUE })
        } else if (fragment === constants.STATE_ID) {
            const stateId = estados.filter(state => state.name === value)[constants.FIRST_INDEX_POSITION].id;
            const dropdown = document.querySelectorAll(constants.DROPDOWN_SELECTOR)[constants.SECOND_INDEX_POSITION];
            const elem = dropdown.shadowRoot.querySelector(constants.DROPDOWN_PLACEHOLDER_CLASS).querySelector(constants.SPAN_ELEMENT);
            elem.innerText = value;
            setSelected({ ...selected, [fragment]: stateId.toString() })
        } else {
            setSelected({ ...selected, [fragment]: value })
        }
    }

    const limpiar = () => {
        setEdit(false);
        setSelected(initialData);
        setEstados([]);
        setResetKey(prevKey => prevKey + constants.ONE_VALUE);
    }

    const obtenerEstados = (countryCode) => {
        showLoader();
        getStatesByCountryId(countryCode).then(res => {
            setEstados(res);
            hideLoader();
        })
    }

    const actualizarLocation = (location, event) => {
        if (location === constants.COUNTRY_LABEL) {
            const dropdown = document.querySelectorAll(constants.DROPDOWN_SELECTOR)[constants.FIRST_INDEX_POSITION];
            const elem = dropdown.shadowRoot.querySelector(constants.DROPDOWN_PLACEHOLDER_CLASS).querySelector(constants.SPAN_ELEMENT)
            elem.innerText = event.detail;
            if (event.detail !== constants.EMPTY_STRING_VALUE) {
                const aux = paises.filter(row => {
                    return row.name === event.detail;
                })[constants.FIRST_INDEX_POSITION];
                actualizarData(constants.COUNTRY_ID, aux.id);
                obtenerEstados(aux.id);
            }
        }
    }

    const handleOnInput = (event) => {
        const { name, value } = event.target;
        actualizarData(constants.CITY_LABEL, event)
    
        const fieldErrors = validateField(name, value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            ...fieldErrors,
        }));
    };

    useEffect(() => {
        getAllCountries().then(res => {
            setPaises(res);
        }).catch(error => {
            console.log(constants.ERROR_LABEL, error);
        })
    }, []);

    useEffect(() => {
        if (selected.paisId !== constants.EMPTY_STRING_VALUE) {
            obtenerEstados(
                paises.find((pais) => pais.id === parseInt(selected.paisId))?.id
            );
        }
    }, [paises, selected.paisId])

    const dropdowns = document.querySelectorAll(constants.DROPDOWN_SELECTOR);
    dropdowns.forEach(dropdown => {
        const dropdownElement = dropdown.shadowRoot.querySelector(constants.DROPDOWN_CLASS);
        if (dropdownElement) {
            dropdownElement.style.cssText = `
                    ${constants.DROPDOWN_WIDTH}
                `;
        }
    }); 

    const getValuesDropdown = useCallback((key, value, list) => {
        return getValueDropdown(key, value, list);
    },[selected]);
    

    return (
        <Fragment>
            <Toolkit>
                <div className="cities-toolkit__search">
                    <InputSearchSet
                        type="text"
                        placeholder={translate('transversal.search')}
                        value={searchItem}
                        onChange={handleOnChangeSearch}
                    />
                </div>
            </Toolkit>
            <div className="form__content edit__location">
                <div className="information">
                    <div>
                        <h3 className='information__title'>{`${edit ? translate('transversal.edit') : translate('transversal.create')} ${translate('administrator.manageCities.city')}`}</h3>
                    </div>
                    <div key={resetKey} className="form__input">
                        <PdsDropdown
                            className={selected.paisId !== constants.EMPTY_STRING_VALUE ? 'input-value' : ''}
                            label={translate('administrator.manageCities.country')}
                            name='pais'
                            value={selected.paisId}
                            onValueChange={(event) => actualizarLocation('pais', event)}
                            placeholder={getValuesDropdown("id", selected.paisId, paises) || translate('transversal.selectOption')}
                        >
                            {paises.map((pais) => (
                                <PdsDropdownOption theme="dark" key={pais.id} value={pais.name}>
                                    {pais.name}
                                </PdsDropdownOption>
                            ))}
                        </PdsDropdown>
                        <PdsDropdown
                            className={selected.estadoId !== constants.EMPTY_STRING_VALUE ? 'input-value' : ''}
                            label={translate('administrator.manageCities.state')}
                            name='estado'
                            value={selected.estadoId}
                            onValueChange={(event) => actualizarData('estadoId', event)}
                            placeholder={getValuesDropdown("id", selected.estadoId, estados) || translate('transversal.selectOption')}
                        >
                            {estados.map((estado) => (
                                <PdsDropdownOption theme="dark" key={estado.id} value={estado.name}>
                                    {estado.name}
                                </PdsDropdownOption>
                            ))}
                        </PdsDropdown>
                        <PdsInput
                            className='input__edit'
                            type='text'
                            name='ciudad'
                            value={selected.ciudad}
                            onInput={handleOnInput}
                            label={translate('administrator.manageCities.city')}
                            {...(errors.ciudad || {})}
                        />
                    </div>
                    <div className="form__button">
                        <PdsButton
                            className='manage-cities__button-clean'
                            name={translate('transversal.clean')} 
                            onClick={limpiar}
                        >
                            {translate('transversal.clean')}
                        </PdsButton>
                        <PdsButton
                            className='manage-cities__button-edit'
                            name={edit ? translate('transversal.edit') : translate('transversal.create')}
                            onClick={validate}
                        >
                            {edit ? translate('transversal.edit') : translate('transversal.create')}
                        </PdsButton>
                    </div>
                </div>
            </div>
            <Table
                tableData={tableData}
                headingColums={headingColumns}
                title={null}
                breakOn='medium'
                orderBy={handleOnclickDataHeading}
                orderColumnName={orderBy.nameColumn}
                orderColumnDirection={orderBy.orderAsc}
                onItemClickListener={handleOnItemClickListener}
            />
            <div className="o-footer__table">
                <MainSelect
                    options={selectValues}
                    name={`invoicetable`}
                    value={requestsPerPage}
                    onChange={handleOnchange}
                />
                <TablePagination
                    paginate={(event, newPage) => handleOnclick(newPage)}
                    pageAmount={totalPages}
                    currentPage={currentPage}
                />
            </div>
            {loader}
        </Fragment>
    )
}

export default ManageCities;
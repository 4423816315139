import React from 'react';
import "./container-upload.scss";

function ContainerUpload({children, dashed}) {
    return (
        <div className={`container-upload ${dashed ? 'border-dashed' : ''}`}>
            {children}
        </div>
    );
}

export default ContainerUpload;
import { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { getUserById } from "../../../services/userService";
import { UserContext } from "../../../provider/user";
import UserForm from "../../molecules/user-form/userForm";
import Wrapper from "../../templates/wrapper/Wrapper";
import useLoader from "../../../utils/useLoader";
import Back from "../../../images/ico-back.svg";
import "./editUser.scss";
import Aside from "../../templates/aside/aside";
import HeaderClient from "../../molecules/header-create-client/headerClient";
import RoundButton from "../../atoms/round-button/roundButton";
import { useTranslation } from "react-i18next";
import EditUserConstants from "../../../constants/edit-user-constants";

function EditUser() {
  const constants = EditUserConstants;

  const params = useParams();
  const { dataUser, setDataUser } = useContext(UserContext);
  const [loader, showLoader, hideLoader] = useLoader();
  const [translate] = useTranslation(constants.GLOBAL);

  useEffect(() => {
    if (params.userId) {
      getDataUser(params.userId);
    }
  }, [params.userId]);

  const getDataUser = async (userId) => {
    showLoader();
    const user = await getUserById(userId);
    if (!user.negocios) {
      user.negocios = [];
    }
    if (!user.filiales) {
      user.filiales = [];
    }
    hideLoader();
    setDataUser({
      type: constants.SETDATAUSER_TYPE,
      payload: { ...user },
    });
  };

  const title = translate('administrator.users.titleEditing');
  const [blur, setBlur] = useState(false);
  const goBack = () => window.history.back();

  return (
    <Wrapper 
      aside={<Aside blur={blur} setBlur={setBlur}/>} 
      blur={blur}>
      <div className="HeaderContainer">
        <div className="HeaderInformation__button">
          <RoundButton className="BotonFixed" icon={Back} onClick={goBack} />
        </div>
        <header className="HeaderEditUser" >
          <HeaderClient title={title} />
        </header>
      </div>
      <UserForm />
      {loader}
    </Wrapper>
  );
}

export default EditUser;

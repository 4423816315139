const manageCitiesConstants = {
    EMPTY_STRING_INITIAL_STATE: '',
    EMPTY_STRING_INITIALIZER: '',
    EMPTY_STRING_VALUE: '',
    REQUESTS_INITIAL_STATE_TWENTY: 20,
    PAGES_INITIAL_STATE_ONE: 1,
    ZERO_VALUE: 0,
    ONE_VALUE: 1,
    ZERO_VALUE_STRING: '0',

    EQUALS: '=',
    AMPERSAND: '&',

    NAME_COLUMN: 'nombre',
    COUNTRY_COLUMN: 'pais',
    DEPARTMENT_COLUMN: 'departamento',
    
    COLUMN_WIDTH_3: 3,
    COLUMN_WIDTH_4: 4,

    TEN_ROWS: 10,
    TEN_ROWS_LABEL: '10',
    TWENTY_ROWS: 20,
    TWENTY_ROWS_LABEL: '20',
    FIFTY_ROWS: 50,
    FIFTY_ROWS_LABEL: '50',

    CITY_LABEL: 'ciudad',
    SUCCESS_LABEL: 'success',
    ERROR_LABEL: 'error',
    STATUS_CODE_200: 200,

    FIRST_PAGE: 1,
    FIRST_INDEX_POSITION: 0,
    SECOND_INDEX_POSITION: 1,
    THIRD_INDEX_POSITION: 2,
    FOURTH_INDEX_POSITION: 3,
    FIFTH_INDEX_POSITION: 4,
    SIXTH_INDEX_POSITION: 5,

    KEYWORD_PARAM: '?keyword=',
    PAGE_NO_PARAM: '&pageNo=',
    PAGE_SIZE_PARAM: '&pageSize=',
    SORT_BY_PARAM: '&sortBy=',
    SORT_ORDER_PARAM: '&ascSort=',

    COUNTRY_ID: 'paisId',
    STATE_ID: 'estadoId',
    COUNTRY_LABEL: 'pais',

    DROPDOWN_SELECTOR: 'pds-dropdown',
    DROPDOWN_CLASS: '.dropdown',
    DROPDOWN_PLACEHOLDER_CLASS: '.dropdown-placeholder',
    SPAN_ELEMENT: 'span',
    DROPDOWN_WIDTH: 'width: 100%',
};

export default manageCitiesConstants;
import { useContext, useEffect, useState } from "react";
import { ClientContext } from "../../../provider/cliente";
import { getClientsBrands } from "../../../services/clientService";
import PillButton from "../../atoms/pill-button/pillButton";
import AssociatedBrandsConstants from "../../../constants/associated-brand-constants";
import { useTranslation } from "react-i18next";
import "./associatedBrands.scss";

function AssociatedBrands() {
  const constants = AssociatedBrandsConstants;

  const { dataClient } = useContext(ClientContext);
  const [translate] = useTranslation(constants.GLOBAL);

  const [dataBrands, setDataBrands] = useState([]);

  useEffect(() => {
    getClientsBrands(dataClient.clienteId)
      .then((response) => setDataBrands(response))
      .catch((err) => setDataBrands([]));
  }, [dataClient]);

  return (
    <section className="brands_fields">
      <h3>{translate('customers.createCustomer.associatedBrands.title')}</h3>
      
      <ul className="associated_brands">
        {dataBrands.map((brand) => {
          return (
            <li key={brand.itemId}>
              <PillButton
                title={brand.itemName}
                itemId={brand.itemId}
                disabled={true}
              />
            </li>
          );
        })}
      </ul>
    </section>
  );
}

export default AssociatedBrands;

import React from "react";
import Swal from "sweetalert2";
import AlertConstants from "../../../constants/alert-constants";

const informationAlert = (icon, title, text) => {
  const constants = AlertConstants
  const confirmButtonText = icon === constants.ERROR ? constants.OK : constants.ACCEPT;
  return Swal.fire({
    icon: icon,
    title: title,
    iconColor: constants.ICON_COLOR,
    background: constants.BACKGROUND_COLOR,
    showCloseButton: constants.TRUE,
    confirmButtonText: confirmButtonText,
    text: text,
  });
};

const confirmationAlert = (
  icon,
  title,
  text,
  confirmButtonText,
  denyButtonText,
  onConfirm
) => {
  const constants = AlertConstants
  return Swal.fire({
    icon,
    title,
    iconColor: constants.ICON_COLOR,
    background: constants.BACKGROUND_COLOR,
    text,
    showDenyButton: constants.TRUE,
    showCloseButton: constants.TRUE,
    confirmButtonText,
    denyButtonText,
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm();
    } else if (result.isDenied) {
      
    }
  });
};

export { informationAlert, confirmationAlert };

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext } from "react";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Table from "../../molecules/table/Table";
import useLoader from '../../../utils/useLoader';
import Toolkit from "../../atoms/toolkit/Toolkit";
import MainSelect from "../../atoms/main-select/MainSelect";
import { informationAlert } from '../../molecules/alert/alert';
import { AppContextUser } from '../../../provider/userInSession';
import { listPolicyRequest } from "../../../services/policyService";
import InputSearchSet from "../../molecules/input-search-set/InputSearchSet";
import policyTableConstants from "../../../constants/policy-table-constants";
import TablePagination from "../../molecules/table-pagination/TablePagination";
import "./policyTable.scss";

const PolicyTable = ({ estados, estadosPropios }) => {
    let filtro, page, requests, columnSearched, orderSearched;

    const constants = policyTableConstants;

    const history = useHistory();
    const [translate] = useTranslation(constants.GLOBAL);
    const { dataUser } = useContext(AppContextUser);
    const [loader, showLoader, hideLoader] = useLoader();

    const [data, setData] = useState([]);
    const [pageAmount, setPageAmount] = useState(constants.ONE_VALUE_INITIALIZER);
    const [currentPage, setCurrentPage] = useState(constants.ONE_VALUE_INITIALIZER);
    const [searchItem, setSearchItem] = useState(constants.EMPTY_STRING_INITIALIZER);
    const [orderBy, setorderBy] = useState({ nameColumn: constants.POL_COLUMN, orderAsc: false });
    const [requestsPerPage, setRequestsPerPage] = useState(constants.REQUESTS_INITIAL_STATE_TWENTY);

    useEffect(() => { if (window.location.search !== constants.EMPTY_STRING_COMPARATOR) separatePastParams(window.location.search) }, []);

    useEffect(() => {
        if (estados.length) {
            showLoader();
            const dataSend = {
                usuarioId: dataUser.userId,
                lista: [{}],
                estados: estados,
                estadosPropios: estadosPropios,
                numeroPagina: (currentPage - constants.ONE_VALUE),
                nombreRol: dataUser.rol,
                peticionesPorPagina: requestsPerPage,
                nombreColumna: orderBy.nameColumn,
                orden: orderBy.orderAsc,
                busqueda: searchItem,
            }
            const response = listPolicyRequest(dataSend);

            response.then(resp => {
                hideLoader();
                if (resp.status && resp.status === constants.STATUS_CODE_200) {
                    setPageAmount(resp.data.cantidadPaginasTotales);
                    setData(resp.data.lista);
                } else {
                    setData([]);
                    informationAlert(constants.ERROR_LABEL, translate('alerts.oops'), resp.userMessage);
                }
            }).catch(err => {
                setData([]);
                informationAlert(constants.ERROR_LABEL, translate('alerts.oops'), err);
            });
        }
    }, [currentPage, requestsPerPage, orderBy, searchItem, estados]);

    const selectValues = [
        { value: constants.TEN_ROWS, label: `${constants.TEN_ROWS_LABEL} ${translate('components.table.resultsPerPage')}` },
        { value: constants.TWENTY_ROWS, label: `${constants.TWENTY_ROWS} ${translate('components.table.resultsPerPage')}` },
        { value: constants.FIFTY_ROWS, label: `${constants.FIFTY_ROWS} ${translate('components.table.resultsPerPage')}` }
    ];

    const headingColumns = [
        { key: constants.POL_COLUMN, val: translate('policy.pendingPolicy.noRequest'), width: constants.COLUMN_WIDTH_6 },
        { key: constants.USER_EMAIL, val: translate('policy.pendingPolicy.user'), width: constants.COLUMN_WIDTH_12 },
        {
            key: constants.DOC_ID, val: translate('policy.pendingPolicy.document'), width: constants.COLUMN_WIDTH_9,
            type: constants.TEXT_TYPE, renderAs: [constants.RENDER.PERCENTAGE, constants.DOC_ID, constants.RENDER.VERIFICATION]
        },
        { key: constants.CLIENT_NAME, val: translate('policy.pendingPolicy.customer'), width: constants.COLUMN_WIDTH_11 },
        { key: constants.DATE_REQUEST, val: translate('policy.pendingPolicy.applicationDate'), width: constants.COLUMN_WIDTH_8 },
        { key: constants.LIMIT_DATE, val: translate('policy.pendingPolicy.deadline'), width: constants.COLUMN_WIDTH_8 },
        { key: constants.STATUS_VALUE, val: translate('policy.pendingPolicy.state'), width: constants.COLUMN_WIDTH_8 }
    ];

    if (window.location.pathname === constants.HISTORY_PATH) {
        headingColumns.push({ key: constants.DATE_CREATION, val: translate('policy.pendingPolicy.creationDate'), width: constants.COLUMN_WIDTH_8 });
    }

    const handleOnclick = (newPage) => {
        if (newPage >= constants.ONE_VALUE && newPage <= pageAmount) setCurrentPage(newPage);
    }

    const handleOnChangeSearch = (event) => setSearchItem(event.target.value);

    const handleOnchange = (requestPerPage) => {
        setRequestsPerPage(requestPerPage);
        setCurrentPage(constants.ONE_VALUE);
    }

    const handleOnclickDataHeading = (nameTableColumn) => {
        let orderByVal = nameTableColumn === orderBy.nameColumn ? !orderBy.orderAsc : true;
        setorderBy({ nameColumn: nameTableColumn, orderAsc: orderByVal });
    };

    const handleOnItemClickListener = (row) => {
        const url = `${constants.FILTER_PARAM}${searchItem}${constants.PAGE_PARAM}${currentPage}${constants.REQUEST_PARAM}
            ${requestsPerPage}${constants.ORDER_BY_PARAM}${orderBy.nameColumn}${constants.ORDER_PARAM}${orderBy.orderAsc}`;
        window.history.replaceState(null, null, url);
        history.push(constants.POLICY_PATH + row.polSolicitud);
    };

    const separatePastParams = (url) => {
        const search = url.split(constants.EQUALS);
        const requestBurned = (search[constants.FOURTH_INDEX_POSITION].split(constants.AMPERSAND))[constants.FIRST_INDEX_POSITION];

        filtro = (search[constants.SECOND_INDEX_POSITION].split(constants.AMPERSAND))[constants.FIRST_INDEX_POSITION];
        page = (search[constants.THIRD_INDEX_POSITION].split(constants.AMPERSAND))[constants.FIRST_INDEX_POSITION];
        requests = Number.isNaN(requestBurned) ? requestBurned : constants.REQUESTS_INITIAL_STATE_TWENTY
        columnSearched = (search[constants.FIFTH_INDEX_POSITION].split(constants.AMPERSAND))[constants.FIRST_INDEX_POSITION];
        orderSearched = search[constants.SIXTH_INDEX_POSITION];

        setRequestsPerPage(requests);
        setSearchItem(filtro);
        setCurrentPage(parseInt(page));
        setorderBy({ nameColumn: columnSearched.toString(), orderAsc: orderSearched === constants.TRUE });
    }

    return (
        <>
            <div className="o-policy__header">
                <Toolkit>
                    <InputSearchSet
                        type={constants.TEXT_TYPE}
                        placeholder={translate('transversal.search')}
                        value={searchItem}
                        onChange={handleOnChangeSearch}
                    />
                </Toolkit>
            </div>
            <div className="policy-table">
                <Table
                    tableData={data}
                    headingColums={headingColumns}
                    title={null}
                    breakOn='medium'
                    orderBy={handleOnclickDataHeading}
                    orderColumnName={orderBy.nameColumn}
                    orderColumnDirection={orderBy.orderAsc}
                    onItemClickListener={handleOnItemClickListener}
                />
            </div>
            
            <div className="o-footer__table">
                <MainSelect
                    options={selectValues}
                    name={`invoicetable`}
                    value={requestsPerPage}
                    onChange={handleOnchange}
                />
                <TablePagination
                    paginate={(e, newPage) => handleOnclick(newPage)}
                    pageAmount={pageAmount}
                    currentPage={currentPage}
                />
            </div>
            {loader}
        </>
    );
}

export default PolicyTable;
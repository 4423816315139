const PolicyPendingTableConstants = {

  GLOBAL: "global",
  
  ROLES: {
    ADMIN: "Administrador",
    APPROVER: "Aprobador",
    SOLICITOR: "Solicitante",
    SUPERVISOR: "Supervisor",
  },

  ESTADOS: {
    PENDIENTE: "PENDIENTE",
    DEVUELTA: "DEVUELTA",
  }

};

export default PolicyPendingTableConstants
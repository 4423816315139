const UtilsConstants = {

  TRANSLATION_GLOBAL_VALUE: 'global',

  FALSE: false,
  TRUE: true,
  UNDEFINED: undefined,
  NULL: null,

  EMPTY_STRING: '',
  EMPTY_SPACE_STRING: ' ',
  OBJECT: 'object',
  DE: ' de ',

  SIGNS: {
    COMMA: ',',
    POINT: '.',
    GUION: '-',
    SLASH: '/',
  },

  REGEX: {
    DIGITS_AND_POINTS: /[^\d.]/g,
    DIGITS_AND_COMMAS: /[^\d,]/g,
    ONLY_NUMBERS: /^[0-9]+$/,
    ONLY_LETTERS: /^[a-zA-Z\s]+$/,
    SPECIAL_CHARACTERS: /[^a-zA-Z0-9\s+-]/,

  },

  NUMBERS_STRING: {
    POINT_DOUBLE_ZERO: '.00',
    POINT_ZERO: '.0',
    DOUBLE_ZERO: '00',
    ZERO: '0',
    ONE: '1',
    TWO: '2',
    THREE: '3',
    FOUR: '4',
    FIVE: '5',
    SIX: '6',
    SEVEN: '7',
    EIGHT: '8',
    NINE: '9'
  },

  NUMBERS : {
    MINUS_ONE: -1,
    ZERO: 0,
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
    SIX: 6,
    SEVEN: 7,
    EIGHT: 8,
    NINE: 9,
    TEN: 10,
    FIFTY: 50
  },

  CURRENCY_ALLOWED_KEY_CODES: [
    48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96,
    97, 98, 99, 100, 101, 102, 103, 104, 105,
    188, 190, 110, 16, 9, 36, 35, 8, 13, 46, 37,
    38, 39, 40
  ],

  NUMERIC_ALLOWED_KEY_CODES: [
    48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96,
    97, 98, 99, 100, 101, 102, 103, 104, 105,
    110, 16, 9, 36, 35, 8, 13, 46, 37,
    38, 39, 40
  ],

  PHONE_ALLOWED_KEY_CODES: [
    48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96,
    97, 98, 99, 100, 101, 102, 103, 104, 105,
    110, 16, 9, 36, 35, 8, 13, 46, 37,
    38, 39, 40, 187
  ],

  LENGTH: {
    TWENTY_ONE: 21,
  },

  LOCAL_CODE: {
    DE: 'de-DE',
    EN: 'en-US'
  },

  DATE_FORMAT: 'YYYY-MM-DD',

  CASE: {
    COUNTRY: 'pais',
    STATE: 'estado',
    CITY: 'ciudad',
    DATE: 'DATE',
    TEXT_DATE: 'TEXT_DATE',
    DD_MM_AAAA: 'DD/MM/AAAA',
  },

  VALIDATE_FIELD: {
    RAZON_SOCIAL_RUT: 'razonSocialRut',
    NUMERO_DOCUMENTO: 'numeroDocumento',
    DIRECCION_RUT: 'direccionRut',
    CODIGO_POSTAL: 'codigoPostal',
    TELEFONO_RUT: 'telefonoRut',
    CELULAR_RUT: 'celularRut',
    EMAIL_FACTURACION: 'emailFacturacion',
    PLAZO_PAGO: 'plazoPago',
    NAME: 'name',
    COSTCENTER: 'costCenter',
    IVA: 'iva',
    RETEIVA: 'reteIva',
    RETEFUENTE: 'reteFuente',
    RETEICA: 'reteIca',
    CITY: 'ciudad',
    STATE: 'estado',
    SORTNAME: 'sortname',
    PHONE_CODE: 'phonecode',
    CONTACT: 'contacto',
    CONTACT_CHARGE: 'cargoContacto',
    SYSTEM_CODE:'codigoSistema',
    COUNTRY:'country',
    DIRECTION: 'direccion',
    TAX_REGIME: 'nombreRegimenTributario',
    TELEPHONE: 'telefono',
    BUY_ORDER: 'buyOrder',
    CONCEPT_VALUE: 'valorConcepto',
  },

  ALERT: {
    TYPE: {
      ERROR: "error"
    },

    MESSAGES:{
      ALERT: '¡Alerta!',
      OBLIGATORY_FIELD: 'Este Campo es Obligatorio',
      REQUIRED_FIELD: 'Campo requerido',
      REQUIRED_INFORMATION: 'Información requerida',
      REQUIRED_FILE: '¡Archivo requerido!',
      INCORRECT_FORMAT: 'Formato incorrecto',
      INCORRECT_VALUE: 'Valor incorrecto',
      NOT_MORE_DIGIT_TITLE: 'No puedes ingresar más dígitos!',
      NOT_MORE_DIGIT_TEXT: "No puedes ingresar más dígitos! \nIntenta borrar un número entero para agregar los decimales.",
      FORMAT_NOT_EXIST: 'No existe este tipo de formato',
      NOT_ONLY_NUMBERS:'No deben contener solo numeros',
      NOT_NUMBERS: 'No debe contener numeros',
      NO_SPECIAL_CHARACTERS: 'No debe contener caracteres especiales',
      ONLY_NUMBERS: 'Debe contener solo numeros',
      LENGTH:'Debe contener más de un caracter',
    },

    DETAILS:{
      BRAND: {
        NAME_BRAND: 'Debes nombrar a la marca',
        COSTCENTER_BRAND: 'Debes darle un centro de costo a la marca',
        CLIENT_BRAND: 'Debes asignar clientes a la marca',
        FILIAL_BRAND: 'Debes asignar filiales a la marca'
      },

      INVOICE:{
        REQUIRED: ' Es requerida',
        REQUIRED_LIMIT_DATE: 'El campo Fecha limite de entrega es requerido',
        REQUIRED_CONTACT: 'El campo Contacto es requerido',
        REQUIRED_CHARGE: 'El campo Cargo es requerido',
        REQUIRED_PAYMENT_CONDITIONS: 'Las condiciones de pago son requeridas',
        REQUIRED_CONCEPTS: 'Los conceptos son requeridos',
        REQUIRED_CONCEPT_DESCRIPTION: 'La descripción del concepto #',
        CONCEPT_NUMBER: ' del concepto #',
        COSTCENTER_BUSSINES: 'El Negocio del Centro de costo #',
        COSTCENTER_BRAND: 'La Marca del Centro de costo #',
        COSTCENTER_VALUE: 'El Valor del Centro de costo #',
        COSTCENTER_INGRESS_TYPE: 'El tipo de ingreso del Centro de costo #',
        VALUE_DAYS_PAYMENT: 'Indica el valor en días del plazo de pago',
        SELECTED_CLIENT: 'Debes seleccionar un cliente',
        SELECTED_FILIAL: 'Debes seleccionar una filial',
      },

      CLIENTS: {
        REQUIRED_NAME: 'El campo Nombre es requerido',
        REQUIRED_NIT: 'El campo Nit es requerido',
        REQUIRED_COUNTRY: 'El campo Pais es requerido',
        REQUIRED_STATE: 'El campo Estado es requerido',
        REQUIRED_CITY: 'El campo Ciudad es requerido',
        REQUIRED_POSTAL_CODE: 'El campo Código postal es requerido',
        REQUIRED_ADDRESS: 'El campo Dirección es requerido',
        REQUIRED_PHONE: 'El campo Telefono es requerido',
        REQUIRED_CELLPHONE: 'El campo Celular es requerido',
        REQUIRED_EMAIL: 'El campo Email es requerido',
        REQUIRED_PAYMENT_TERM: 'El campo Plazo de pago es requerido',
        SET_COMERCIAL_DOCUMENT: 'Ingrese el documento de comercio correspondiente',
        SET_FISCAL_DOCUMENT: 'Ingrese el documento de identificación fiscal correspondiente',
        PHONE_FORMAT: 'El campo Teléfono no tiene el formato de teléfono correcto',
        CELLPHONE_FORMAT: 'El campo Celular no tiene el formato de celular correcto',
        EMAIL_FORMAT: 'El campo Email no tiene el formato de un correo electrónico correcto',
        PAYMENT_TERM_ONLY_NUMBERS: 'El campo Plazo de pago solo debe contener números',
        NIT_ONLY_NUMBERS: 'El campo NIT solo debe contener números',
        POSTAL_CODE_ONLY_NUMBERS: 'El campo Código postal solo debe contener números',
        IVA_BETWEEN_0_50: 'El IVA debe ser mayor a 0 y menor a 50',
        RETEIVA_BETWEEN_0_50: 'El ReteIva debe ser mayor a 0 y menor a 50',
        RETEFUENTE_BETWEEN_0_50: 'La ReteFuente debe ser mayor a 0 y menor a 50',
        ICA_BETWEEN_0_50: 'El Ica debe ser mayor a 0 y menor a 50',
      },

      POLICY: {
        REQUIRED_LIMIT_DATE: 'El campo Fecha limite de entrega es requerido',
        REQUIRED_CLIENT: 'Debes seleccionar un cliente',
        REQUIRED_CONTACT: 'El campo Contacto es requerido',
        REQUIRED_CHARGE: 'El campo Cargo es requerido',
        REQUIRED_POLICY_TYPE: 'Debes seleccionar por lo menos un tipo de poliza',
        REQUIRED_FILIAL: 'Debes seleccionar una filial',
        REQUIRED_BRAND: 'Debes seleccionar una marca',
        REQUIRED_BUSSINES: 'Debes seleccionar un negocio',
        REQUIRED_FILES: 'Debes agregar un archivo en cada uno de los campos.',
        OTHER_POLICY: 'Por favor indica el nombre de la otra opción',
      },

      LOCATION_DATA: {
        REQUIRED_COD_COUNTRY: 'El campo Código del pais es requerido',
        COD_COUNTRY_LENGTH: 'El campo Código debe tener máximo 3 dígitos',
        REQUIRED_NAME: 'El campo Nombre es requerido',
        REQUIRED_COD_PHONE: 'El campo Código de teléfono es requerido',
        REQUIRED_COUNTRY: 'Seleccione un país',
        REQUIRED_STATE: 'El campo Estado es requerido',
        REQUIRED_CITY: 'El campo Ciudad es requerido',
        SELECT_STATE: 'Seleccione un estado',
        PHONE_FORMAT: 'El campo Teléfono no tiene el formato de teléfono correcto',
        PHONE_LENGTH: 'El campo Teléfono debe tener máximo 10 dígitos'
      },

      USER: {
        REQUIRED_ROLE: 'El campo Rol es requerido',
        REQUIRED_COD_SYSTEM: 'El campo Código del sistema es requerido',
        REQUIRED_BUSSINES: 'El campo Negocios es requerido',
        FILIAL_CONTAIN: 'El campo filiales debe contener minimo una filial',
      }
      
    },

    TRIM:{
      LIMIT_DATE: 'fechaLimiteEntrega',
      CONTACT: 'contacto',
      CONTACT_CHARGE: 'cargoContacto'
    }
  },

};

export default UtilsConstants;
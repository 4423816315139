import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import UserInSessionProvider from "./provider/userInSession";
import indexConstants from './constants/index-constants';
import reportWebVitals from "./reportWebVitals";
import ReactDOM from "react-dom";
import React from "react";
import App from "./app";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import i18next_es from './i18n/es.json';
import i18next_en from './i18n/en.json';

import "./index.scss";
import "@pragma-dev-kit/pragma-ds/dist/pragma-ds/pragma-ds.css";
import { defineCustomElements } from "@pragma-dev-kit/pragma-ds-react";

defineCustomElements();

const constants = indexConstants;

i18next.init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem(constants.I18N) || constants.SPANISH,
  fallbackLng: localStorage.getItem(constants.I18N) || constants.SPANISH,
  resources: {
    es: {
      global: i18next_es
    },
    en: {
      global: i18next_en
    }
  }
})


ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <UserInSessionProvider>
        <App />
      </UserInSessionProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById(constants.ROOT)
);

serviceWorkerRegistration.register();
reportWebVitals();
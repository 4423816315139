import "./CheckboxSwitch.scss"
import SwitchConstants from "../../../constants/checkbox-switch-constants";
import { useTranslation } from "react-i18next";

const CheckboxSwitch = ({ checked, label, disabledText, enabledText, onChange, name }) => {
    const constants = SwitchConstants;
    const [translate] = useTranslation(constants.GLOBAL);

    return (
        <section className="group_container">
            <p className="enabled__text">{enabledText}</p>
            <div>
                <label className="switch">
                    <input type="checkbox" checked={checked} name={name} onChange={onChange} />
                    <span className="slider round">
                        <span className={checked ? "slider-true" : "slider-false"}>
                            {checked ? translate('alerts.activeStatus') : translate('alerts.deactiveStatus')}
                        </span>
                    </span>
                </label>
            </div>
        </section>
    );
}

export default CheckboxSwitch;
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";
import { AppContextUser } from "../../../provider/userInSession";

import "./footer_aside.scss";
import Salir_icon from "../../../images/ico-salir.svg";
import Images from "../../atoms/images/images";
import copyrightFooter from "../../../images/copyrigth-footer.svg";
import lineFooter from "../../../images/line-footer.svg";

const FooterAside = (Props) => {
  const { dataUser, setDataUser } = useContext(AppContextUser);
  const history = useHistory();

  const signOut = () => {
    document.cookie.split(";").forEach(function(c) {
      document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
      
    Auth.signOut().finally(() => {
      history.push("/login");
      window.location.reload();
    });
  };    

  return (
    <div className="footer">
      <div className="footer_aside">
        <div className="initialName">
          {/* Informacion despues del logeo */}
          <span>{dataUser.initialLetters}</span>
        </div>
        <div className="content-name">
          {/* Informacion despues del logeo */}
          <div className="name">{dataUser.userName}</div>
          <div className="rol">{dataUser?.rol}</div>
        </div>
        <div className="content-ico">
          <Images Imagen={Salir_icon} onClick={signOut} />
        </div>
      </div>
      <div className="footer_line">
        <Images Imagen={lineFooter} />
        <Images className="footer_copyright" Imagen={copyrightFooter} />
      </div>
    </div>
  );
};

export default FooterAside;
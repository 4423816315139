import "./ErrorPage.scss";
import Wrapper from "../../templates/wrapper/Wrapper";
import Aside from "../../templates/aside/aside";
import {Fragment, useState} from "react";

const ErrorPage = ({location}) => {

    const [blur, setBlur] = useState(false);

    return (<Fragment>
        <Wrapper aside={<Aside blur={blur} setBlur={setBlur}/>} blur={blur}>
            <div className={"error-container"}>
                <h1>Lo sentimos</h1>
                <p>Ha ocurrido un error inesperado.</p>
            </div>
        </Wrapper>
    </Fragment>);
}

export default ErrorPage;

import React, { useContext, useState } from 'react';
import './concepto.scss';
import icoDelete from '../../../images/ico-delete.svg';
import icoMinus from '../../../images/ico-minus.svg';
import { PdsInput } from "@pragma-dev-kit/pragma-ds-react";
import Select from '../../atoms/select/select';
import TextBlockTwo from '../../molecules/text-blocks/textBlockTwo';
import Button from '../../atoms/buttons/button';
import Images from '../../atoms/images/images'
import { FacturaContext } from '../../../provider/factura';
import {
    avoidNonNumericValues,
    formatValToCurrency,
    parseCurrencyToVal,
    validateInputDecimals,
    isInvoiceRequestDisable,
    validateField
} from '../../../utils/utils';
import {
    INPUT_MAX_LENGTH_WITH_DECIMAL_SEPARATOR,
    INPUT_MAX_LENGTH_WITHOUT_DECIMAL_SEPARATOR,
    MAXLENGTH_BUYORDER
} from "../../../utils/constans";
import TextArea from '../../atoms/text-area/textArea';
import { useTranslation } from 'react-i18next';
import ConceptoConstants from '../../../constants/concepto-constants';


const Concepto = (props) => {
    const constants = ConceptoConstants;
    const [t] = useTranslation(constants.GLOBAL);
    const numberConcept = `${t('invoices.createInvoice.concept')} ${constants.SIGNS.HASHTAG} ${props.number + constants.NUMBERS.ONE}`;
    const [dataFactura] = useContext(FacturaContext)
    const [changeClient, setChangeClient] = props.changeClient;
    const [errors, setErrors]= useState({});

    let [dataConcepto, setDataConcepto] = React.useState({
        descripcion: constants.EMPTY,
        ordenCompra: constants.EMPTY,
        centroCostoDTO: [{ marcaId: constants.NUMBERS.ZERO, negocioId: constants.NUMBERS.ZERO, tipoIngresoId: constants.NUMBERS.ZERO, valor: constants.NUMBERS.ZERO }],
        costCenterTotal: constants.NUMBERS.ZERO
    });

    React.useEffect(() => {
        if (dataFactura.facSolicitudId) {
            setDataConcepto(
                state => ({
                    ...props.dataConcepto,
                    costCenterTotal: calculateTotal(props.dataConcepto.centroCostoDTO)
                })
            )
        }
    }, [props.dataConcepto])

    React.useEffect(() => {
        if (props.dataConcepto.costCenterTotal) {
            setDataConcepto(
                state => ({
                    ...props.dataConcepto,
                })
            )
        }
    }, [props.dataConcepto])

    React.useEffect(() => {
        if (changeClient) {
            dataConcepto.centroCostoDTO.map((centroCosto) => {
                centroCosto.marcaId = constants.NUMBERS.ZERO;
                centroCosto.negocioId = constants.NUMBERS.ZERO;
                centroCosto.tipoIngresoId = constants.NUMBERS.ZERO;
            })
            setChangeClient(constants.BOOLEANS.FALSE);
        }
    })

    const addCostCenter = () => {
        if(dataFactura.facSolicitudId && dataConcepto.facConceptoId){
           setDataConcepto(
                state => ({
                    ...state,
                    facConceptoId: state.facConceptoId,
                    facSolicitudId: state.facSolicitudId,
                    centroCostoDTO: [...state.centroCostoDTO, { marcaId: constants.NUMBERS.ZERO, negocioId: constants.NUMBERS.ZERO, tipoIngresoId: constants.NUMBERS.ZERO, valor: constants.NUMBERS.ZERO,  facConceptoId: dataConcepto.facConceptoId,}],
                })
            );
        }else{
           setDataConcepto(
                state => ({
                    ...state,
                    centroCostoDTO: [...state.centroCostoDTO, { marcaId: constants.NUMBERS.ZERO, negocioId: constants.NUMBERS.ZERO, tipoIngresoId: constants.NUMBERS.ZERO, valor: constants.NUMBERS.ZERO }],
                })
            );
        }
    }

    const removeCostCenter = (index) => {
        let valueToDelete = dataConcepto.centroCostoDTO[index].valor;
        dataConcepto.centroCostoDTO = dataConcepto.centroCostoDTO.filter((item, i) => i !== index);
        dataConcepto.costCenterTotal = calculateTotal(dataConcepto.centroCostoDTO)
        setDataConcepto(
            state => ({
                ...state,
            })
        )
        props.updateTotalInvoiceValue(index, props.number, valueToDelete);
        props.onChangeConcept(props.number, dataConcepto);
    }

    const handleChangeCostCenter = (i, value, event) => {
        dataConcepto.centroCostoDTO[i].valor = validateInputDecimals(value);
        dataConcepto.costCenterTotal = calculateTotal(dataConcepto.centroCostoDTO);
        setDataConcepto(dataConcepto);
        const { name } = event.target;
        const fieldErrors = validateField(name, value);
        setErrors((prevErrors) => ({
          ...prevErrors,
          ...fieldErrors,
        }));
        props.onChangeConcept(props.number, dataConcepto);
    }

    const handleChange = (event, index) => {
        switch (event.target.name) {
            case constants.CASES.BUY_ORDER:
                dataConcepto.ordenCompra = event.target.value;
                break;
            case constants.CASES.BRAND:
                dataConcepto.centroCostoDTO[index].marcaId = event.target.value;
                break;
            case constants.CASES.DESCRIPTION:
                dataConcepto.descripcion = event.target.value;
                break;
            case constants.CASES.BUSSINES:
                dataConcepto.centroCostoDTO[index].negocioId = event.target.value;
                break;
            case constants.CASES.OPPORTUNITY:
                dataConcepto.centroCostoDTO[index].tipoIngresoId = parseInt(event.target.value);
                break;
            default:
                console.error(constants.CASES.INPUT_NO_FOUND);
                break;
        }
        setDataConcepto(dataConcepto);

        const { name, value } = event.target;
        const fieldErrors = validateField(name, value);
        setErrors((prevErrors) => ({
          ...prevErrors,
          ...fieldErrors,
        }));

        props.onChangeConcept(props.number, dataConcepto);
    }
    

    const calculateTotal = (costCenters) => {
        let total = constants.NUMBERS.ZERO;
        for (let costCenter of costCenters) {
            total += parseFloat(costCenter.valor) || constants.NUMBERS.ZERO;
        }
        return total === constants.NUMBERS.ZERO ? total : total.toFixed(constants.NUMBERS.TWO); 
    }

    const isBrandDisabled = () => {
        if(dataFactura.facSolicitudId){
            return isInvoiceRequestDisable(dataFactura);
        }else{
            return props.brandDisabled;
        }
    }

    const isSelectDisabled = () => {
        if(dataFactura.facSolicitudId){
            return isInvoiceRequestDisable(dataFactura);
        }else{
            return (dataFactura.filialId == constants.NUMBERS.ZERO)
        }
    }

    const textArea = document.querySelector('textarea')
    const textRowCount = textArea ? textArea.value.split("\n").length : constants.NUMBERS.ZERO
    const rows = textRowCount + constants.NUMBERS.ONE


    return (
        <div className="concept">
            <div hidden>SI SEÑOR, ESTAS EN AWS</div>
            <div className="concept-header">
                <h3>{numberConcept} </h3>
                {props.number > constants.NUMBERS.ZERO && !isInvoiceRequestDisable(dataFactura) ?
                    <button className="concept__delete " type='button' onClick={props.removeConcept}>
                        {t('invoices.createInvoice.deleteConcept')}
                    </button>
                    : " "
                }
            </div>
            <div className="head">
                <PdsInput
                    label={t('invoices.createInvoice.purchaseOrder')}
                    type="text"
                    name="buyOrder"
                    className="head__orde_compra"
                    onInput={handleChange}
                    value={dataFactura.facSolicitudId ? props.dataConcepto.ordenCompra : dataConcepto.ordenCompra}
                    disabled={isInvoiceRequestDisable(dataFactura) ? true : false}
                    maxLength={MAXLENGTH_BUYORDER}
                    placeholder={t('invoices.createInvoice.purchaseOrderPlaceHolder')}
                    {...(errors.buyOrder || {})}

                />
                <p className={`head__description ${isInvoiceRequestDisable(dataFactura) ? "disabled-textArea" : ""}`}>{t('invoices.createInvoice.description')}</p>
                <TextArea
                    rows={isInvoiceRequestDisable(dataFactura) ? rows : ""}
                    onChange={(e) => handleChange(e)}
                    placeholder={t('invoices.createInvoice.description')}
                    type='text'
                    name="description"
                    className="head__descripcion"
                    value={dataFactura.facSolicitudId && props.dataConcepto.descripcion}
                    disabled={isInvoiceRequestDisable(dataFactura) ? "disabled" : ""}
                    maxLength={constants.MAX_LENGTH}>
                </TextArea>
            </div>
            {dataConcepto.centroCostoDTO.map((centroCosto, i) =>
                <div key={`${i}'-costCenter'`} className="cost-center">
                    <div className="cost-center-dropdowns">
                        <Select onChange={(e) => handleChange(e, i)}
                            name="negocio"
                            label={t('invoices.createInvoice.business')}
                            value={isInvoiceRequestDisable(dataFactura) ? props.dataConcepto.centroCostoDTO[i].negocioId : dataConcepto.centroCostoDTO[i].negocioId}
                            data={props.dataBusiness}
                            disabled={isSelectDisabled()}
                            placeholder={t('transversal.selectOption')}
                            required={isInvoiceRequestDisable(dataFactura) ? true : false}
                            messageDisabled={t('invoices.createInvoice.enterSubsidiary')}/>
                        <Select onChange={(e) => handleChange(e, i)}
                            name="marca"
                            label={t('invoices.createInvoice.brand')}
                            isBrand
                            value={isInvoiceRequestDisable(dataFactura) ? props.dataConcepto.centroCostoDTO[i].marcaId : dataConcepto.centroCostoDTO[i].marcaId}
                            data={props.dataBrands}
                            disabled={isBrandDisabled()}
                            placeholder={t('transversal.selectOption')}
                            required={isInvoiceRequestDisable(dataFactura) ? true : false}
                            messageDisabled={t('invoices.createInvoice.enterSubsidiaryCustomer')}/>
                        <Select onChange={(e) => handleChange(e, i)}
                            name="oportunidad"
                            label={t('invoices.createInvoice.typeIncome')}
                            value={isInvoiceRequestDisable(dataFactura) ? props.dataConcepto.centroCostoDTO[i].tipoIngresoId : dataConcepto.centroCostoDTO[i].tipoIngresoId}
                            data={props.dataOpportunities}
                            disabled={isSelectDisabled()}
                            placeholder={t('transversal.selectOption')}
                            required={isInvoiceRequestDisable(dataFactura) ? true : false}
                            messageDisabled={t('invoices.createInvoice.enterSubsidiary')}/>
                    </div>
                    <div className="cost-center-lastRow">
                        <PdsInput
                            className="said"
                            name="valorConcepto"
                            type='text'
                            label={t('invoices.createInvoice.amount')}
                            placeholder='$'
                            value={isInvoiceRequestDisable(dataFactura) ? formatValToCurrency(constants.NUMBERS.ONE, props.dataConcepto.centroCostoDTO[i].valor) : dataConcepto.centroCostoDTO[i].valor !== constants.NUMBERS.ZERO ? formatValToCurrency(1, dataConcepto.centroCostoDTO[i].valor) : ''.parseInt}
                            maxLength={centroCosto.valor.toString().includes('.') ? INPUT_MAX_LENGTH_WITH_DECIMAL_SEPARATOR : INPUT_MAX_LENGTH_WITHOUT_DECIMAL_SEPARATOR}
                            disabled={isInvoiceRequestDisable(dataFactura)}
                            onKeyDown={(e) => avoidNonNumericValues(e)}
                            onInput={(e) => parseCurrencyToVal(constants.NUMBERS.ONE, e, (event, value) => handleChangeCostCenter(i, value, event))}
                            {...(errors.valorConcepto || {})}
                        />

                        {dataConcepto.centroCostoDTO.length > constants.NUMBERS.ONE && !isInvoiceRequestDisable(dataFactura) ?
                            <button type="button" className="cost-center__delete" onClick={removeCostCenter.bind(this, i)} >
                                <Images Imagen={icoDelete} />
                            </button>
                            : " "
                        }
                    </div>
                </div>
            )}
            {isInvoiceRequestDisable(dataFactura) ? " " : 
                <div className='add-cost-center__container'>
                    <Button type="button" addButton name={t('invoices.createInvoice.addCostCenter')} onClick={addCostCenter.bind(this)}/>
                </div>
            }
            <div className="total">
                <h3>{t('invoices.createInvoice.totalConcept')}:</h3>
                <h3>{'$' + formatValToCurrency(constants.NUMBERS.ONE, dataConcepto.costCenterTotal)}</h3>
            </div>
        </div>
    )
}

export default Concepto;

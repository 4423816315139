import React from 'react';
import moment from 'moment';
import './text.scss';
import { informationAlert } from '../../molecules/alert/alert';
import datesComponentConstants from '../../../constants/dates-constants';

const Dates = (Props) => {
  const constants = datesComponentConstants;
  const [selectedDate, setSelectedDate] = React.useState(Props.Values);
  React.useEffect(() => { setSelectedDate(Props.Values) }, [Props.Values]);

  const handleChange = (e) => {
  
    const date = moment(new Date()).format(constants.DATE_FORMAT);

    if (moment(selectedDate).isBefore(date)) {
      informationAlert(constants.ERROR_LABEL, constants.WRONG_DATE, constants.ERROR_MESSAGE);
      e.target.value = date;
    }
    Props.onChange(e)
  };

  return (
    <input className="Date" placeholder="dd/mm/aaaa" onChange={(e) => handleChange(e)} onBlur={(e) => handleChange(e)} value={selectedDate} type="Date" min={Props.Min} max={Props.Max} disabled={Props.disabled} />
  )
}
export default Dates;

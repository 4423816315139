import React from 'react';
import './clientHistory.scss';
import { ClientContext } from '../../../provider/cliente';
import { useTranslation } from 'react-i18next';
import ClientHistoryConstants from '../../../constants/client-history-constants';

function ClientHistory() {
    const constants = ClientHistoryConstants;
    const { dataClient } = React.useContext(ClientContext);
    const [translate] = useTranslation(constants.GLOBAL);

    const redirect = (path) => {
        return `${path}${constants.URL_FILTRO}${dataClient.numeroDocumento}${constants.URL_PARAMS}`;
    }

    return (
        <div className="history_fields">
            <div className="information">
                <div>
                    <h3>{translate('customers.createCustomer.customerHistory.title')}</h3>
                </div>
                <div className="clients-links">
                    <div className="clients-links-col">
                        <a target='_blank' rel='noopener noreferrer' href={redirect('/facturas-pendientes')}>
                            {translate('customers.createCustomer.customerHistory.pendingBillingRequests')}
                        </a>
                        <hr />
                        <a target='_blank' rel='noopener noreferrer' href={redirect('/historico-de-facturas')}>
                            {translate('customers.createCustomer.customerHistory.historicalBillingRequests')}
                        </a>
                    </div>
                    <div className="clients-links-col">
                        <a target='_blank' rel='noopener noreferrer' href={redirect('/polizas-pendientes')}>
                            {translate('customers.createCustomer.customerHistory.pendingPolicyApplications')}
                        </a>
                        <hr />
                        <a target='_blank' rel='noopener noreferrer' href={redirect('/historico-de-polizas')}>
                            {translate('customers.createCustomer.customerHistory.historicalPolicyApplications')}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientHistory;
const brandsTableConstants = {
    COST_CENTER_COLUMN: 'centroCosto',
    NAME_COLUMN: 'nombre',
    STATE_COLUMN: 'estado',

    ADMINISTRATOR_ROLE: 'Administrador',
    ERROR_LABEL: 'error',

    EMPTY_STRING: '',

    ZERO_VALUE: 0,
    ONE_VALUE: 1,
    TWO_VALUE: 2,
    THREE_VALUE: 3,
    FOUR_VALUE: 4,
    FIVE_VALUE: 5,
    TEN_VALUE: 10,
    TWENTY_VALUE: 20,
    FIFTY_VALUE: 50,

    TEN_ROWS: '10',
    TWENTY_ROWS: '20',
    FIFTY_ROWS: '50',

    EDIT_BRAND_PATH: '/marcas/editar/',
    CREATE_BRAND_PATH: '/marcas/crear',

    EQUALS: '=',
    AMPERSAND: '&',
};

export default brandsTableConstants;
import {API_BASE_URL, API_PARAMETERS_CONTEXT, API_CLIENTS_CONTEXT} from "../utils/constans";
import clienteAxios from "../api/axios";

const brandsService = async () => {
    let result = [];
    await clienteAxios(`${API_BASE_URL}${API_PARAMETERS_CONTEXT}brands-list`, null, null, "POST")
        .then(respuesta => {
            result = respuesta.data.data.slice();
        }).catch(
            err => console.log(err)
        )
    return result;
}

const paginatedBrandService = async (data) => {
    let result = [];
    let url = `${API_BASE_URL}${API_CLIENTS_CONTEXT}marcas/list-paginated?sortBy=${data.sortBy}&isSort=${data.orden}&pageSize=${data.peticionesPorPagina}&pageNo=${data.numeroPagina}&keyword=${data.searchItem}`
    await clienteAxios(url, null, null, "GET")
        .then(respuesta => {
            result = respuesta.data.data;            
        }).catch(
            err => console.log(err)
        )
    return result;
}

const findBrandById = async (idBrand) => {
    const data = {
        code: idBrand
    };
    let header = {'Content-Type': 'application/json'};
    let result;
    let url = `${API_BASE_URL}${API_CLIENTS_CONTEXT}marcas/find-by-id/`;
    await clienteAxios(url, data, header, "POST")
        .then(respuesta => {
            result = respuesta.data.data;
        }).catch(
            err => console.log(err)
        )
    return result;
}

const saveNewBrand = async (brand) => {
    let header = {'Content-Type': 'application/json'};
    let result;
    let url = `${API_BASE_URL}${API_CLIENTS_CONTEXT}marcas/save`;
    await clienteAxios(url, brand, header, "POST")
        .then(respuesta => {
            result = respuesta;
        }).catch(
            err => console.log(err)
        )
    return result;
}

const getFilialesByBrand = async (brandId) => {
    let result;
    let url = `${API_BASE_URL}${API_CLIENTS_CONTEXT}marcas/${brandId}/filiales`;
    await clienteAxios(url, null, null, "GET")
        .then(respuesta => {
            result = respuesta;
        }).catch(
            err => console.log(err)
        )
    return result;
}

const getClientesByBrand = async (brandId) => {
    let result;
    let url = `${API_BASE_URL}${API_CLIENTS_CONTEXT}marcas/${brandId}/clientes`;
    await clienteAxios(url, null, null, "GET")
        .then(respuesta => {
            result = respuesta;
        }).catch(
            err => console.log(err)
        )
    return result;
}

const getBrandFullinformation = async (brandId) => {
    let result= {
        name:"",
        costCenter:"",
        active:true,
        clients:[],
        filiales:[]
    };
    let brand, clients, filiales;
    await findBrandById(brandId).then(response => {
        brand = response;
    }).catch(error => {
        console.log(error);
    });
    await getClientesByBrand(brandId).then(response => {
        clients = response.data.data;
    }).catch(error => {
        console.log(error);
    });
    await getFilialesByBrand(brandId).then(response => {
        filiales = response.data.data;
    }).catch(error => {
        console.log(error);
    });
    result.name = brand.name;
    result.costCenter = brand.costCenter;
    result.active = brand.active;
    result.code = brand.code;
    result.clients = clients;
    result.filiales = filiales;
    return result;
}

const getBrandsByClientSubsidiary = async (clientid, subsidiaryid) => {
    let result;
    let url = `${API_BASE_URL}${API_CLIENTS_CONTEXT}marcas?clientId=${clientid}&filialId=${subsidiaryid}`;
    await clienteAxios(url, null, null, "GET")
        .then(respuesta => {
            result = respuesta.data.data;
        }).catch(
            err => console.log(err)
        )
    return result;
}


export {brandsService, 
        paginatedBrandService, 
        findBrandById, 
        saveNewBrand,
        getClientesByBrand,
        getFilialesByBrand,
        getBrandFullinformation,
        getBrandsByClientSubsidiary
    };


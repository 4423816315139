import Proptypes from 'prop-types';
import './roundButton.scss';

function RoundButton({className, type, onClick, icon, ...otherButtonProperties}) {
    return (
        <button className={`button round-button ${className}`}
                type={type}
                onClick={onClick}
                {...otherButtonProperties}
        >
            <div className="iconRoundButton">
                <img src={icon}/>
            </div>
        </button>
    )
}

RoundButton.defaultProps = {
    type: 'button',
}
RoundButton.propTypes = {
    type: Proptypes.string,
    className: Proptypes.string,
    onClick: Proptypes.func,
    name: Proptypes.string,
    icon: Proptypes.string,
}

export default RoundButton;
import { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";

import { getClientByID } from "../../../services/clientService";

import { ClientContext } from "../../../provider/cliente";

import HeaderClient from "../../molecules/header-create-client/headerClient";
import Aside from "../../templates/aside/aside";
import FormClient from "../../templates/form-client/formClient";
import RoundButton from "../../atoms/round-button/roundButton";
import Wrapper from "../../templates/wrapper/Wrapper";
import useLoader from "../../../utils/useLoader";
import Back from "../../../images/ico-back.svg";
import { useTranslation } from "react-i18next";
import "./crearCliente.scss";
import CreateClientComponentConstants from "../../../constants/crear-cliente-constants";

function CrearCliente() {
  const constants = CreateClientComponentConstants;
  const params = useParams();
  const { dataClient, setDataClient } = useContext(ClientContext);
  const [loader, showLoader, hideLoader] = useLoader();
  const [translate] = useTranslation(constants.TRANSLATION_GLOBAL_VALUE);

  useEffect(() => {
    if (params.id) {
      getData();
    } else {
      setDataClient({
        type: constants.RESET_LABEL,
        payload: constants.RESET_MESSAGE,
      });
    }
    return () => {};
  }, [params.id]);

  const getData = async () => {
    const data = { clienteId: params.id };
    showLoader();
    const user = await getClientByID(data);
    hideLoader();
    setDataClient({
      type: constants.ADD_LABEL,
      payload: { ...user },
    });
  };
  const title = dataClient.clienteId ? translate('customers.createCustomer.edit') : translate('customers.createCustomer.title');
  const description = translate('customers.createCustomer.dataCustomer.description')
  const [blur, setBlur] = useState(false);

  return (
    <Wrapper aside={<Aside blur={blur} setBlur={setBlur} />} blur={blur}>
      <HeaderClient title={title} description={description} />
      <FormClient />
      {loader}
    </Wrapper>
  );
}
export default CrearCliente;

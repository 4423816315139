import Header from '../../organism/Header/Header';
/* import Translation from '../../organism/translation-language/translation-language';
import Notifications from '../../organism/notifications/notifications';
import { CookiesProvider } from 'react-cookie';
import TranslationHeader from '../../organism/translation-header/translation-header'; */
import { useState } from 'react';
import './Wrapper.scss';
import useInputValidation from '../../../utils/hooks/useInputValidation';
import footerImage from '../../../images/footer.svg';

const Wrapper = ({ aside, children, blur, ...otherAsideProps }) => {
    useInputValidation()
    const [showNotifications, setShowNotifications] = useState(false);

    return (
        <>
            <Header showNotifications={showNotifications} setShowNotifications={setShowNotifications} />
            <section className="t-wrapper" {...otherAsideProps}>
                <aside className="t-wrapper__aside">
                    {aside}
                </aside>
                <main className={`t-wrapper__children ${blur ? 't-wrapper__children-blur' : ''} ${showNotifications ? 't-wrapper__children-not-scroll' : ''}`}>
                    {children}
                </main>
            </section>
            <footer className="t-wrapper__footer"><img src={footerImage} alt="footer" /></footer>
        </>
    );
}

export default Wrapper;
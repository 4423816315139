import { useState } from "react";
import useLoader from "../../../utils/useLoader";
import HeaderInvoiceRequest from "../../organism/header-invoice-request/HeaderInvoiceRequest";
import Aside from "../../templates/aside/aside";
import ClientsTable from "../../templates/clientsTable/clientsTable";
import Wrapper from "../../templates/wrapper/Wrapper";
import { useTranslation } from "react-i18next";
import TranslationComponentConstants from "../../../constants/translation-language-constants";

function Clients() {
  const translationConstants = TranslationComponentConstants;
  const [loader] = useLoader();
  const [translate] = useTranslation(translationConstants.USE_TRANSLATION_GLOBAL_VALUE);
  const title = translate('customers.customers.title');
  const description = translate('customers.customers.description')
  const [blur, setBlur] = useState(false);

  return (
    <Wrapper aside={<Aside blur={blur} setBlur={setBlur} />} blur={blur}>
      <HeaderInvoiceRequest title={title} description={description}/>
      <ClientsTable />
      {loader}
    </Wrapper>
  );
}

export default Clients;

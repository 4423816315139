import { useTranslation } from 'react-i18next';
import './header.scss'
import TranslationComponentConstants from '../../../constants/translation-language-constants';
import HeaderConstants from '../../../constants/header-constants';

function Header(props) {
    const constants = HeaderConstants;
    const translationConstants = TranslationComponentConstants;
    const [translate] = useTranslation(translationConstants.USE_TRANSLATION_GLOBAL_VALUE);
    let title = constants.EMPTY;
    let description = constants.EMPTY;

    switch (constants.TRUE) {
        case props.policy && props.context.estadoId === constants.NUMBER_ONE:
            title = translate('policy.pendingPolicy.approvePolicy');
            description = translate('policy.pendingPolicy.approvePolicyDescription');
            break;
        case props.policy && props.context.estadoId === constants.NUMBER_FIVE:
            title = translate('policy.historicPolicy.policy');
            description = translate('policy.historicPolicy.policyDescription');
            break;
        case props.policy:
            title = translate('invoices.createInvoice.requestPolicy');
            description = translate('invoices.createInvoice.requestPolicyDescription'); 
            break;
        case (props.context.estadoId === constants.NUMBER_ONE && !props.context.facSolicitudId) || props.context.estadoId === constants.NUMBER_EIGHT:
            title = translate('invoices.createInvoice.title');
            description = translate('invoices.createInvoice.createInvoiceDescription');
            break;
        case !props.policy && (props.context.estadoId === constants.NUMBER_FIVE || props.context.estadoId === constants.NUMBER_SIX): 
            title = translate('invoices.historicInvoice.historicTitle');
            description = translate('invoices.historicInvoice.historicDescription');
            break;
        default:
            title = translate('invoices.pendingInvoice.invoiceApproved');
            description = translate('invoices.pendingInvoice.invoiceApprovedDescription');
            break;
    };

    return (
        <div className="Header">
            <div className="Primary">
                <div className="TitleContent">
                    <h1 className="title">{title}</h1>
                    <p className="description">{description}</p>
                </div>
            </div>
        </div>
    );
}

export default Header;

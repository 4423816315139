import Proptypes from 'prop-types';
import { useTranslation } from "react-i18next";
import "./attach-file.scss";
import TranslationComponentConstants from '../../../constants/translation-language-constants';

function AttachFile({message, disableBlockFile}) {
    const constants = TranslationComponentConstants;
    const [t] = useTranslation(constants.USE_TRANSLATION_GLOBAL_VALUE);
    return (
        <div className="attach-file">
            {
                disableBlockFile() ? 
                <></> 
                : 
                <div className="attach-file__text-attach" >
                    <label  htmlFor="file-uploader" className={disableBlockFile() ? 'disable-attach-block' : undefined}>
                        {t('components.inputFile.selectFile')}
                    </label>
                </div>
            }            
        </div>
    );
}

AttachFile.propTypes = {
    disableBlockFile: Proptypes.func.isRequired,
    message: Proptypes.string,
}

export default AttachFile;
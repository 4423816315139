const PolicyRequestConstants = {
  GLOBAL: 'global',
  MIDDLE_DASH: '-',

  FALSE: false,
  TRUE: true,
  NULL: null,

  NUMBER_ZERO: 0,
  NUMBER_ONE: 1,
  NUMBER_TWO: 2,
  NUMBER_THREE: 3,
  NUMBER_FOUR: 4,
  NUMBER_FIVE: 5,
  NUMBER_SIX: 6,
  NUMBER_EIGHT: 8,
  NUMBER_NINE: 9,
  NUMBER_ELEVEN: 11,

  PRIMARY_WHITE: 'white',
  PRIMARY_DARK: 'dark',


  SETDATAPOLICY_AGREGAR: 'AGREGAR',

  FECHA_SOLICITUD: 'fechaSolicitud',
  FECHA_LIMITE_ENTREGA: 'fechaLimiteEntrega',
  FORMAT_DATE: 'DD/MM/AAAA',
  DATE: 'DATE',

  TYPE_ORANGE: 'orange',
  TYPE_DATE: 'date',
  TYPE_INITIAL_STATE: 'INITIAL_STATE',
  TYPE_RESET: 'RESET',
  TYPE_ADD_FILE: 'ADD_FILE',

  STATUS_200: 200,
  STATUS_403: 403,

  URL_POLIZA: '/poliza',
  URL_POLIZAS_PENDIENTES: '/polizas-pendientes',
  URL_ROOT: '/',


  ALERT_ERROR: 'error',
  ALERT_SUCCES: 'success',
  ALERT_WARNING: 'warning',

  THROW_MSG: 'Ups, algo ha salido mal',
  EMPTY: ''
};

export default PolicyRequestConstants;
import './Toolkit.scss'
import PropTypes from "prop-types";

const Toolkit = ({children, floating}) => {
    return (
        <div className={`toolkit ${!floating ? 'nonfloating-toolkit' : ''}`}>
            {children}
        </div>
    )
}

Toolkit.defaultProps = {
    children: [],
}

Toolkit.propTypes = {
    floating: PropTypes.bool,
}

export default Toolkit;

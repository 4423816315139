const TableConstants = {
    EMPTY_STRING: "",

    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large",

    INPUT: "INPUT",
    GLOBAL: "global",
    CHECKED_STATUS: "checked",
    ALERT_CHECK: "check",
    INPUT_CHECKBOX: "check",

    BASE_CLASS: "table-container__table ",
    SMALL_CLASS: "table-container__table--break-sm",
    MEDIUM_CLASS: "table-container__table--break-md",
    LARGE_CLASS: "table-container__table--break-lg",

    TR_BASE_CLASS: "m-tablerow__dark ",
    TR_SMALL_CLASS: "m-tablerow--break-sm",
    TR_MEDIUM_CLASS: "m-tablerow--break-md",
    TR_LARGE_CLASS: "m-tablerow--break-lg",

    CURRENCY_TYPE: "currency",
    TEXT_TYPE: "text",
    BOOL_TYPE: "bool",

    TEXT_TRANSFORM: "textTransform",
    ALIGN: "align",
    STATUS_KEY: "estado",
    NIT_KEY: "numeroDocumento",
    CENTERCOST_KEY: "centroCosto",
    
    ACTIVE_KEY: "Activo",
    APPROVED_KEY: "Aprobada",
    PENDING_KEY: "Pendiente",
    REVIEWED_KEY: "Revisada",
    EXPORTED_KEY: "Exportada",
    SAVED_KEY: "Guardada",
    NULLIFIED_KEY: "Anulada",
    RETURNED_KEY: "Devuelta",

    APPROVED_COLOR: "#00B880",
    PENDING_COLOR: "#6429CD",
    REVIEWED_COLOR: "#DBAB00",
    EXPORTED_COLOR: "#9610FF",
    SAVED_COLOR: "#4646FF",
  

    FONT_WEIGHT_700: "700",
    ERROR_COLOR: "#B8002C",

    REQUEST_NAME: "solicitud-",

    CURRENCY_DEFAULT: "$ 0",
    CURRENCY_FORMAT: "$ ",
    CURRENCY_FORMAT2: "$",
    PERCENTAGE_SYMBOL: "%",

    ID_ONE: 1,
    ONE_LENGTH: 1,
    ONE_INITIALIZER: 1,
    FIRST_POSITION_INDEX: 0,

    EVEN_NUMBER_DIVISION: 2,
    EVEN_NUMBER_RESIDUE: 0,
}

export default TableConstants;


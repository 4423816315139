import {API_BASE_URL, API_INVOICES_CONTEXT} from "../utils/constans";
import clienteAxios from "../api/axios";

const getInvoiceData = async () => {
    let result = [];
    await clienteAxios(`${API_BASE_URL}${API_INVOICES_CONTEXT}sedes`, "", null, "GET")
        .then((response) => {
            result = response.data;
        }).catch((err) => {
            console.log(err);
        });

    return result;
}

export default getInvoiceData;

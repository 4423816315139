const UserServiceConstants = {

  PETITIONS: {
    GET: "GET",
    POST: "POST",
  },

  API_ENDPOINTS:{
    USERS: "usuarios/",
    UPDATE_USER: "usuarios/update",
    ALL_USERS: "usuarios/all",
    FILIALES_BY_USER: "usuarios/filiales-by-user/",
  },

  PAGINATION_TOKEN: "paginationTokenUsers",

};
export default UserServiceConstants;
import React from "react";
import './novelty.scss';

const Novelty = ({data}) =>{
    return(
        <div className="novedad">
            <div className="primera">
                <div className="titulos">
                    <h3 className="titulo_novedad">{data.titulo}</h3>
                    <h5 className="nombre_de_la_novedad">{data.subtitulo}</h5>
                </div>
                <span className="fecha">{data.fechaCreacion}</span>
            </div>            
            <div className="secundary">
                <hr className="linea" />
                <p className="descripcion">
                    {data.descripcion}
                </p>
                <imagen className="imagen_novedad">
                    <img src={data.urlRecurso}></img>
                </imagen>
            </div>
        </div>     
    );
};

export default Novelty;
import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ClientContext } from "../../../provider/cliente";
import getHeadquarters from '../../../services/getHeadquarters';
import CheckboxSwitch from "../../atoms/checkbox-switch/CheckboxSwitch";
import getEconomyActivities from "../../../services/getEconomyActivities";
import ApproveClientConstants from "../../../constants/approve-client-constants";
import { PdsDropdown, PdsInput, PdsDropdownOption } from '@pragma-dev-kit/pragma-ds-react';
import { validateField, getValueDropdown } from "../../../utils/utils";
import "./approveClient.scss";

let timer;

function ApproveClient() {
  const constants = ApproveClientConstants;

  const [translate] = useTranslation(constants.GLOBAL);
  const { dataClient, setDataClient } = useContext(ClientContext);

  const [actividades, setActividades] = useState([]);
  const [headquarters, setHeadquarters] = useState([]);
  const [errors, setErrors] = useState({});
  const [economicActivities, setEconomicActivities] = useState([]);


  useEffect(() => {
    getHeadquarters().then((resp) => setHeadquarters(resp.data));

    getEconomyActivities().then((resp) => {
      let result = [];

      resp.data.forEach((activity, index) => {
        result[index] = { code: activity.code, name: `${activity.code} - ${activity.name}` }
      })

      setEconomicActivities(result);
      setActividades(result);
    });
  }, []);

  const getHeadquarter = (event) => {
    return headquarters.filter((value) => {
      return value.nombre === event.detail
    })[constants.FIRST_POSITION];
  };

  const updateData = (event) => {
    if (event.target.name === constants.FIELDS.LOCATION_NAME) {
      const selectedCity = getHeadquarter(event);
      setDataClient({
        type: constants.ADD_TYPE,
        payload: { ...dataClient, [constants.FIELDS.LOCATION_NAME]: selectedCity.sedeId.toString() }
      });
      return;
    }

    const value = event.detail ? event.detail : event.target.value;
    setDataClient({
      type: constants.ADD_TYPE,
      payload: { ...dataClient, [event.target.name]: event.target.type === constants.CHECKBOX_TYPE ? event.target.checked : value }
    });
  }

  const handleOnInput = (event) => {
    const { name, value } = event.target;
    updateData(event);

    const fieldErrors = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      ...fieldErrors,
    }));
  };

  const getActivityById = (code) => {
    if (typeof code === constants.NUMBER_TYPE) {
      const result = economicActivities.filter(activity => activity.code === code);
      if (result.length === constants.ONE_POSITION_LIST) return result[constants.FIRST_POSITION].name;
    }
    else return code;
  }

  const consultaActividadEconomica = (event) => {
    updateData(event);
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (dataClient.actividadEconomicaId.length > constants.MIN_LENGTH) {
        let filter;

        if (!isNaN(event.target.value)) {
          filter = economicActivities.filter(activity => activity.name.includes(event.target.value))
        }
        else {
          const value = event.target.value.toLowerCase();
          filter = economicActivities.filter(activity => activity.name.toLowerCase().includes(value))
        }

        setActividades(filter);

        if (filter?.length === constants.SELECTED_ACTIVITY) setDataClient({
          type: constants.ADD_TYPE,
          payload: { ...dataClient, actividadEconomicaId: parseInt(filter[constants.FIRST_POSITION].code) }
        });
      }
    }, constants.REFRESH_RATE)
  }

  return (
    <section className="approve_fields">
      <h3>{translate('customers.createCustomer.approveCustomer.title')}</h3>
      <section className="approve_fields__form">
        <div className="select-economy grid-layout">
          <PdsDropdown
            className={dataClient.actividadEconomicaId ? 'input-value' : ''}
            name={constants.FIELDS.ACT_NAME}
            value={dataClient.actividadEconomicaId ? getActivityById(dataClient.actividadEconomicaId) : undefined}
            onValueChange={updateData}
            onKeyUp={consultaActividadEconomica}
            placeholder={getActivityById(dataClient.actividadEconomicaId) || translate('customers.createCustomer.approveCustomer.activity')}
            label={translate('customers.createCustomer.approveCustomer.activity')}
          >
            {actividades.map(item => (
              <PdsDropdownOption theme="dark" value={item.name} key={item.code}>
                {item.name}
              </PdsDropdownOption>
            ))}
          </PdsDropdown>
          <PdsDropdown
            className={dataClient.sedeId ? 'input-value' : ''}
            name={constants.FIELDS.LOCATION_NAME}
            onValueChange={updateData}
            placeholder={getValueDropdown("sedeId", dataClient.sedeId, headquarters) || translate('transversal.selectOption')}
            label={translate('customers.createCustomer.approveCustomer.billingVenue')}
          >
            {headquarters && headquarters.sort().map(item => (
              <PdsDropdownOption theme="dark" key={item.sedeId} value={(item.nombre)}>
                {item.nombre}
              </PdsDropdownOption>
            ))}
          </PdsDropdown>
          <PdsInput
            name={constants.FIELDS.IVA_NAME}
            type={constants.NUMBER_TYPE}
            label={translate('customers.createCustomer.approveCustomer.vat')}
            value={dataClient.iva ? dataClient.iva : ""}
            onInput={handleOnInput}
            {...(errors.iva || {})} 
          />
        </div>

        <div className="rte-group grid-layout">
          <PdsInput
            name={constants.FIELDS.RETE_IVA_NAME}
            type={constants.NUMBER_TYPE}
            label={translate('customers.createCustomer.approveCustomer.rteVat')}
            value={dataClient.reteIva ? dataClient.reteIva : ""}
            onInput={handleOnInput}
            {...(errors.reteIva || {})} 
          />
          <PdsInput
            name={constants.FIELDS.RETE_FUENTE_NAME}
            type={constants.NUMBER_TYPE}
            label={translate('customers.createCustomer.approveCustomer.rteAtSource')}
            value={dataClient.reteFuente ? dataClient.reteFuente : ""}
            onInput={handleOnInput}
            {...(errors.reteFuente || {})} 
          />
          <PdsInput
            name={constants.FIELDS.RETE_ICA_NAME}
            type={constants.NUMBER_TYPE}
            label={translate('customers.createCustomer.approveCustomer.ica')}
            value={dataClient.reteIca ? dataClient.reteIca : ""}
            onInput={handleOnInput}
            {...(errors.reteIca || {})} 
          />
        </div>

        <div className="checkbox__group grid-layout">
          <div className={constants.CHECKBOX_TYPE}>
            <CheckboxSwitch
              name={constants.FIELDS.RENT_NAME}
              checked={dataClient.autoRenta ? dataClient.autoRenta : false}
              onChange={updateData}
              label={translate('customers.createCustomer.approveCustomer.autoRent') + "0.8%"}
              disabledText={translate('transversal.inactive')}
              enabledText={translate('customers.createCustomer.approveCustomer.autoRent')}
            />
          </div>
          <div className={constants.CHECKBOX_TYPE}>
            <CheckboxSwitch
              name={constants.FIELDS.WHITHOLDING_NAME}
              className={constants.CHECKBOX_TYPE}
              checked={dataClient.agenteRetenedor}
              onChange={updateData}
              label={translate('customers.createCustomer.approveCustomer.withholdingAgent')}
              disabledText={translate('transversal.inactive')}
              enabledText={translate('customers.createCustomer.approveCustomer.withholdingAgent')}
            />
          </div>
          <CheckboxSwitch
            name={constants.FIELDS.ACTIVE_NAME}
            checked={dataClient.activo}
            onChange={updateData}
            label={translate('customers.createCustomer.approveCustomer.activeCustomer')}
            disabledText={translate('transversal.inactive')}
            enabledText={translate('customers.createCustomer.approveCustomer.activeCustomer')}
          />
        </div>
      </section >
    </section >
  );
}

export default ApproveClient;

const UsersTableConstants = {
    EMPTY_STRING_INITIAL_STATE: '',
    INITIAL_20_ROWS_STATE: 20,

    NAME_COLUMN: 'nombre',
    LASTNAME_COLUMN: 'apellido',
    EMAIL_COLUMN: 'email',

    MAIN_NAME: `invoicetable`,

    TYPE_TEXT: 'text',

    TABLE_BREAKON: 'medium',

    COLUMN_WIDTH_3: 3,
    COLUMN_WIDTH_2: 2,


    TEN_ROWS_PER_PAGE: 10,
    TEN_ROWS_PER_PAGE_LABEL: '10',
    TWENTY_ROWS_PER_PAGE: 20,
    TWENTY_ROWS_PER_PAGE_LABEL: '20',
    FIFTY_ROWS_PER_PAGE: 50,
    FIFTY_ROWS_PER_PAGE_LABEL: '50',

    USER_PATH: '/usuario/',

    INFORMATION_ERROR: 'error',
};

export default UsersTableConstants;
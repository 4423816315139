import { useContext, useState } from "react";

import { AppContextUser } from "../../../provider/userInSession";

import Loader from "../../atoms/loader/loader";
import SendClientForm from "../../molecules/send-new-client/sendClientForm";
import AddClient from "../../organism/add-client/addClient";
import ClientFields from "../../organism/client-files/clientFiles";
import ApproveClient from "../../organism/approve-client/approveClient";

import "./formClient.scss";
import ClientHistory from "../../organism/client-history/clientHistory";
import { ClientContext } from "../../../provider/cliente";
import AssociatedBrands from "../../organism/associated-brands/associatedBrands";

function FormClient(props) {
  const { dataUser } = useContext(AppContextUser);
  const { dataClient, setDataClient } = useContext(ClientContext);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="form-client">
      <Loader isLoading={isLoading}></Loader>
      <form>
        <AddClient />
        <ClientFields />
        {dataUser.userId && !(dataUser?.rol === "Solicitante" || dataUser?.rol === "Supervisor") && (
          <ApproveClient />
        )}
        
        {dataClient.clienteId && (<AssociatedBrands />)}
        {dataClient.clienteId && (<ClientHistory />)}
        <SendClientForm user={dataUser} setIsLoading={setIsLoading} />
      </form>
    </div>
  );
}
export default FormClient;
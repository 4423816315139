import {API_BASE_URL, API_PARAMETERS_CONTEXT} from "../utils/constans";
import clienteAxios from "../api/axios";

const listarRegimenTributario = async () => {
    let result = [];
    await clienteAxios(`${API_BASE_URL}${API_PARAMETERS_CONTEXT}regimen-list`, null, null, "POST")
        .then(respuesta => {
            result = respuesta.data;
        }).catch(
            err => console.log(err)
        )
    return result;
}

export default listarRegimenTributario

import InputSearch from '../../atoms/input-search/InputSearch';
import "./InputSearchSet.scss";

const InputSearchSet = ({type,onChange,placeholder,value,...props}) =>{
    return (
        <div className="m-container__input">
            <InputSearch
                type = {type}
                onChange={onChange}
                placeholder = {placeholder}
                value = {value}
            />
        </div>
    )
}

export default InputSearchSet;
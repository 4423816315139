import { useEffect, useState, useContext } from 'react';
import FinderSelector from "../../molecules/finder-selector/finderSelector";
import { getFiliales } from '../../../services/parametersService';
import { BusinessFilialService } from "../../../services/listarNegociosService"
import { getBrandsByClientSubsidiary } from "../../../services/listarMarcasService";
import "./policyRelations.scss";
import { AppContextUser } from '../../../provider/userInSession';
import { useTranslation } from 'react-i18next';
import { PdsDropdown, PdsDropdownOption } from '@pragma-dev-kit/pragma-ds-react';
import PolicyRelationsConstants from '../../../constants/policy-relations-constants';
import { getValueDropdown } from '../../../utils/utils';
import { PolicyContext } from '../../../provider/policy';
import PillButton from '../../atoms/pill-button/pillButton';

const RelationsPolicy = ({ context, isEditing, changeClientState }) => {
    const constants = PolicyRelationsConstants;
    const [translate] = useTranslation(constants.GLOBAL);
    const { dataUser } = useContext(AppContextUser);
    const [dataBusiness, setDataBusiness] = useState([]);
    const [dataPolicy, setDataPolicy] = useContext(context);
    const [dataBrand, setDataBrand] = useState([]);
    const [dataSubsidiary, setDataSubsidiary] = useState([]);
    const [dataButtonsBusiness, setDataButtonsBusiness] = useState([])
    const [dataButtonsBrand, setDataButtonsBrand] = useState([])
    const [dataButtonSubsidiary, setDataButtonSubsidiary] = useState();
    const [isDisabled, setIsDisabled] = useState(true);
    const [changeClient, setChangeClient] = changeClientState;




    useEffect(() => {
        async function queryFiliales() {
            await getFiliales().then(response => {
                setDataSubsidiary(response);
            }).catch(error => {
                setDataSubsidiary([]);
            })
        }
        queryFiliales();
    }, [])

    useEffect(() => {
        if (dataPolicy.clienteId !== constants.NUMBER_MINUS_ONE_STRING && dataPolicy.filialId) {
            async function querySubsidiaryByParams() {
                setIsDisabled(constants.FALSE);
                await getBrandsByClientSubsidiary(dataPolicy.clienteId, dataPolicy.filialId)
                    .then(response => {
                        setDataBrand(response);
                    }).catch(error => {
                        setDataBrand([]);
                    })
            }
            querySubsidiaryByParams();
        }
        else setIsDisabled(constants.TRUE);
    }, [dataPolicy.clienteId, dataPolicy.filialId]);


    useEffect(() => {
        if (!dataPolicy.polSolicitudId) {
            setDataButtonsBrand([]);
            setDataButtonsBusiness([]);
            setDataPolicy({
                type: constants.TYPE_ADD,
                payload: {
                    negocios: [],
                    marcas: []
                }
            });
            setDataButtonSubsidiary();
        }
        if (dataPolicy.filialId) {
            const data = { filialId: dataPolicy.filialId };
            async function queryBusinessBySubsidiar() {
                await BusinessFilialService(data)
                    .then(response => {
                        if (response.status === constants.STATUS_200) {
                            setDataBusiness(response.datas);
                        }
                    }).catch(error => {
                        setDataBusiness([]);
                    })
            }
            queryBusinessBySubsidiar();
        }
    }, [dataPolicy.filialId])

    useEffect(() => {
        if (dataPolicy.filialId) {
            setDataButtonsBusiness(dataPolicy.negocios);
            setDataButtonsBrand(dataPolicy.marcas);
            setDataButtonSubsidiary(dataPolicy.filialId);
        }
    }, [dataPolicy]);

    useEffect(() => {
        if (changeClient) {
            setDataButtonsBrand([]);
            actualizarArreglos(constants.BRANDS, []);
            setChangeClient(constants.FALSE);
        }
    })

    const isBrandsEditable = () => {
        if (dataPolicy.estadoId === constants.FIVE_VALUE) return constants.TRUE;

        if ((dataUser.rol === constants.ROLE_ADMIN || dataUser.rol === constants.ROLE_APPROVER) && dataBrand.length > constants.NUMBER_ZERO) {
            return constants.FALSE;
        }
        else return constants.TRUE;
    }

    const isPolicyEditable = () => {
        if (isEditing) {
            if (dataUser.rol === constants.ROLE_APPROVER || dataUser.rol === constants.ROLE_ADMIN) return constants.FALSE;
            return constants.TRUE;
        } return constants.FALSE;
    }

    const aux = () => {

        if (dataPolicy.estadoId === constants.FIVE_VALUE) return constants.TRUE;

        if (isEditing) {
            if (dataUser.rol === constants.ROLE_APPROVER || dataUser.rol === constants.ROLE_ADMIN) return constants.FALSE;
            return constants.TRUE;
        } else {
            if (dataBusiness.length === constants.NUMBER_ZERO) return constants.TRUE;
            return (dataPolicy.filialId === constants.NUMBER_ZERO);
        }
    }

    const activeBrands = () => {
        let activeB = [];
        dataBrand.forEach(element => {
            if (element.active) activeB.push(element);
        });
        return activeB;
    }

    const changeSubsidiary = (event) => {
        let filialCode = + dataSubsidiary.find(filial => filial.name === event.detail).code;
        setDataButtonSubsidiary(parseInt(filialCode));
        actualizarArreglos(constants.FILIAL_ID, parseInt(filialCode));
    }

    const getBusinessNameByCode = (code) => {
        for (let i = constants.NUMBER_ZERO; i <= dataBusiness.length; i++) {
            if (dataBusiness[i].code === code) {
                return dataBusiness[i].name;
            }
        }
    }

    const getBrandNameByCode = (code) => {
        for (let i = constants.NUMBER_ZERO; i <= dataBrand.length; i++) {
            if (dataBrand[i].code == code) {
                return dataBrand[i].name;
            }
        }
    }

    const handleOnChangeBusiness = (event) => {
        const businessCode = event.target.value;
        const businessName = getBusinessNameByCode(event.target.value);
        let businessDataAux = [...dataButtonsBusiness];
        if (!dataButtonsBusiness.find(item => item.itemId === event.target.value)) {
            businessDataAux.push({ itemId: businessCode, itemName: businessName });
            setDataButtonsBusiness(businessDataAux);
        }
        actualizarArreglos(constants.BUSINESS, businessDataAux);
    }

    const removeItemPillBusiness = (itemId) => {
        var newDataButtons = dataButtonsBusiness.filter(x => {
            return x.itemId !== itemId;
        });
        setDataButtonsBusiness(newDataButtons);
        actualizarArreglos(constants.BUSINESS, newDataButtons);
    }

    const actualizarArreglos = (name, array) => {
        setDataPolicy({
            type: constants.TYPE_ADD,
            payload: { ...dataPolicy, [name]: array }
        });
    }

    const handleOnChangeBrand = (event) => {
        const brandCode = event.target.value;
        const brandName = getBrandNameByCode(brandCode);
        let brandsDataAux = [...dataButtonsBrand];
        if (!dataButtonsBrand.find(item => item.itemId === event.target.value)) {
            brandsDataAux.push({ itemId: brandCode, itemName: brandName });
            setDataButtonsBrand(brandsDataAux);
        }
        actualizarArreglos(constants.BRANDS, brandsDataAux);
    }

    const removeItemPillBrand = (itemId) => {
        var newDataButtons = dataButtonsBrand.filter(x => {
            return x.itemId !== itemId;
        });
        setDataButtonsBrand(newDataButtons);
        actualizarArreglos(constants.BRANDS, newDataButtons);
    }

    const canEdit = () => {
        if (isEditing) {
            if (dataUser.rol === constants.ROLE_ADMIN || dataUser.rol === constants.ROLE_APPROVER) {
                return constants.TRUE
            } else return constants.FALSE
        } else {
            return constants.TRUE
        }
    }

    return (
        <div className="form">
            <div className="HeaderBody relations">
                <div className="RelationsTitle">
                    <h3>{translate('policy.requestPolicy.relations')}</h3>
                </div>
                <div className="header-fields">
                    <PdsDropdown
                        className={dataButtonSubsidiary !== undefined ? 'input-value' : ''}
                        onValueChange={(event) => changeSubsidiary(event)}
                        name="filial"
                        value={dataButtonSubsidiary}
                        data={dataSubsidiary}
                        disabled={isPolicyEditable()}
                        label={translate('policy.requestPolicy.subsidiary')}
                        placeholder={getValueDropdown("code", dataButtonSubsidiary, dataSubsidiary) || translate('policy.requestPolicy.selectSubsidiary')}
                        required={true}
                    >
                        {dataSubsidiary.map((item) => (
                            <PdsDropdownOption theme="dark" key={item.code} value={item.name} >
                                {item.name}
                            </PdsDropdownOption>
                        ))}
                    </PdsDropdown>
                </div>
            </div>
            <hr />
            <div className="ContentBody">
                <div className="BussinesData PolicyData">
                    <FinderSelector
                        labelSelect={translate('policy.requestPolicy.business')}
                        placeholder={translate('transversal.selectOptions')}
                        dataSelect={dataBusiness}
                        searchName={"name"}
                        onChange={(event) => handleOnChangeBusiness(event)}
                        dataButtons={dataButtonsBusiness}
                        removeItemPill={removeItemPillBusiness}
                        isDisabled={aux()}
                        disabledMessage={isEditing ? `${translate('policy.requestPolicy.selectedBusinesses')}:` : translate('policy.requestPolicy.selectSubsidiary')}
                        isSelect
                    />
                </div>

                <hr />
                <div className="BrandsData PolicyData">
                    <FinderSelector
                        labelSelect={translate('policy.requestPolicy.brands')}
                        placeholder={translate('transversal.selectOptions')}
                        dataSelect={canEdit() ? activeBrands() : dataBrand}
                        searchName={"brand"}
                        onChange={(event) => handleOnChangeBrand(event)}
                        dataButtons={dataButtonsBrand}
                        removeItemPill={removeItemPillBrand}
                        isDisabled={isEditing ? isBrandsEditable() : isDisabled}
                        disabledMessage={isEditing ? `${translate('policy.requestPolicy.selectedBrands')}:` : translate('policy.requestPolicy.selectCustomerSubsidiary')}
                        isSelect
                    />
                </div>
            </div>
        </div>
    );
}

export default RelationsPolicy;
import React from 'react';
import Text from '../../atoms/text/text';
import './textBlockTwo.scss';
const TextblockTwo = (Props) =>
  <div className="Textblock">
    <div className="TextField">
      <div className="text__container">
        <Text 
          disabled={Props.disabled}
          Type={Props.type}
          placeholder={Props.placeholder}
          name={Props.name}
          value={Props.value}
          onChange={Props.onChange}
          defaultValue={Props.defaultValue}
          onBlur={Props.onBlur}
          className={Props.className}
          maxLength={Props.maxLength}
          onKeyDown={Props.onKeyDown}
          trmLabel
        /></div>
    </div>
  </div>
  
export default TextblockTwo

import {API_BASE_URL, API_INVOICES_CONTEXT} from "../utils/constans";
import clienteAxios from "../api/axios";

const getInvoiceById = async (data) => {
  return await clienteAxios(`${API_BASE_URL}${API_INVOICES_CONTEXT}find-by-id`, data, null, "POST")
};

const createInvoice = async (data) => {
  return await clienteAxios(`${API_BASE_URL}${API_INVOICES_CONTEXT}create` , data, null, "POST");
};

const updateInvoiceData = async (data) => {
  return await clienteAxios(`${API_BASE_URL}${API_INVOICES_CONTEXT}update`, data, null, "POST");
}
const deleteFile = async (data) => {
  return await clienteAxios(
    `${API_BASE_URL}${API_INVOICES_CONTEXT}delete-invoice-file`,
    data,
    null,
    "POST"
  );
};

const saveInvoiceRequest = async (data) => {
  return await clienteAxios(`${API_BASE_URL}${API_INVOICES_CONTEXT}save`, data, null, "POST")
}

export { getInvoiceById, createInvoice, updateInvoiceData, deleteFile,saveInvoiceRequest};

import { Fragment, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../provider/user";
import { updateUser } from "../../../services/userService";
import { BusinessService } from "../../../services/listarNegociosService";
import { getRoles } from "../../../services/rolesService";
import useLoader from "../../../utils/useLoader";
import { validateUserFields, getValueDropdown, validateField } from "../../../utils/utils";
import { informationAlert } from "../../molecules/alert/alert";
import FinderSelector from "../finder-selector/finderSelector";
import "./userForm.scss";
import { getFiliales } from "../../../services/parametersService";
import { useTranslation } from "react-i18next";
import { PdsInput, PdsButton, PdsDropdown, PdsDropdownOption } from '@pragma-dev-kit/pragma-ds-react';
import UserFormConstants from "../../../constants/user-form-constants";

function UserForm() {
  const constants = UserFormConstants;

  const { dataUser, setDataUser } = useContext(UserContext);
  const [allBusiness, setAllBusiness] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [allFiliales, setAllFiliales] = useState([]);
  const [loader, showLoader, hideLoader] = useLoader();
  const history = useHistory();
  const [translate] = useTranslation(constants.GLOBAL);
  const [errors, setErrors]= useState({});

  useEffect(() => {
    getAllBusiness();
    getAllRoles();
    getAllFiliales();
  }, []);

  const getAllBusiness = async () => {
    await BusinessService()
      .then((businessResponse) => {
        setAllBusiness(businessResponse);
      })
      .catch((error) => {
        setAllBusiness([]);
        informationAlert(constants.ERROR, translate('alerts.oops'), error);
      });
  };

  const getAllRoles = async () => {
    await getRoles()
      .then((rolesResponse) => {
        setAllRoles(rolesResponse);
      })
      .catch((error) => {
        setAllRoles([]);
        informationAlert(constants.ERROR, translate('alerts.oops'), error);
      });
  };

  const getAllFiliales = async () => {
    await getFiliales()
      .then((filialesResponse) => {
        setAllFiliales(filialesResponse);
      })
      .catch((error) => {
        setAllFiliales([]);
      });
  }

  const removeBusinessSelected = (itemId) => {
    let removeBusiness = dataUser.negocios.filter((negocio) => {
      return negocio.code !== itemId;
    });
    setDataUser({
      type: constants.SETDATAUSER_TYPE,
      payload: { ...dataUser, negocios: removeBusiness },
    });
  };

  const removeFilialSelected = (itemId) => {
    let removeFilial = dataUser.filiales.filter((filial) => {
      return filial.code !== itemId;
    });
    setDataUser({
      type: constants.SETDATAUSER_TYPE,
      payload: { ...dataUser, filiales: removeFilial },
    });
  }

  const handleOnChangeBusiness = (event) => {
    let codeBusinessSelected = event.target.value;
    let existBusiness = validateBusinessExistInUser(codeBusinessSelected)
    if (!existBusiness) {
      let newBusiness = findBusinessSelected(codeBusinessSelected);
      dataUser.negocios.push(newBusiness);
      setDataUser({
        type: constants.SETDATAUSER_TYPE,
        payload: { ...dataUser, negocios: [...dataUser.negocios] },
      });
    }
  };

  const findBusinessSelected = (code) => {
    return allBusiness.find((business) => business.code === code);
  };

  const validateBusinessExistInUser = (codeBusiness) => {
    return dataUser.negocios.find(
      (item) => item.code === codeBusiness
    );
  }

  const handleOnChangeFilial = (event) => {
    let codeFilialSelected = +event.target.value;
    let existFilial = validateFilialExistInUser(codeFilialSelected)
    if (!existFilial) {
      let newFilial = findFilialSelected(codeFilialSelected);
      dataUser.filiales.push(newFilial);
      setDataUser({
        type: constants.SETDATAUSER_TYPE,
        payload: { ...dataUser, filiales: [...dataUser.filiales] },
      });
    }
  };

  const findFilialSelected = (code) => {
    return allFiliales.find((filial) => filial.code === code);
  };

  const validateFilialExistInUser = (codeFilial) => {
    return dataUser.filiales.find(
      (item) => item.code === codeFilial
    );
  }


  function handleOnChangeRole(event) {
    let roleId =+ allRoles.find(rol => rol.nombre === event.detail)?.roleId;
    setDataUser({
      type: constants.SETDATAUSER_TYPE,
      payload: { ...dataUser, roleId},
    });
  }

  function handleOnChangeCodeSystem(event) {
    let codigoSistema = event.target.value
    setDataUser({
      type: constants.SETDATAUSER_TYPE,
      payload: { ...dataUser, codigoSistema},
    });
    
    const { name, value } = event.target;
    const fieldErrors = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      ...fieldErrors,
    }));
  }

  const handleSubmit = async () => {
    showLoader();
    try {
      validateUserFields(dataUser, () => {
        updateUser(dataUser)
          .then((response) => {
            switch (response.data.status) {
              case constants.CASE_200:
                history.push(constants.URL_USERS);
                informationAlert(constants.SUCCESS, translate('alerts.success'), response.data.userMessage);
                break;
              default:
                informationAlert(constants.ERROR, translate('alerts.oops'), response.data.userMessage);
                break;
            }
          })
          .catch((error) => {
            informationAlert(constants.ERROR, translate('alerts.oops'), error.message);
          });
      });
    } catch (error) {
      informationAlert(error.type, error.msg, error.details);
    }
    hideLoader();
  };
  return (
    <Fragment>
      <div className="user-form__content">

        <div className="information">
          <div><h3 className="information__title">{translate('administrator.users.editing')}</h3></div>
          <div className="user-form__content__formBody">
            <PdsInput
              type={constants.TYPE_TEXT}
              name={constants.NAME_NAME}
              placeholder={translate('administrator.users.name')}
              label={translate('administrator.users.name')}
              required
              disabled={true}
              value={dataUser.nombre}
            />
            <PdsInput
              type={constants.TYPE_TEXT}
              name={constants.NAME_LASTNAME}
              placeholder={translate('administrator.users.lastname')}
              label={translate('administrator.users.lastname')}
              required
              disabled={true}
              value={dataUser.apellido}
            />
            <PdsInput
              type={constants.TYPE_EMAIL}
              name={constants.NAME_EMAIL}
              placeholder={translate('administrator.users.email')}
              label={translate('administrator.users.email')}
              required
              disabled={true}
              value={dataUser.email}
            />
          </div>
          <div className="user-form__content__formBody">
            <PdsDropdown
              className={`input__20 margin__bottom ${dataUser.roleId ? 'input-value' : ''}`}
              placeholder={getValueDropdown("roleId", dataUser.roleId, allRoles) || translate('transversal.selectOption')}
              label={translate('administrator.users.rol')}
              data={allRoles}
              name={constants.NAME_ROL}
              value={dataUser.roleId}
              onValueChange={handleOnChangeRole}
            >
              {allRoles.map(item =>(
                <PdsDropdownOption theme="dark" value={item.nombre} key={item.roleId}>
                  {item.nombre}
                </PdsDropdownOption>
              ))}
            </PdsDropdown>

            
            <PdsInput
              type={constants.TYPE_TEXT}
              name={constants.NAME_CODESYSTEM}
              placeholder={translate('administrator.users.systemCode')}
              label={translate('administrator.users.systemCode')}
              required={dataUser.roleId === constants.ROL_ID_SOLICITANTE}
              maxLength={constants.TEXT_MAX_LENGTH}
              value={dataUser.codigoSistema}
              onInput={handleOnChangeCodeSystem}
              {...(errors.codigoSistema || {})}
            />
          </div>
          <div className="user-form__content__formBody">
            <FinderSelector
              labelSelect={translate('administrator.users.business')}
              placeholder={translate('transversal.selectOption')}
              dataSelect={allBusiness}
              searchName={constants.SEARCHNAME_BUSINESS}
              onChange={(event) => handleOnChangeBusiness(event)}
              dataButtons={dataUser.negocios}
              removeItemPill={removeBusinessSelected}
              isSelect
            />

            <FinderSelector
              labelSelect={translate('administrator.users.subsidiary')}
              placeholder={translate('transversal.selectOption')}
              dataSelect={allFiliales}
              searchName={constants.SEARCHNAME_FILIAL}
              onChange={(event) => handleOnChangeFilial(event)}
              dataButtons={dataUser.filiales}
              removeItemPill={removeFilialSelected}
              isSelect
            />
          </div>
        </div>
      </div>
      <div className="user-form__content__formButton">
        <PdsButton onClick={handleSubmit}>
          {translate('administrator.users.saveUser')}
        </PdsButton>
      </div>
      {loader}
    </Fragment>
  );
}
export default UserForm;

const CreateClientComponentConstants = {
    TRANSLATION_GLOBAL_VALUE: 'global',

    RESET_LABEL: 'RESET',
    ADD_LABEL: 'AGREGAR',

    RESET_MESSAGE: 'Voy a reestablecer el cliente desde la página crear cliente',

};

export default CreateClientComponentConstants;
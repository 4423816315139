import {API_BASE_URL, API_PARAMETERS_CONTEXT} from "../utils/constans";
import clienteAxios from "../api/axios";

const getEconomyActivities = async () => {
    let result = [];
    await clienteAxios(`${API_BASE_URL}${API_PARAMETERS_CONTEXT}activity-economic-list`, null, null, "POST")
        .then((respuesta) => {
            result = respuesta.data;
        })
        .catch((err) => console.log(err));
    return result;
};

export default getEconomyActivities;

import { useContext, useEffect } from "react";
import { isInvoiceRequestDisable } from "../../../utils/utils";
import Button from "../../atoms/buttons/button";
import TextAreaBlock from "../../molecules/text-area-block/textAreaBlock";
import "./observaciones.scss";
import { useTranslation } from "react-i18next";
import ObservacionesConstants from "../../../constants/observaciones-constants";
import { PdsButton } from "@pragma-dev-kit/pragma-ds-react";

const Observaciones = (Props) => {
  const constants = ObservacionesConstants;
  const [dataFactura, setDataFactura] = useContext(Props.context);
  const [translate] = useTranslation(constants.GLOBAL);

  useEffect(() => {
    if (dataFactura.reset) {
      dataFactura.reset = constants.FALSE;
    }
  }, [dataFactura]);

  const actualizarObservaciones = (event) => {
    dataFactura.observaciones = event.target.value;
    setDataFactura({
      action: constants.ACTION_ADD_OBS,
      payload: { ...dataFactura },
    });
  };

  return (
    <div className="observaciones">
      <div className="body observacionesContent">
        <div className="headerBody">
          <h3>{Props.title ? Props.title : translate('transversal.customerHistory')}</h3>
        </div>
        {isInvoiceRequestDisable(dataFactura) ? (
          <TextAreaBlock
            maxLength={500}
            text={Props.text}
            textStrong={Props.textStrong}
            value={dataFactura.observaciones}
            disabled="Disabled"
          ></TextAreaBlock>
        ) : (
          <TextAreaBlock
            maxLength={500}
            text={Props.text}
            textStrong={Props.textStrong}
            onChange={actualizarObservaciones}
            value={dataFactura.observaciones}
            placeholder={translate('transversal.observationsAdd')}
          ></TextAreaBlock>
        )}
      </div>
      {Props.showButton && (
        <div className="button__container">
          {Props.buttons.map((button) => {
            if (!button.hide) {
              return (
                <PdsButton
                  key={button.name}
                  className={button.primary}
                  name={button.name}
                  onClick={button.onClick}
                  disabled={button.disabled}
                >
                  {button.name}
                </PdsButton>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};
export default Observaciones;

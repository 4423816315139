import './text.scss';
import Help from '../../../images/ico-help.svg';
import { PdsTooltip } from '@pragma-dev-kit/pragma-ds-react';
import { useTranslation } from 'react-i18next';
import TranslationComponentConstants from '../../../constants/translation-language-constants';

const SpanOrange = (Props) => {

    const [translate] = useTranslation(TranslationComponentConstants.USE_TRANSLATION_GLOBAL_VALUE);
    return (
        <span className='SpanOrange'>
            {Props.MostrarValor}
            {Props.date ? 
                <div className="tooltip"><img src={Help}/>
                <PdsTooltip className="tooltiptext">{ translate('transversal.tooltipDate') }</PdsTooltip>
                </div> 
                : ''}
        </span>

    )
}
export default SpanOrange;
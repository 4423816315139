import { BASE_URL, LOCATIONS_URL } from '../constants/api-constants';
const INPUT_MAX_LENGTH_WITH_DECIMAL_SEPARATOR = "24"
const INPUT_MAX_LENGTH_WITHOUT_DECIMAL_SEPARATOR = "22"

const API_BASE_URL = BASE_URL;
const API_LOCATIONS_URL = LOCATIONS_URL;

const API_CLIENTS_CONTEXT = process.env.REACT_APP_API_CLIENTS_CONTEXT;
const API_INVOICES_CONTEXT = process.env.REACT_APP_API_INVOICES_CONTEXT;
const API_PARAMETERS_CONTEXT = process.env.REACT_APP_API_PARAMETERS_CONTEXT;
const API_POLICY_CONTEXT = process.env.REACT_APP_API_POLICY_CONTEXT;

const TAX_REGIME_IDS = {
    SIMPLIFICADO: 1,
    CONTRIBUYENTE: 2,
    COMUN: 3,
    NO_RESPONSABLE: 4,
    SIN_REGIMEN: 5
}

const FILES_CATEGORIES = {
    CAMARA_COMERCIO: "camara_comercio",
    RUT: "rut",
    OTROS: "otros",
    INVOICE_REQUEST: "invoice_request",
    INVOICE: "invoice",
    POLICY_REQUEST: "policy_request",
    POLICY: "policy",
}

const STATUS_ID = {
    PENDIENTE: 1,
    REVISADA: 2,
    EXPORTADA: 3,
    DEVUELTA: 4,
    APROBADA: 5,
    ANULADA: 6,
    CANCELADA: 7,
    GUARDADA: 8,
}

const POLICY_ID = {
    CALIDAD_SERVICIO: 1,
    CUMPLIMIENTO_CONTRATO: 2,
    MANEJO_ANTICIPO: 3,
    PAGO_SALARIOS: 4,
    RESPONSABILIDAD_CIVIL: 5,
    RESPONSABILIDAD_CONTRACTUAL: 6,
    SERIEDAD: 7,
    OTRA: 8
}

const REGEX = {
    MAIL_REGEX: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    PHONE_REGEX: /^\+?([0-9]+)$/,
    NUMBER_REGEX: /^-?\d?\d*$/
}

export const OFFLINE = 'offline';
export const NETWORK_ERROR = 'Network Error';
export const PATH_ERROR = '/error';

const MAXLENGTH_BUYORDER = 45;

const MONTHS_LIST = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

//Limit size file in bytes
const LIMIT_SIZE_FILE = 10485760;

export {
    INPUT_MAX_LENGTH_WITH_DECIMAL_SEPARATOR,
    INPUT_MAX_LENGTH_WITHOUT_DECIMAL_SEPARATOR,
    API_BASE_URL,
    API_LOCATIONS_URL,
    API_CLIENTS_CONTEXT,
    API_INVOICES_CONTEXT,
    API_PARAMETERS_CONTEXT,
    TAX_REGIME_IDS,
    FILES_CATEGORIES,
    STATUS_ID,
    REGEX,
    MAXLENGTH_BUYORDER,
    POLICY_ID,
    API_POLICY_CONTEXT,
    MONTHS_LIST,
    LIMIT_SIZE_FILE
}
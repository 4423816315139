const NotificationsComponentConstants = {
    NOVEDAD_KEY: 'novedad',
    EMPTY_STRING_VALUE: '',

    ROOT_PATH: '/',

    FIRST_INDEX_POSITION: 0,
    LIMIT_DAYS: 15,

    USE_TRANSLATION_GLOBAL_VALUE: 'global',

    ZERO_LENGTH: 0
};

export default NotificationsComponentConstants;
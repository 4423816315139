const ConceptosConstants = {

  GLOBAL: 'global',
  
  FALSE: false,

  CURRENCY: {
    USD: 'USD',
    COP: 'COP'
  },

  NUMBERS: {
    MINUS_ONE: -1,
    ZERO: 0,
    ONE: 1,
    TWO: 2
  },

  STATUS: {
    SUCCESS: 200,
  },

  ON_CHANGE_CONCEPT: 'onChangeConcept',
  COST_CENTER_TOTAL: 'costCenterTotal'

}

export default ConceptosConstants
import React, {Fragment, useContext, useEffect, useState} from 'react';
import MainSelect from '../../atoms/main-select/MainSelect';
import Table from '../../molecules/table/Table';
import TablePagination from '../../molecules/table-pagination/TablePagination';
import InputSearchSet from '../../molecules/input-search-set/InputSearchSet';
import {AppContextUser} from '../../../provider/userInSession';
import Toolkit from "../../atoms/toolkit/Toolkit";
import { informationAlert } from '../../molecules/alert/alert';
import { useHistory } from "react-router-dom";
import useLoader from '../../../utils/useLoader';
import './brandsTable.scss';
import {paginatedBrandService} from '../../../services/listarMarcasService';
import Button from '../../atoms/buttons/button';
import { useTranslation } from 'react-i18next';
import TranslationComponentConstants from '../../../constants/translation-language-constants';
import brandsTableConstants from '../../../constants/brands-table-constants';
import { PdsButton } from '@pragma-dev-kit/pragma-ds-react';

const BrandsTable = (props) => {
    const translationConstants = TranslationComponentConstants;
    const constants = brandsTableConstants;
    const [translate] = useTranslation(translationConstants.USE_TRANSLATION_GLOBAL_VALUE);

    const [dataBrands, setDataBrands] = useState([]);
    let filtro, page, requests,columnSearched,orderSearched;
    const headingBrandsColumns = [
        {key: constants.COST_CENTER_COLUMN, val: translate('administrator.brands.costCenter'), width: constants.TWO_VALUE},
        {key: constants.NAME_COLUMN, val: translate('administrator.brands.brandName'), width: constants.TEN_VALUE},
        {key: constants.STATE_COLUMN, val: translate('administrator.brands.active'), width: constants.TWO_VALUE}
    ];
    const initialDataAuxiliar = {
        sortBy: constants.COST_CENTER_COLUMN,
        isSort: true,
        peticionesPorPagina: constants.TWENTY_VALUE,
        numeroPagina: constants.ZERO_VALUE,
        searchItem: constants.EMPTY_STRING,
        orden: true
    }
    const [initialData,setInitialData] = useState(initialDataAuxiliar);

    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(constants.ONE_VALUE);
    const {dataUser} = useContext(AppContextUser);
    const [loader, showLoader, hideLoader] = useLoader();
    const [orderBy, setorderBy] = useState({
        nameColumn: constants.COST_CENTER_COLUMN,
        orderAsc: true
    });
    const [totalPages, setTotalPages] = useState(constants.ONE_VALUE);
    const [requestsPerPage, setRequestsPerPage] = useState(constants.TWENTY_VALUE);
    const [searchItem, setSearchItem] = useState(constants.EMPTY_STRING);

    const selectValues = [
        {value: constants.TEN_VALUE, label: `${constants.TEN_ROWS} ${translate('components.table.resultsPerPage')}`},
        {value: constants.TWENTY_VALUE, label: `${constants.TWENTY_ROWS} ${translate('components.table.resultsPerPage')}`},
        {value: constants.FIFTY_VALUE, label: `${constants.FIFTY_ROWS} ${translate('components.table.resultsPerPage')}`}
    ];
    const handleOnclick = (newPage) => {
        if (newPage >= constants.ONE_VALUE && newPage <= totalPages) setCurrentPage(newPage);
    }

    useEffect(() => {
        if(dataUser.rol === constants.ADMINISTRATOR_ROLE){
            showLoader();
            async function queryBrands() {
                await paginatedBrandService(initialData).then(response => {
                    setTotalPages(response.totalPages);
                    setDataBrands(response.resultList.slice());
                    hideLoader();
                }).catch(error => {
                    hideLoader();
                    setDataBrands([]);
                    informationAlert(constants.ERROR_LABEL, translate('alerts.oops'), error);
                })
            }
            queryBrands();
        }
        if(!window.location.search==constants.EMPTY_STRING){
            setInitialData(separatePastParams(window.location.search));
        }
    }, []);      

    const handleOnclickDataHeading = (nameTableColumn) => {

        let orderByVal = nameTableColumn === orderBy.nameColumn ? !orderBy.orderAsc : true;

        setorderBy({
            nameColumn: nameTableColumn,
            orderAsc: orderByVal
        });
    };

    const handleOnchange = (requestPerPage) => {
        setRequestsPerPage(requestPerPage);
        setCurrentPage(constants.ONE_VALUE);
    };

    const handleOnChangeSearch = (event) => {
        setSearchItem(event.target.value)
    };

    const reloadTable = () => {
        const filterData = {
            usuarioId: dataUser.userId,
            lista: [{}],
            nombreRol: dataUser.rol,
            numeroPagina: (currentPage - constants.ONE_VALUE),
            peticionesPorPagina: requestsPerPage,
            searchItem: searchItem,
            sortBy: orderBy.nameColumn,
            orden: orderBy.orderAsc
        };
        if (filterData.nombreRol === constants.ADMINISTRATOR_ROLE) {
            const response = paginatedBrandService(filterData);
            showLoader();
            response.then(resp => {
                setTotalPages(resp.totalPages);
                setDataBrands(resp.resultList.slice());
                hideLoader();                
            }).catch(err => {
                hideLoader();
                setDataBrands([]);
                informationAlert(constants.ERROR_LABEL, translate('alerts.oops'), err);
            });
        }
    }

    useEffect(() => { 
        reloadTable();
    },[currentPage,requestsPerPage,dataUser,orderBy,searchItem]);
    
    const handleOnItemClickListener = (row) => {
        history.push(constants.EDIT_BRAND_PATH + row.brandId);
    };

    const separatePastParams = (url) => {
        const search = url.split(constants.EQUALS);
        filtro = (search[constants.ONE_VALUE].split(constants.AMPERSAND))[constants.ZERO_VALUE];
        page = (search[constants.TWO_VALUE].split(constants.AMPERSAND))[constants.ZERO_VALUE];
        requests = (search[constants.THREE_VALUE].split(constants.AMPERSAND))[constants.ZERO_VALUE];
        columnSearched = (search[constants.FOUR_VALUE].split(constants.AMPERSAND))[constants.ZERO_VALUE];
        orderSearched = search[constants.FIVE_VALUE];
        setRequestsPerPage(requests);
        setSearchItem(filtro);
        setCurrentPage(parseInt(page));
        setorderBy({
            nameColumn: columnSearched.toString(),
            orderAsc: orderSearched
        });
        return ({
            sortBy: columnSearched.toString(),
            isSort: orderSearched,
            peticionesPorPagina: requests,
            numeroPagina: page,
            searchItem: filtro,
            orden: true
        });
    }

    useEffect(() => {
        if(!window.location.search==constants.EMPTY_STRING){
            setInitialData(separatePastParams(window.location.search));
        }
    },[])


    const handleCreateBrand = () => {
        history.push(constants.CREATE_BRAND_PATH);
    }

    return (
        <Fragment>
            <Toolkit>
                {<PdsButton className='brand-toolkit__button' onClick={handleCreateBrand} >{translate('administrator.brands.addBrand')}</PdsButton>}
                <div className="brand-toolkit__search">
                    <InputSearchSet
                        type="text"
                        placeholder={translate('administrator.brands.searchBrand')}
                        value={searchItem}
                        onChange={handleOnChangeSearch}
                    />
                </div> 
            </Toolkit>

            <Table
                tableData={dataBrands}
                headingColums={headingBrandsColumns}
                title={null}
                breakOn='medium'
                orderBy={handleOnclickDataHeading}
                orderColumnName={orderBy.nameColumn}
                orderColumnDirection={orderBy.orderAsc}
                onItemClickListener={handleOnItemClickListener}
            />
            <div className="o-footer__table">
                <MainSelect
                    options={selectValues}
                    name={`invoicetable`}
                    value={requestsPerPage}
                    onChange={handleOnchange}
                />
                <TablePagination
                    paginate={(event, newPage) => handleOnclick(newPage)}
                    pageAmount={totalPages}
                    currentPage={currentPage}
                />
            </div>
            {loader}
        </Fragment>
    );
};

export default BrandsTable;

export const ROLES = {
    ADMIN : "Administrador",
    APROBADOR : "Aprobador",
    AUDITOR : "Auditor",
    SOLICITANTE : "Solicitante",
    SUPERVISOR : "Supervisor"
}

export const COGNITO_ROLES = {
    ADMIN : "facturacion_administrador",
    APROBADOR : "facturacion_aprobador",
    AUDITOR : "facturacion_auditor",
    SOLICITANTE : "facturacion_solicitante",
    SUPERVISOR : "facturacion_supervisor"
}